import {
  ExtendedInvoice,
  IdentifierType,
  InvoicePayment,
  OnSuccessHandlers,
} from '@/lib/types';
import { Divider, Skeleton } from 'antd';
import { FC } from 'react';
import { NoInvoiceFoundView } from './noInvoiceFoundView';

import { hasAccessAtom } from '@/lib/atoms/atoms';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';
import { EditInvoice } from './editInvoice';
import { InvoiceViewMode } from './invoiceViewMode';

type InvoiceDetailProps = {
  identifierTypes: IdentifierType[];
  invoice?: ExtendedInvoice;
  invoicePayment?: InvoicePayment;
  showPdf?: boolean;
  moduleHandlers?: OnSuccessHandlers;
  isLoading: boolean;
};

export const InvoiceDetail: FC<InvoiceDetailProps> = ({
  identifierTypes,
  invoice,
  invoicePayment,
  showPdf,
  moduleHandlers,
  isLoading,
}) => {
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [editModeEnabled, setEditModeEnabled] = useState(false);

  return (
    <Skeleton loading={isLoading} active>
      {invoice ? (
        editModeEnabled ? (
          <EditInvoice
            identifierTypes={identifierTypes}
            invoice={invoice}
            disableEditMode={() => setEditModeEnabled(false)}
            onSuccess={moduleHandlers?.onEditSuccess}
          />
        ) : (
          <InvoiceViewMode
            invoice={invoice}
            showPdf={showPdf}
            onSuccess={moduleHandlers}
            invoicePayment={invoicePayment}
            isAdmin={isAdmin}
            enableEditMode={() => setEditModeEnabled(true)}
          />
        )
      ) : (
        <NoInvoiceFoundView />
      )}
    </Skeleton>
  )
}
