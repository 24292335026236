export const floorToTwoDecimals = (num: number): number => {
  return Math.floor((num + Number.EPSILON) * 100) / 100;
};

export const roundToTwoDecimals = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const ceilToTwoDecimals = (num: number): number => {
  return Math.ceil((num + Number.EPSILON) * 100) / 100;
};
