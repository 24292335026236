import { getRelations } from '@/lib/adapters/users-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import { ScopeTrackingButton } from '@/lib/components/scope';
import {
  LyantheFile,
  LyantheFileFilter,
  LyantheFileStatus,
  LyantheFileWithOwner,
  SortOrder,
} from '@/lib/types';
import { IdentifierCategory } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Popconfirm, Radio, Row, Space } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteFile,
  deleteFileBatch,
  getFileScanStatus,
  reprocessFile,
  reprocessFileBatch,
} from '../../../lib/adapters/lyanthe-adapter';
import { InvoiceRejectModal } from '../../../lib/components/reject-invoice/invoiceRejectModal';
import {
  RejectFile,
  RejectModalState,
} from '../../../lib/types/reject-invoice';

const baseColumnSettings: CustomColumnType<LyantheFileWithOwner> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const LyantheData = () => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<LyantheFileWithOwner[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const hasAccess = useAtomValue(hasAccessAtom);

  const [tableSettings, setTableSettings] = useState<LyantheFileFilter>({
    page: 1,
    pageSize: 10,
    orderField: '',
    order: SortOrder.Descending,
    exactMatch: false,
    status: LyantheFileStatus.Error,
  });

  const [modalState, setModalState] = useState<RejectModalState>('hidden');
  const [currentFile, setCurrentFile] = useState<LyantheFileWithOwner>();
  const [selectedFiles, setSelectedFiles] = useState<LyantheFileWithOwner[]>(
    [],
  );

  const reprocessSelected = async () => {
    try {
      setLoading(true);
      await reprocessFileBatch(selectedFiles.map((x) => ({ id: x.id })));
      fetchData(tableSettings);
      setSelectedFiles([]);
      showNotification(
        'success',
        'Geselecteerde opnieuw ter verwerking gesteld.',
      );
    } catch (e) {
      showNotification('error', 'Fout tijdens opnieuw verwerken facturen');
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (tableSettings: LyantheFileFilter) => {
    setLoading(true);
    try {
      const files = await getFileScanStatus(tableSettings);

      setTotal(files.total);

      const relationIds = Array.from(
        new Set(
          files.data
            .filter((x) => x.ownerRelationId)
            .map((x) => x.ownerRelationId),
        ),
      );

      const relations = relationIds.length
        ? (
            await getRelations({
              relationIds: relationIds,
            })
          ).data
        : [];

      const filesWithOwner: LyantheFileWithOwner[] = files.data.map((x) => ({
        ...x,
        owner: relations.find((relation) => relation.id === x.ownerRelationId),
      }));
      setFiles(filesWithOwner);
    } catch (e) {
      console.log(e);
      showNotification('error', 'Fout tijdens ophalen facturen');
    } finally {
      setLoading(false);
    }
  };

  const reprocessLyantheFile = async (id: number) => {
    try {
      setLoading(true);
      await reprocessFile(id);
      showNotification('success', 'Opnieuw ter verwerking gesteld.');
      fetchData(tableSettings);
    } catch {
      console.log('Fout tijdens herverwerken factuur.');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(tableSettings);
  }, [tableSettings]);

  const showSelectedConfirmModal = (
    tKey: string,
    onOk: () => Promise<void> | void,
  ) => {
    const overflow = selectedFiles.length > 10;
    const contentText = overflow
      ? t(`${tKey}.contentOverflow`, {
          filenames: selectedFiles
            .slice(0, 10)
            .map((x) => x.documentName)
            .join(', '),
          count: selectedFiles.length - 10,
        })
      : t(`${tKey}.content`, {
          filenames: selectedFiles.map((x) => x.documentName).join(', '),
        });

    Modal.confirm({
      title: t(`${tKey}.title`),
      content: contentText,
      okText: t(`${tKey}.ok`),
      cancelText: t('general.actions.cancel'),
      width: '800px',
      onOk,
      onCancel() {},
    });
  };

  const tableColumns: CustomColumnType<LyantheFile>[] = [
    {
      title: t('lyanthedata.columns.id'),
      dataIndex: 'id',
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.owner.customerNumber'),
      dataIndex: 'ownerCustomerNumber',
      render: (_, row: LyantheFileWithOwner) => {
        return row.owner?.identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier;
      },
      sorter: false,
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.ocrDocumentIdentifier'),
      dataIndex: 'ocrDocumentIdentifier',
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.documentName'),
      dataIndex: 'documentName',
      render: (text, _) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.type'),
      dataIndex: 'type',
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.status'),
      dataIndex: 'status',
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.statusDescription'),
      dataIndex: 'statusDescription',
      render: (value) =>
        value
          ? t(`api.${value}`, { ns: 'errors', defaultValue: value })
          : undefined,
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.ownerRelationId'),
      dataIndex: 'ownerRelationId',
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.uploadDate'),
      dataIndex: 'uploadDate',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      ...baseColumnSettings,
      title: t('lyanthedata.columns.updated'),
      dataIndex: 'updated',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      width: 120,
      dataIndex: 'id',
      render: (id, row) => (
        <Space>
          <DocumentViewModal id={row.incomingFileId} idType="incomingfile" />
          <ScopeTrackingButton scopeId={row.scopeId} />
          <Popconfirm
            title={t('lyanthedata.actions.reprocess.single', {
              filename: row.documentName,
            })}
            onConfirm={() => reprocessLyantheFile(id)}
          >
            <CustomButton
              type="link"
              toolTipKey="lyanthedata.action.reprocess"
              icon={<FontAwesomeIcon icon="redo" />}
            />
          </Popconfirm>
          {row.status === LyantheFileStatus.Error && (
            <CustomButton
              type="link"
              onClick={() => openSingleRejectModal(row)}
              toolTipKey="lyanthedata.actions.delete"
              danger
              shape="circle"
              size="small"
              icon={<FontAwesomeIcon icon="trash-alt" />}
            />
          )}
        </Space>
      ),
    },
  ];

  const onTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    const filter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'deliveryDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
    setLoading(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: LyantheFile[]) => {
      setSelectedFiles(selectedRows);
    },
    getCheckboxProps: (record: LyantheFile) => ({}),
    preserveSelectedRowKeys: false,
  };

  const openSingleRejectModal = (file: LyantheFileWithOwner) => {
    setCurrentFile(file);
    setModalState('single');
  };

  const openMultiRejectModal = () => {
    setModalState('multi');
  };

  const closeRejectModal = () => {
    setModalState('hidden');
  };

  const rejectFile = async (
    file: RejectFile,
    reason: string,
    description?: string,
  ) => {
    try {
      await deleteFile(file.id as number, reason, description);
      showNotification('success', 'Successvol verwijderd');
      fetchData(tableSettings);
      closeRejectModal();
    } catch {
      showNotification('error', 'Fout tijdens verwijderen factuur');
    }
  };

  const rejectBatch = async (
    files: RejectFile[],
    reason: string,
    description?: string,
  ) => {
    try {
      setLoading(true);
      await deleteFileBatch(
        files.map((x) => ({ id: x.id as number, reason, description })),
      );
      showNotification('success', 'Geselecteerde bestanden verwijderd.');
      fetchData(tableSettings);
      setSelectedFiles([]);
      closeRejectModal();
    } catch (e) {
      showNotification('error', 'Fout tijdens verwijderen bestanden.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InvoiceRejectModal
        modalState={modalState}
        hideModal={closeRejectModal}
        singleFile={
          currentFile
            ? {
                id: currentFile.id,
                name: currentFile?.documentName,
              }
            : undefined
        }
        multiFiles={selectedFiles.map((x) => ({
          id: x.id,
          name: x.documentName,
        }))}
        rejectSingle={rejectFile}
        rejectBatch={rejectBatch}
      />
      <Row justify="space-between" align="bottom">
        <Col>
          Toon:
          <Radio.Group
            disabled={isLoading}
            style={{ marginLeft: 10 }}
            optionType="button"
            onChange={(e) =>
              setTableSettings((current) => ({
                ...current,
                status: e.target.value,
                page: 1,
              }))
            }
            defaultValue={tableSettings.status}
            buttonStyle="solid"
          >
            <Radio.Button value={LyantheFileStatus.Error}>Fout</Radio.Button>
            <Radio.Button value={LyantheFileStatus.Processing}>
              In verwerking
            </Radio.Button>
            <Radio.Button value={LyantheFileStatus.Uploaded}>
              Uploaded
            </Radio.Button>
            {hasAccess.isSupport ? (
              <Radio.Button value={LyantheFileStatus.None}>None</Radio.Button>
            ) : null}
          </Radio.Group>
        </Col>
        <Col flex={1}>
          <Row justify="end" gutter={16} align="middle">
            <Col>
              <span>
                {selectedFiles.length
                  ? `${selectedFiles.length} geselecteerd`
                  : null}
              </span>
            </Col>
            <Col>
              <Space>
                <CustomButton
                  toolTipKey="lyanthedata.action.delete"
                  disabled={!selectedFiles.length}
                  type="primary"
                  danger
                  icon={<FontAwesomeIcon icon="trash-alt" />}
                  onClick={openMultiRejectModal}
                />

                <CustomButton
                  toolTipKey="lyanthedata.action.reprocess"
                  disabled={!selectedFiles.length}
                  type="primary"
                  onClick={() =>
                    showSelectedConfirmModal(
                      'lyanthedata.actions.reprocess',
                      reprocessSelected,
                    )
                  }
                  icon={<FontAwesomeIcon icon="redo" />}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomTable
        rowSelection={rowSelection}
        rowKey="id"
        style={{ marginTop: '2rem' }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={files}
        onChange={onTableChange}
        pagination={{
          current: tableSettings.page,
          pageSize: tableSettings.pageSize,
          hideOnSinglePage: true,
          total: total,
          onChange: () => {},
        }}
        size="small"
      />
    </>
  );
};
