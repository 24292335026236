import { CurrencyDisplay } from '@/lib/components';
import { Invoice } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToExtendedInvoice } from '../../../lib/utils/extendedInvoice';
import styles from './styles.module.scss';

interface ListItemProps {
  invoice: Invoice;
}
export const DossierRow: FC<ListItemProps> = ({ invoice }) => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const rowClasses = [styles.invoicerow];

  if (!invoice) return null;

  if (params.id === invoice.invoiceId.toString())
    rowClasses.push(styles.active);

  const extendedInvoice = convertToExtendedInvoice(invoice);
  return (
    <Row
      key={extendedInvoice.invoiceId}
      className={rowClasses.join(' ')}
      onClick={() => navigate(`/dossier/${extendedInvoice.invoiceId}`)}
    >
      <Col md={4}>
        <Typography.Text ellipsis>
          {extendedInvoice.invoiceNumber}
        </Typography.Text>
      </Col>
      <Col md={3}>
        {dateTimeFormatter.toDateOnly(extendedInvoice.invoiceDate)}
      </Col>
      <Col flex={1}>{extendedInvoice.counterInvoiceRelation.name}</Col>
      <Col md={2}>
        {extendedInvoice.hasNote && (
          <FontAwesomeIcon className={styles.tag} icon={'comment'} size="sm" />
        )}
      </Col>
      <Col md={3} className="text-right">
        <CurrencyDisplay amount={extendedInvoice.amount} />
      </Col>
    </Row>
  );
};
