import { useFeature } from '@/lib/context';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { GeneralSettings } from './_tabs/GeneralSettings';
import { InvoiceRejectReasonSettings } from './_tabs/InvoiceRejectReasonSettings';
import { IdentifierSettings } from './_tabs/IdentifierSettings';

export const ApplicationSettings = () => {
  const invoiceRejectReasonsEnabled = useFeature(
    'settings.invoiceRejectReasons',
  );
  if (!invoiceRejectReasonsEnabled) {
    return <GeneralSettings />;
  }

  const { t } = useTranslation('management', {
    keyPrefix: 'settings.applicationSettings.tabs',
  });
  const items = [
    {
      label: t('general'),
      key: 'general',
      children: <GeneralSettings key={'general'} />,
    },
    {
      label: t('rejectReasons'),
      key: 'rejectReasons',
      children: <InvoiceRejectReasonSettings key={'rejectReasons'} />,
    },
    {
      label: t('identifiers'),
      key: 'identifiers',
      children: <IdentifierSettings key={'identifiers'} />,
    },
  ];

  return <Tabs items={items} />;
};
