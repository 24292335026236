import { RootColors } from '@/lib/types';

import tinyColor from 'tinycolor2';

export const setTheme = (colors: RootColors) => {
  const doc = document.documentElement.style;

  // map antd keys to desired value to use
  const keyMapping = {
    primary: colors.primary,
    info: colors.primary, //technically does not need hover, outline & active set
    success: colors.success,
    error: colors.danger,
  };

  // update antd colors
  Object.entries(keyMapping).forEach(([key, value]) => {
    const color = tinyColor(value);
    doc.setProperty(`--ant-${key}-color`, color.toRgbString());
    doc.setProperty(
      `--ant-${key}-color-active`,
      color.lighten(15).toRgbString(),
    );
    doc.setProperty(
      `--ant-${key}-color-hover`,
      color.lighten(10).toRgbString(),
    );
    doc.setProperty(
      `--ant-${key}-color-outline`,
      color.darken(15).toRgbString(),
    );
    doc.setProperty(`--color--${key}-color`, color.toRgbString());
    doc.setProperty(
      `--color--${key}-color-active`,
      color.lighten(15).toRgbString(),
    );
    doc.setProperty(
      `--color--${key}-color-hover`,
      color.lighten(10).toRgbString(),
    );
    doc.setProperty(
      `--color--${key}-color-outline`,
      color.darken(15).toRgbString(),
    );
  });

  // update all primary colors (antd sometimes uses this for sliders and such elements)
  // also updating certain deprecated colors, since sliders still seem to use them
  const deprecated = [
    '--ant-primary-color-deprecated-l-35',
    '--ant-primary-color-deprecated-l-20',
    '--ant-primary-color-deprecated-t-20',
    '--ant-primary-color-deprecated-t-50',
  ];
  const base = tinyColor(colors.primary).lighten(50);
  for (let i = 1; i < 8; i++) {
    const color = base.darken(3 * i).toRgbString();
    if (deprecated[i - 1]) doc.setProperty(deprecated[i - 1], color);
    doc.setProperty(`--ant-primary-${i}`, color);
  }

  // update custom colors
  Object.entries(colors).forEach(([key, value]) => {
    const color = tinyColor(value);

    doc.setProperty(`--${key}`, color.toRgbString());
    doc.setProperty(`--${key}--lighter`, color.lighten(10).toRgbString());
    doc.setProperty(`--${key}--lightest`, color.lighten(15).toRgbString());
    doc.setProperty(`--${key}--darker`, color.darken(10).toRgbString());
    doc.setProperty(`--${key}--darkest`, color.darken(15).toRgbString());
    tinyColor(value).isLight()
      ? doc.setProperty(`--text--${key}`, '#111')
      : doc.setProperty(`--text--${key}`, '#fff');
  });
};
