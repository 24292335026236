import { CustomButton } from '@/lib/components';
import { useGetInvoiceStatusReasons } from '@/lib/queries';
import { showNotification } from '@/lib/utils/showNotification';
import { Col, Form, Input, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface InvoiceRejectFormProps {
  onFinish: (reason: string, description: string) => void;
  onCancel: () => void;
}

export const InvoiceRejectForm: React.FunctionComponent<
  InvoiceRejectFormProps
> = ({ onFinish, onCancel }) => {
  const { data, isLoading } = useGetInvoiceStatusReasons();
  const { t } = useTranslation('management', {
    keyPrefix: 'settings.applicationSettings.invoiceRejectReasons',
  });

  const [form] = Form.useForm();

  if (isLoading) {
    return <Spin />;
  }

  const onFormSubmit = (values) => {
    const reason = data?.find((x) => x.id === values.reasonId);
    if (!reason) {
      showNotification('error', 'messages.setError');
      return;
    }
    const statusDescription =
      reason.statusDescription || values.statusDescription;
    onFinish(reason.statusReason, statusDescription);
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFormSubmit}>
      <Form.Item
        label={t('fields.statusReason')}
        name="reasonId"
        rules={[{ required: true }]}
      >
        <Select
          options={data?.map((x) => ({
            value: x.id,
            label: x.statusReason,
          }))}
        />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.reasonId !== curValues.reasonId
        }
      >
        {() => {
          const reasonId = form.getFieldValue('reasonId');
          const reason = data?.find((x) => x.id === reasonId);

          if (reason?.statusDescription) {
            return;
          }

          return (
            <Form.Item
              label={t('fields.statusDescription')}
              name="statusDescription"
              rules={[{ max: 70, required: true }]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Row justify="space-between">
        <Col>
          <CustomButton type="link" loading={isLoading} onClick={onCancel}>
            {t('actions.cancel')}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton htmlType="submit" type="primary" loading={isLoading}>
            {t('actions.reject.ok')}
          </CustomButton>
        </Col>
      </Row>
    </Form>
  );
};
