import { userRolesAtom } from '@/lib/atoms/atoms';
import { useAtom } from 'jotai';
import React from 'react';
import { ProtectedRoute } from './protectedRoute';

interface RoleProtectedRouteProps {
  roles: string[];
}

export const RoleProtectedRoute: React.FunctionComponent<
  RoleProtectedRouteProps
> = ({ roles }) => {
  const [userRoles] = useAtom(userRolesAtom);

  return (
    <ProtectedRoute
      isValid={userRoles.roles?.some((x) => roles.includes(x))}
      redirectPath="/"
    />
  );
};
