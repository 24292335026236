import { Accent } from '@/lib/components';
import { IdentifierCategory } from '@/lib/types/enums';
import { Relation } from '@/lib/types/user';
import { Descriptions, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RelationSummaryProps {
  relation: Relation;
}

export const RelationSummary: React.FunctionComponent<RelationSummaryProps> = ({
  relation,
}) => {
  const { t } = useTranslation();

  return (
    <Descriptions colon={false}>
      <Descriptions.Item
        span={3}
        label={
          <Accent color="primary" type="strong">
            {t('relation.name')}
          </Accent>
        }
      >
        {relation.name}
      </Descriptions.Item>
      <Descriptions.Item
        span={3}
        label={
          <Accent color="primary" type="strong">
            {t('relation.address')}
          </Accent>
        }
      >
        {relation?.street} {relation?.houseNumber}, {relation?.city}
      </Descriptions.Item>
      <Descriptions.Item
        span={3}
        label={
          <Accent color="primary" type="strong">
            {t('relation.zipcode')}
          </Accent>
        }
      >
        {relation?.zipcode}
      </Descriptions.Item>
      <Descriptions.Item
        span={3}
        label={
          <Accent color="primary" type="strong">
            {t('relation.identifiers')}
          </Accent>
        }
      >
        <Space direction="vertical" size="small">
          {relation.identifiers.map((relationIdentifier, index) => {
            const typeText =
              relationIdentifier.category == IdentifierCategory.COC
                ? 'KVK-nummer'
                : relationIdentifier.category == IdentifierCategory.VAT
                ? 'BTW-nummer'
                : 'Klant-nummer';
            return (
              <Accent type="p" key={index}>
                {relationIdentifier.identifier} - {typeText}
              </Accent>
            );
          })}
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
};
