import { Copyright, ErrorBoundary, InitWrapper } from '@/lib/components';
import { AuthComponent } from '@/lib/route-guards';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ConfigProvider } from 'antd';
import nlNL from 'antd/lib/locale/nl_NL';
import { Provider } from 'jotai';
import './lib/utils/dayjs';
import { Providers } from './providers';
import { AppRouter } from './router';

library.add(far, fas);

export const App = () => {
  return (
    <ConfigProvider locale={nlNL}>
      <Provider>
        <AuthComponent>
          <Providers>
            <InitWrapper>
              <ErrorBoundary>
                <AppRouter />
              </ErrorBoundary>
              <Copyright />
            </InitWrapper>
          </Providers>
        </AuthComponent>
      </Provider>
    </ConfigProvider>
  );
};
