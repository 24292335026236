import { CustomApiError } from '@/lib/types';
import { showNotification } from './showNotification';

export const showApiNotification = (
  e: CustomApiError,
  defaultStatusText: string,
) => {
  const errorText = e.status === 409 ? e.message : defaultStatusText;
  showNotification('error', errorText);
};
