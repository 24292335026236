import { Navigatable } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericModal } from '..';
import { CustomButton } from '../core/buttons';
import { ScopeView } from './scopeView';

interface ScopeTrackingButtonProps extends Navigatable {
  scopeId?: string;
}

export const ScopeTrackingButton: React.FunctionComponent<
  ScopeTrackingButtonProps
> = ({ scopeId, onNavigate }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  if (!scopeId || scopeId === '00000000-0000-0000-0000-000000000000') {
    return null;
  }

  const handleNavigate = () => {
    setIsVisible(false);
    onNavigate?.();
  };

  return (
    <>
      <GenericModal
        title={t('scope.modal.title')}
        isVisible={isVisible}
        hideModal={() => setIsVisible(false)}
        destroyOnClose
        footer={false}
      >
        <ScopeView scopeId={scopeId} onNavigate={handleNavigate} />
        <Row justify="end">
          <CustomButton onClick={() => setIsVisible(false)} type="default">
            {t('general.actions.close')}
          </CustomButton>
        </Row>
      </GenericModal>
      <CustomButton
        size="small"
        shape="circle"
        type="link"
        onClick={() => setIsVisible(true)}
      >
        <FontAwesomeIcon icon="search-location" />
      </CustomButton>
    </>
  );
};
