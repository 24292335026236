import { PaginationParams } from '@/lib/types';
import { Row, Space, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

type ScrollListProps = {
  loadMore: (page: number) => Promise<void>; // items are handled in parent
  pagination: PaginationParams | undefined;
  children?: JSX.Element | undefined; // items are passed here, this makes implementing other things than lists (such as collapse) easier
  height?: React.CSSProperties['height'];
};

export const ScrollList: FC<ScrollListProps> = ({
  loadMore,
  pagination,
  children,
  height = '50vh',
}: // items,
ScrollListProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  // hook for adding incoming items & updating values
  useEffect(() => {
    setHasMore(
      pagination
        ? pagination.total > pagination.page * pagination.pageSize
        : false,
    );
  }, [pagination]);

  const handleLoad = () => {
    // We use our own page numbers; as it won't reset properly using the built-in page number
    const nextPage = (pagination?.page ?? 0) + 1;
    setLoading(true);
    loadMore(nextPage).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <div
        style={{
          height: height,
          overflow: 'auto',
        }}
      >
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={handleLoad}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          {children}
        </InfiniteScroll>
      </div>
      <Row justify="center" style={{ height: 20 }}>
        {loading && hasMore && (
          <Space>
            <Spin /> Loading more...
          </Space>
        )}
      </Row>
    </>
  );
};
