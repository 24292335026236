import { HeaderItem } from '@/lib/components';
import { Col, Row } from 'antd';

export const UserDetailEntry = ({
  label,
  value,
}: {
  label: string;
  value?: string;
}) => {
  return (
    <Row justify="center" style={{ textAlign: 'left' }}>
      <Col span={8}>
        <HeaderItem textKey={label} />
      </Col>
      <Col flex={1}>{value}</Col>
    </Row>
  );
};
