import { InPayment } from '@/routes/payment/inpayment/page';
import { PaymentLayout } from '@/routes/payment/layout';
import { SupplierSettings } from '../settings/suppliers/page';
import { PaidInvoices } from './paid/page';

const paymentRoutes = {
  path: 'payment',
  element: <PaymentLayout />,
  children: [
    { path: 'inpayment', element: <InPayment /> },
    { path: 'paid', element: <PaidInvoices /> },
    { path: 'suppliers', element: <SupplierSettings /> },
  ],
};

export default paymentRoutes;
