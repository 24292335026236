import { DetailItem } from '@/lib/components';
import { useGetAdministrationDetails } from '@/lib/queries';
import { Skeleton, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LyantheAdministrationDetailsProps {
  administrationId?: string;
}

export const LyantheAdministrationDetails: React.FunctionComponent<
  LyantheAdministrationDetailsProps
> = ({ administrationId }) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'relations.addRelation.steps.lyanthe',
  });
  const { data, isLoading } = useGetAdministrationDetails(administrationId);

  return (
    <Skeleton loading={isLoading} active>
      <Space direction="vertical" style={{ width: '100%' }}>
        <DetailItem name={t('labels.id')} value={data?.id} />
        <DetailItem name={t('labels.name')} value={data?.name} />
        <DetailItem name={t('labels.address')} value={data?.address} />
        <DetailItem name={t('labels.zipcode')} value={data?.zipCode} />
        <DetailItem name={t('labels.city')} value={data?.city} />
        <DetailItem name={t('labels.country')} value={data?.country} />
      </Space>
    </Skeleton>
  );
};
