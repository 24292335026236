import {
  Label,
  LabelDetails,
  LabelModifyRequest,
  LabelValue,
  LabelValueModifyRequest,
} from '@/lib/types/labels';
import { api } from '@/lib/utils/api.axios';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.user}/v1/labels`;

export const getLabels = () =>
  api.get<Label[]>(`${BASE_URL}`).then((r) => r.data);

export const getLabelDetails = (id: number) =>
  api.get<LabelDetails>(`${BASE_URL}/${id}`).then((r) => r.data);

export const updateLabel = (id: number, request: LabelModifyRequest) =>
  api.put<Label>(`${BASE_URL}/${id}`, request).then((r) => r.data);

export const getLabelValues = (id: number) =>
  api.get<LabelValue[]>(`${BASE_URL}/${id}/values`).then((r) => r.data);

export const createLabelValue = (
  id: number,
  request: LabelValueModifyRequest,
) =>
  api.post<LabelValue>(`${BASE_URL}/${id}/values`, request).then((r) => r.data);

export const updateLabelValue = (
  id: number,
  request: LabelValueModifyRequest,
) => api.put<Label>(`${BASE_URL}/values/${id}`, request).then((r) => r.data);

export const deleteLabelValue = (id: number) =>
  api.delete<LabelValue>(`${BASE_URL}/values/${id}`).then((r) => r.data);
