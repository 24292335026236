import { Table, TablePaginationConfig, TableProps } from 'antd';
import {
  ColumnType,
  FilterValue,
  SorterResult,
} from 'antd/lib/table/interface';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../buttons';
import {
  DefaultRender,
  DefaultSearch,
  getDatePickerSearch,
  getDefaultRender,
  getDefaultSearch,
  getDefaultSorter,
} from './helper';

export interface CustomColumnType<T> extends ColumnType<T> {
  enableDefaultSearch?: boolean;
  enableDefaultSorter?: boolean;
  defaultSorterType?: string;
  defaultRender?: DefaultRender;
  defaultSearch?: DefaultSearch;
}

export interface CustomTableBaseProps<T> extends TableProps<T> {
  columns: CustomColumnType<T>[];
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
}

export const CustomTableBase: FC<CustomTableBaseProps<any>> = <
  Type extends object,
>(
  props: CustomTableProps<Type>,
) => {
  const { pagination, columns, ...rest } = props;

  const PAGINATION_OPTIONS: TablePaginationConfig = {
    hideOnSinglePage: true,
    ...pagination,
  };

  const columnsConfiguration = columns?.map((x) => {
    if (!x.render && x.defaultRender) {
      x.render = getDefaultRender(x.defaultRender);
    }

    if (!x.title) {
      return x;
    }

    const filterOptions =
      x.defaultSearch === 'dateonly'
        ? getDatePickerSearch(x.dataIndex, x.defaultRender, x?.onFilter)
        : x.enableDefaultSearch ?? true
        ? getDefaultSearch(x.dataIndex, x.title, x.defaultRender, x?.onFilter)
        : {};

    const sorterOptions =
      x.enableDefaultSorter ?? true
        ? {
            sorter: (a, b) =>
              getDefaultSorter(a, b, x.dataIndex, x.defaultSorterType),
          }
        : {};

    return {
      ...x,
      ...filterOptions,
      ...sorterOptions,
    };
  });

  return (
    <Table<Type>
      size="small"
      pagination={PAGINATION_OPTIONS}
      columns={columnsConfiguration}
      {...rest}
    />
  );
};

export interface CustomTableProps<T> extends CustomTableBaseProps<T> {
  clearFilter?: boolean;
}

interface TableState<T> {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<T> | SorterResult<T>[];
}
export const CustomTable: FC<CustomTableProps<any>> = <Type extends object>({
  pagination,
  columns,
  onChange,
  clearFilter,
  ...rest
}: CustomTableProps<Type>) => {
  const { t } = useTranslation('', { keyPrefix: 'general' });
  const [tableState, setTableState] = useState<TableState<Type>>({
    pagination: {
      ...pagination,
    },
    filters: {},
    sorter: {},
  });

  useEffect(() => {
    onChange?.(tableState.pagination, tableState.filters, tableState.sorter);
  }, [tableState]);

  const columnsConfiguration = columns?.map((x) => {
    return {
      ...x,
      filteredValue:
        tableState.filters[(x.key || x.dataIndex) as string] || null,
    };
  });

  const onChangeHandler = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Type> | SorterResult<Type>[],
  ) => {
    setTableState((cv) => ({
      pagination: {
        ...cv?.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters: filters,
      sorter: sorter,
    }));
  };

  const clearFilters = () => {
    setTableState((cv) => ({
      pagination: cv?.pagination,
      filters: {},
      sorter: cv.sorter,
    }));
  };

  return (
    <>
      {(clearFilter ?? true) && (
        <CustomButton
          type="link"
          size="small"
          onClick={clearFilters}
          style={{ marginTop: '8px' }}
        >
          {t('actions.clearFilter')}
        </CustomButton>
      )}
      <CustomTableBase
        columns={columnsConfiguration}
        pagination={{
          ...tableState.pagination,
          total: pagination ? pagination.total : 0,
        }}
        onChange={onChangeHandler}
        {...rest}
        style={{ ...rest.style, marginTop: '4px' }}
      />
    </>
  );
};
