import { getInvoicesZipFile } from '@/lib/adapters/invoice-adapter';
import { CustomButton, CustomButtonProps } from '@/lib/components';
import { downloadUrl } from '@/lib/utils/download-file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';

interface DownloadZipProps extends CustomButtonProps {
  invoices: number[];
}

export const DownloadZipButton: FC<DownloadZipProps> = ({
  invoices,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(false);

  const onclick = async () => {
    try {
      setLoading(true);
      const filename = `${new Date().getTime()}_dossierfacturen.zip`;
      const zipFile = await getInvoicesZipFile(filename, invoices);
      const blobUrl = window.URL.createObjectURL(new Blob([zipFile]));
      downloadUrl(blobUrl, filename);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomButton
        icon={<FontAwesomeIcon icon={['fas', 'archive']} size="sm" />}
        onClick={onclick}
        loading={isLoading}
        toolTipKey="dossier.action.zip"
        {...rest}
      >
        Download ZIP
      </CustomButton>
    </>
  );
};
