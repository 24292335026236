import { HeaderItem } from '@/lib/components';
import { Col, Row } from 'antd';
import styles from './styles.module.scss';

export const DossierHeader = () => {
  return (
    <Row className={styles.headerRow}>
      <Col md={4}>
        <HeaderItem textKey="dossier.header.invoiceNumber" />
      </Col>
      <Col md={3}>
        <HeaderItem textKey="dossier.header.invoiceDate" />
      </Col>
      <Col flex={1}>
        <HeaderItem textKey="dossier.header.supplierName" />
      </Col>
      <Col md={2}></Col>
      <Col md={3} className="text-right">
        <HeaderItem textKey="dossier.header.invoiceAmount" />
      </Col>
    </Row>
  );
};
