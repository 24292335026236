import { IdentifierType, IdentifierTypeModifyRequest } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['identifiertypes'];
const BASE_URL = `${apiBasePaths.user}/v2/identifiertypes`;

export const useGetIdentifierTypes = (): UseQueryResult<
  IdentifierType[],
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () =>
      api
        .get(`${BASE_URL}`)
        .then((r) =>
          r.data.sort((a: IdentifierType, b: IdentifierType) =>
            a.type.localeCompare(b.type),
          ),
        ),
  });

export const useCreateIdentifierTypes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: IdentifierTypeModifyRequest) =>
      api.post(`${BASE_URL}`, request).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};

export const useUpdateIdentifierTypes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      request,
    }: {
      id: number;
      request: IdentifierTypeModifyRequest;
    }) => api.put(`${BASE_URL}/${id}`, request).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};

export const useDeleteIdentifierTypes = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`${BASE_URL}/${id}`).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};
