import { createDocument, createInvoice } from '@/lib/adapters/invoice-adapter';
import { applicationSettingsAtom } from '@/lib/atoms/atoms';
import { Accent, CustomButton } from '@/lib/components';
import { useCurrentUserContext, useRelationContext } from '@/lib/context';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  DatePicker,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Upload,
  UploadFile,
  notification,
} from 'antd';
import { useAtomValue } from 'jotai/utils';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from './userAvatar';

const { Dragger } = Upload;

type UploadFormProps = {
  onSuccess?: () => void;
  onCancel?: () => void;
  visible: boolean;
};

export const UploadForm: FC<UploadFormProps> = ({
  visible,
  onCancel,
  onSuccess,
}) => {
  const { relation } = useRelationContext();
  const user = useCurrentUserContext();
  const { maxFileCount: settingMaxFileCount, maxFileSize: settingMaxFileSize } =
    useAtomValue(applicationSettingsAtom);
  const [maxFileCount, setMaxFileCount] = useState(settingMaxFileCount);
  const [nameField, setNameField] = useState('');
  const [yearField, setYearField] = useState<Moment>(moment());
  const [myFiles, setMyFiles] = useState<UploadFile[]>([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadMethod, setUploadMethod] = useState<
    'purchase' | 'sales' | 'other' | ''
  >('');
  const { t } = useTranslation();

  useEffect(() => {
    setNameField('');
    setYearField(moment());
    if (uploadMethod === 'other') {
      setMaxFileCount(1);
    } else {
      setMaxFileCount(settingMaxFileCount);
    }
  }, [uploadMethod, settingMaxFileCount]);

  useEffect(() => {
    setMyFiles((files) => {
      let current = [...files];
      if (current.length > maxFileCount) {
        current = current.slice(0, maxFileCount);
      }
      return current;
    });
  }, [maxFileCount]);

  const draggerSettings = {
    name: 'file',
    multiple: true,
    fileList: myFiles,
    beforeUpload: (file, fileList) => {
      let cloneList = [...myFiles, ...fileList];
      setMyFiles(cloneList);
      return false;
    },
    onRemove: (file) => {
      const index = myFiles.indexOf(file);
      const newFileList = myFiles.slice();
      newFileList.splice(index, 1);
      setMyFiles(newFileList);
    },
  };

  function belowMaxFileSize(file: Blob) {
    return file.size <= 1024 * 1000 * settingMaxFileSize;
  }

  function overMinFileSize(file: Blob) {
    return file.size > 1024 * 1;
  }

  function successInvoiceNotification(filename: string) {
    showNotification('success', `factuur met naam: ${filename} is opgeslagen`);
  }

  const changeNameField = (e) => setNameField(e.target.value);

  const changeYearField = (e) => setYearField(e);

  const uploadFile = async (
    file: any,
    method: 'purchase' | 'sales' | 'other',
  ) => {
    if (!overMinFileSize(file)) {
      throw new Error(`Minimale grootte is 1kb`);
    }
    if (!belowMaxFileSize(file)) {
      throw new Error(`Maximale grootte is ${settingMaxFileSize} MB`);
    }

    try {
      if (method === 'other') {
        await createDocument(
          file as Blob,
          nameField && nameField,
          yearField && yearField.year(),
        );
      } else {
        await createInvoice(file as Blob, method);
      }
    } catch (e: any) {
      if (e.status === 409) {
        throw new Error(t('Error.DuplicateUpload'));
      }
      throw new Error('Fout tijdens uploaden');
    }
  };

  const showUploadResult = (files) => {
    if (!Array.isArray(files) || files.length === 0) {
      return;
    }

    const successFiles = files.filter((x) => x.status === 'success');
    const errorFiles = files.filter((x) => x.status === 'error');
    showNotification(
      'info',
      t('actionResults.upload', {
        successCount: successFiles.length,
        totalCount: files.length,
      }),
    );

    if (errorFiles.length) {
      showNotification(
        'error',
        t('actionResults.uploadError', { errorCount: errorFiles.length }),
      );
    }
  };

  const handleUpload = async () => {
    if (!uploadMethod) return;
    if (myFiles.length > maxFileCount) {
      notification['error']({
        message: t('Error.TooMany'),
        description: `Max: ${maxFileCount}`,
      });
      return;
    }
    setUploading(true);
    setMyFiles(
      myFiles.map((file) => Object.assign(file, { status: 'uploading' })),
    );

    for (let i = 0; i < myFiles.length; i++) {
      try {
        const file = myFiles[i];
        await uploadFile(file, uploadMethod);
        successInvoiceNotification(file.name);
        updateFileState(i, { status: 'success' });
      } catch (e: any) {
        updateFileState(i, { status: 'error', response: e.message });
      }
    }

    setUploading(false);

    showUploadResult(myFiles);
  };

  const onModalCancel = () => {
    let notUploaded = false;
    for (let i = 0; i < myFiles.length; i++) {
      let e = myFiles[i];
      if (['error', 'uploading', undefined].includes(e.status)) {
        notUploaded = true;
      }
    }

    if (notUploaded || isUploading) {
      Modal.confirm({
        title: t('upload.cancel.title'),
        content: t('upload.cancel.message'),
        okText: t('upload.cancel.confirm'),
        cancelText: t('upload.cancel.cancel'),
        onOk: onCancel,
      });

      return;
    }

    if (onCancel) onCancel();
  };

  const updateFileState = (i, fileStateUpdate) => {
    setMyFiles((files) => {
      const copy = [...files];
      const file = copy[i];
      copy[i] = Object.assign(file, fileStateUpdate);
      return copy;
    });
  };

  const clearUpload = () => {
    setMyFiles([]);
    setUploadMethod('');
  };

  return (
    <Modal
      open={visible}
      onCancel={onModalCancel}
      destroyOnClose
      footer={false}
      width={400}
      bodyStyle={{ padding: 0 }}
      style={{ padding: 0, borderRadius: '10px', overflow: 'hidden' }}
    >
      <div>
        <div>
          <Dragger
            maxCount={maxFileCount}
            {...draggerSettings}
            style={{
              backgroundColor: 'rgba(155,215,209, 0.2)',
              border: 'none',
            }}
            onDrop={(e) => console.log(e)}
          >
            <Accent
              type="h2"
              color="secondary"
              style={{ opacity: 0.12, margin: '3rem 0', borderRadius: 0 }}
            >
              <FontAwesomeIcon icon="file-invoice" size="3x" />
            </Accent>
            <Accent type="h2" color="secondary">
              {t('upload.choose')}
            </Accent>
            <Accent
              type="p"
              style={{ textAlign: 'center', padding: '0 0.8rem' }}
            >
              {t('upload.select')}
            </Accent>
            <Accent
              type="p"
              style={{ textAlign: 'center', padding: '0 0.8rem' }}
            >
              Maximaal {maxFileCount} bestanden
            </Accent>
          </Dragger>
        </div>
        <div style={{ padding: '1rem 1rem' }}>
          <Space>
            <Accent
              color={myFiles.length > maxFileCount ? 'danger' : 'default'}
            >
              Totaal: {myFiles.length} files
            </Accent>
            <CustomButton
              onClick={() => clearUpload()}
              disabled={myFiles.length == 0}
              size="small"
              toolTipKey="invoice.action.clearUpload"
              ghost
              danger
            >
              {t('upload.invoice.clear')}
            </CustomButton>
          </Space>
        </div>
        <div style={{ padding: '1rem 1rem' }}>
          <Accent type="h2">{t('upload.invoice.title')}</Accent>
          <Accent type="div" color="tertiary">
            {t('upload.invoice.radio.title')}
          </Accent>
          <Radio.Group
            onChange={(e) => setUploadMethod(e.target.value)}
            value={uploadMethod}
          >
            <Radio value="purchase">{t('upload.invoice.radio.purchase')}</Radio>
            <Radio value="sales">{t('upload.invoice.radio.sales')}</Radio>
            <Radio value="other">{t('upload.invoice.radio.other')}</Radio>
          </Radio.Group>
        </div>
        {uploadMethod === 'other' && (
          <Row style={{ padding: '0.5rem 1rem' }}>
            <Col xs={12}>
              <Input
                value={nameField}
                onChange={changeNameField}
                placeholder={t('upload.invoice.name')}
                status={!nameField ? 'error' : undefined}
              />
            </Col>
            <Col xs={12}>
              <DatePicker
                onChange={changeYearField}
                value={yearField}
                style={{ width: '100%' }}
                placeholder={t('upload.invoice.year')}
                picker="year"
              />
            </Col>
          </Row>
        )}
        <div style={{ padding: '1rem' }}>
          <Accent type="h2" color="tertiary">
            {t('upload.invoice.current_relation')}
          </Accent>
          <UserAvatar relation={relation} username={user?.username} />
        </div>
        <div style={{ padding: '1rem', textAlign: 'center' }}>
          <CustomButton
            color="primary"
            size="large"
            shape="round"
            toolTipKey="invoice.action.upload"
            onClick={handleUpload}
            disabled={
              myFiles.length === 0 ||
              uploadMethod === '' ||
              (uploadMethod === 'other' && (!yearField || !nameField))
            }
            loading={isUploading}
            style={{ marginTop: 16 }}
          >
            {isUploading ? 'Uploading' : `Start Upload`}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};
