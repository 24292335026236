import {
  getAllInvoicesWithouthRelation,
  rejectProcessFile,
  rejectProcessFileBatch,
} from '@/lib/adapters/invoice-adapter';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  InvoiceRejectModal,
} from '@/lib/components';
import { InvoiceWithouthRelation } from '@/lib/types';
import { RejectFile, RejectModalState } from '@/lib/types/reject-invoice';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const InvoicesWithouthRelation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalState, setModalState] = useState<RejectModalState>('hidden');
  const [currentFile, setCurrentFile] = useState<InvoiceWithouthRelation>();
  const [selectedFiles, setSelectedFiles] = useState<InvoiceWithouthRelation[]>(
    [],
  );

  const [invoices, setInvoices] = useState<InvoiceWithouthRelation[]>([]);
  const [isLoading, setLoading] = useState(true);

  const tableColumns: CustomColumnType<InvoiceWithouthRelation>[] = [
    {
      title: t('invoiceWithouthRelation.deliveryDate'),
      dataIndex: 'deliveryDate',
      width: 200,
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: t('invoiceWithouthRelation.documentName'),
      dataIndex: 'documentName',
      width: 300,
    },
    {
      title: t('invoiceWithouthRelation.customerIdentifier'),
      dataIndex: 'customerIdentifier',
      width: 300,
      render: (_, record) =>
        `${record.customerIdentifier} (${record.customerIdentifierType})`,
    },
    {
      title: t('invoiceWithouthRelation.supplierIdentifier'),
      dataIndex: 'supplierIdentifier',
      width: 300,
      render: (_, record) =>
        `${record.supplierIdentifier} (${record.supplierIdentifierType})`,
    },
    {
      title: t('invoiceWithouthRelation.uploadType'),
      dataIndex: 'uploadType',
      render: (value) => t(`uploadTypes.${value}`),
    },

    {
      width: 120,
      render: (_, invoice) => (
        <>
          <CustomButton
            type="link"
            toolTipKey="linkrelations.action.link"
            onClick={() => navigate(`${invoice.id}`)}
            icon={<FontAwesomeIcon icon="pencil-alt" />}
          />

          <CustomButton
            type="link"
            loading={isLoading}
            onClick={() => openSingleRejectModal(invoice)}
            toolTipKey="linkrelations.action.reject"
            danger
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon="ban" />}
          />
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: InvoiceWithouthRelation[],
    ) => {
      setSelectedFiles(selectedRows);
    },
    getCheckboxProps: (record: InvoiceWithouthRelation) => ({}),
    preserveSelectedRowKeys: false,
  };

  const fetchData = async () => {
    getAllInvoicesWithouthRelation()
      .then((r) => setInvoices(r))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const openSingleRejectModal = (file: InvoiceWithouthRelation) => {
    setCurrentFile(file);
    setModalState('single');
  };

  const openMultiRejectModal = () => {
    setModalState('multi');
  };

  const closeRejectModal = () => {
    setModalState('hidden');
  };

  const rejectFile = async (
    file: RejectFile,
    reason: string,
    description?: string,
  ) => {
    try {
      await rejectProcessFile(file.id as string, reason, description);
      showNotification('success', 'Afgewezen.');
      fetchData();
      closeRejectModal();
    } catch {
      showNotification('error', 'Fout tijdens afwijzen.');
    }
  };

  const rejectBatch = async (
    files: RejectFile[],
    reason: string,
    description?: string,
  ) => {
    try {
      setLoading(true);
      await rejectProcessFileBatch(
        files.map((x) => ({ id: x.id as string, reason, description })),
      );
      showNotification('success', 'Geselecteerde facturen afgewezen');
      fetchData();
      setSelectedFiles([]);
      closeRejectModal();
    } catch (e) {
      showNotification('error', 'Fout tijdens afwijzen facturen');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InvoiceRejectModal
        modalState={modalState}
        hideModal={closeRejectModal}
        singleFile={
          currentFile
            ? {
                id: currentFile.id,
                name: currentFile?.documentName,
              }
            : undefined
        }
        multiFiles={selectedFiles.map((x) => ({
          id: x.id,
          name: x.documentName,
        }))}
        rejectSingle={rejectFile}
        rejectBatch={rejectBatch}
      />
      <Row justify="space-between" align="bottom">
        <Col flex={1}>
          <Row justify="end" gutter={16} align="middle">
            <Col>
              <span>
                {selectedFiles.length
                  ? `${selectedFiles.length} geselecteerd`
                  : null}
              </span>
            </Col>
            <Col>
              <CustomButton
                disabled={!selectedFiles.length}
                onClick={openMultiRejectModal}
                type="primary"
                toolTipKey="invoiceWithouthRelation.action.reject"
                danger
                shape="round"
                icon={<FontAwesomeIcon icon="ban" />}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomTable
        rowKey="id"
        rowSelection={rowSelection}
        style={{ marginTop: '2rem' }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={invoices}
        pagination={{ hideOnSinglePage: true }}
        size="small"
      />
    </>
  );
};
