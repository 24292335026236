import { FilterBase } from '@/lib/types';

export const convertEnumToArray = (data) => {
  const arrayObjects = [] as { key: any; value: any }[];
  // Retrieve key and values using Object.entries() method.
  for (const [propertyKey, propertyValue] of Object.entries(data)) {
    // Ignore keys that are not numbers
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }

    // Add keys and values to array
    arrayObjects.push({ key: propertyValue, value: propertyKey });
  }

  return arrayObjects;
};

export const createFilterParams = (filter?: FilterBase) => {
  // Because of the array in the filter
  // it is required to this in using URLSearchParams
  //  or a similar way: https://stackoverflow.com/a/43208627
  if (!filter) {
    return;
  }

  const valueIsKeyValue = (value: any): boolean => {
    return (
      value && typeof value === 'object' && 'value' in value && 'key' in value
    );
  };

  const params = new URLSearchParams();
  for (let [key, value] of Object.entries(filter)) {
    if (typeof value === 'undefined') {
      continue;
    }

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const item = value[i];
        const itemKey = `${key}[${i}]`;
        if (valueIsKeyValue(item)) {
          params.append(`${itemKey}.key`, item.key);
          params.append(`${itemKey}.value`, item.value);
        } else {
          params.append(`${itemKey}`, item);
        }
      }
      continue;
    }

    if (typeof value == 'object') {
      let flattened = flattenObject(value);

      for (let [subkey, value] of Object.entries(flattened)) {
        if (value) params.append(`${key}.${subkey}`, value as string); // object is flattened, so no sub object should be possible at this point
      }
      continue;
    }

    params.append(key, value);
  }

  return params;
};

function flattenObject(obj: any, parent: string | null = null, res = {}) {
  for (let [key, value] of Object.entries(obj)) {
    let propName = parent ? parent + '.' + key : key;
    if (typeof value == 'object') {
      flattenObject(value, propName, res);
    } else {
      res[propName] = value;
    }
  }
  return res;
}
