import { Invoice, PaymentInfo, PaymentSetting } from '@/lib/types';
import { sum } from '@/lib/utils/array-utils';
import dayjs from 'dayjs';

export const getNetInvoiceAmount = (
  invoice: Invoice,
  nettingInvoices: Invoice[],
) => {
  const nettingAmount = sum(nettingInvoices, 'amount');
  return invoice.amount - Math.abs(nettingAmount);
};

export const getPaymentInfo = (
  invoice: Invoice,
  paymentSetting?: PaymentSetting,
): PaymentInfo => {
  // If there are payment settings; use the payment settings
  // Else use the invoice settings.
  // It could be possible that there are payment settings without value (empty iban etc.).
  // => This is correct; the initial field should be empty in that case.

  const getExecutionDate = (paymentTerm: number) => {
    // The execution date is:
    // 1: if there is a invoice.invoiceDate and paymentTerm; use invoiceDate + paymentTerm (paymentTerm from invoice is ignored as this is mostly incorrect).
    // 2: If they do not exist or in history -> use today + 1;

    let executionDate = invoice.invoiceDate
      ? dayjs(invoice.invoiceDate).add(paymentTerm, 'd')
      : dayjs().add(paymentTerm, 'd');

    // If the date is invalid or before today; use default of today + 1;
    if (!executionDate.isValid() || executionDate.isBefore(dayjs())) {
      executionDate = dayjs();
    }

    return executionDate;
  };

  const paymentTerm = paymentSetting?.paymentTerm ?? 1;
  const discount = paymentSetting?.defaultDiscount ?? 0;

  // If there is only 1 validated bank account, select it
  // If there is more than 1 validated bank account, let the user select
  // If there are none valid bankaccounts, select invoice bank account
  const validBankAccounts = paymentSetting?.bankAccounts.filter(
    (x) => x.isValidated,
  );
  if (validBankAccounts?.length) {
    if (validBankAccounts.length === 1) {
      const first = validBankAccounts[0];
      return {
        bankAccount: {
          iban: first.iban,
          bic: first.bic,
        },
        paymentTerm: paymentTerm,
        discount,
        date: getExecutionDate(paymentTerm),
      };
    }
    return {
      paymentTerm: paymentTerm,
      discount,
      date: getExecutionDate(paymentTerm),
    };
  }

  return {
    bankAccount: {
      iban: invoice.supplier?.iban,
      bic: invoice.supplier?.bic,
    },
    paymentTerm: paymentTerm,
    discount,
    date: getExecutionDate(paymentTerm),
  };
};

export const getDifferences = (
  invoice: Invoice,
  paymentSetting?: PaymentSetting,
  partialPayments?: [{ bankAccount?: { iban: string; bic: string } }],
) => {
  const warnings: string[] = [];

  // Flow
  // If there are no payment settings or the iban is empty; add a warning
  // Possible options
  // 1: PaymentSetting is mssing --> Warning

  // 2: PaymentSetting is not missing AND iban is different than invoice --> Warning
  // 3: PaymentSetting is not missing AND iban is different than input --> Warning

  // 4: Invoice iban is different than input --> Warning

  const validatedAccounts =
    paymentSetting?.bankAccounts.filter((x) => x.isValidated && x.iban) || [];

  if (!validatedAccounts.length) {
    warnings.push('payment.differences.missingPaymentSetting');
  } else {
    if (
      invoice.supplier.iban &&
      !validatedAccounts.some((x) => x.iban === invoice.supplier.iban)
    ) {
      warnings.push('payment.differences.invoiceIbanToSetting');
    }
    if (
      partialPayments?.some(
        (x) => !validatedAccounts.some((v) => v.iban === x.bankAccount?.iban),
      )
    ) {
      warnings.push('payment.differences.inputIbanToSetting');
    }
  }

  if (invoice.supplier.iban) {
    if (partialPayments?.some((x) => x.bankAccount?.iban !== invoice.supplier.iban)) {
      warnings.push('payment.differences.inputIbanToInvoice');
    }
  }

  return warnings;
};