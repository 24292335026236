import { ApplicationSettingsProps, LocationProps, UserPost } from '@/lib/types';
import {
  Relation,
  RelationFilter,
  RelationIdentifier,
  User,
  UserVerifyDetails,
  UserVerifyRequest,
  UserVerifyResponse,
} from '@/lib/types/user';

import { PagedResponse } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.user}/v1`;
const BASE_URL_V2 = `${apiBasePaths.user}/v2`;

export const getLocations = () =>
  api.get<LocationProps[]>(`${BASE_URL}/locations`).then((r) => r.data);

export const getVerifyDetails = () =>
  api
    .get<UserVerifyDetails>(`${BASE_URL}/userverification`)
    .then((r) => r.data);

export const verifyUser = (request: UserVerifyRequest) =>
  api
    .post<UserVerifyResponse>(`${BASE_URL}/userverification`, request)
    .then((r) => r.data);

export const getRelations = (params?: RelationFilter) =>
  api
    .get<PagedResponse<Relation[]>>(`${BASE_URL_V2}/relations`, {
      params: createFilterParams({
        ...params,
      }),
      timeout: 60000,
    })
    .then((r) => r.data);

export const getRelationByIdentifier = (params?: {
  identifier: string;
  type: string;
}) =>
  api
    .get<Relation>(`${BASE_URL}/relations/identifier`, {
      params,
    })
    .then((r) => r.data);

export const postRelation = (relation: Relation) =>
  api.post<Relation>(`${BASE_URL}/relations`, relation).then((r) => r.data);

export const putRelation = (id: string, relation: Relation) =>
  api.put<Relation>(`${BASE_URL}/relations/${id}`, relation);

export const deleteRelation = (id: string) =>
  api.delete<Relation>(`${BASE_URL}/relations/${id}`);

export const freezeRelation = (id: string) =>
  api.patch<Relation>(`${BASE_URL}/relations/${id}/freeze`);

export const unFreezeRelation = (id: string) =>
  api.patch<Relation>(`${BASE_URL}/relations/${id}/unfreeze`);

export const blockRelation = (id: string) =>
  api.patch<Relation>(`${BASE_URL}/relations/${id}/block`);

export const unblockRelation = (id: string) =>
  api.patch<Relation>(`${BASE_URL}/relations/${id}/unblock`);

export const getAfasRelation = (identifier: string) =>
  api
    .get<Relation>(`${BASE_URL}/afas/`, { params: { identifier } })
    .then((r) => r.data);

export const getUsers = () =>
  api.get<User[]>(`${BASE_URL}/users/`).then((r) => r.data);

export const postUser = (user: UserPost) =>
  api.post<User>(`${BASE_URL}/users`, user).then((r) => r.data);
export const putUser = (id: string, user: UserPost) =>
  api.put<User>(`${BASE_URL}/users/${id}`, user).then((r) => r.data);

export const deleteUser = (id: string) =>
  api.delete<User>(`${BASE_URL}/users/${id}`).then((r) => r.data);

export const getRolesEnum = () =>
  api.get<string[]>(`${BASE_URL}/roles`).then((r) => r.data);

export const postRelationIdentifier = (
  id: string,
  identifier: RelationIdentifier,
) =>
  api
    .post(`${BASE_URL}/relations/${id}/identifiers`, identifier)
    .then((r) => r.data);

export const getApplicationSettings = () =>
  api.get<ApplicationSettingsProps>(`${BASE_URL}/settings`).then((r) => r.data);

export const postApplicationSettings = (values: ApplicationSettingsProps) =>
  api.post(`${BASE_URL}/settings`, values).then((r) => r.data);

export const updateUserRelation = (userId: string, relationIds: string[]) =>
  api.put<User>(`${BASE_URL}/users/${userId}/relations`, relationIds, {
    headers: { 'Content-Type': 'application/json' },
  });

export const delete2FaCredentials = (userId: string) =>
  api.delete(`${BASE_URL}/users/${userId}/credentials/2fa`);

export const registerLogin = () => api.post(`${BASE_URL}/login`);
