import { Accent, CustomButton } from '@/lib/components';
import { IdentifierCategory, RelationType } from '@/lib/types/enums';
import { RelationIdentifier } from '@/lib/types/user';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const combineIdentifiers = (current, afas) => {
  const identifiers = [...current];
  if (!Array.isArray(afas)) {
    return current;
  }
  for (let item of afas) {
    const exists =
      identifiers.findIndex(
        (x) =>
          x.identifier === item.identifier &&
          x.identifierType === item.identifierType &&
          x.category === item.category,
      ) >= 0;
    if (!exists) {
      identifiers.push(item);
    }
  }

  return identifiers;
};

export const ConvertSummary = ({
  relation,
  setCurrentPage,
  extraData,
  submitConvert,
  type,
  loading,
  afasRelation,
  lyantheRelation,
}) => {
  const { t } = useTranslation();

  let newIdentifiers =
    afasRelation?.identifiers.map((relationIdentifier) => {
      const type =
        relationIdentifier.category === IdentifierCategory.COC
          ? 'NL:KVK'
          : 'ALFA:CUSTOMERNUMBER';

      return {
        category: relationIdentifier.category,
        identifier: relationIdentifier.identifier,
        identifierType: type,
      };
    }) || [];

  if (lyantheRelation) {
    if (lyantheRelation?.vatNumber) {
      newIdentifiers = [
        ...newIdentifiers,
        {
          category: IdentifierCategory.VAT,
          identifier: lyantheRelation.vatNumber,
          identifierType: 'NL:VAT',
        },
      ];
    }
    if (lyantheRelation?.thirdPartyIdentifier) {
      newIdentifiers = [
        ...newIdentifiers,
        {
          category: IdentifierCategory.CUSTOMER,
          identifier: lyantheRelation.thirdPartyIdentifier,
          identifierType: 'LYANTHE:CUSTOMERNUMBER',
        },
      ];
    }
  }

  const identifiers = combineIdentifiers(relation.identifiers, newIdentifiers);

  relation = {
    ...relation,
    name: relation.name || afasRelation?.name,
    street: relation.street || afasRelation?.street,
    houseNumber: relation.houseNumber || afasRelation?.houseNumber,
    zipcode: relation.zipcode || afasRelation?.zipcode,
    city: relation.city || afasRelation?.city,
    country: relation.country || afasRelation?.country,
    locationId: extraData?.locationId || relation.locationId,
    mailbox: extraData?.mailbox,
    safeEmailAddresses: extraData?.safeEmailAddresses,
    theme: extraData?.theme,
    blockBulkNotifications: false,
    type,
    identifiers,
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={24} className="text-center">
          <Accent type="h4" color="secondary">
            Omzetten naar{' '}
            {type === RelationType.External ? 'Extern bedrijf' : 'Klant'}
          </Accent>
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.name')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.name}
        </Col>

        {/* ADDRESS */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.address')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.street} {relation?.houseNumber}, {relation?.city}
        </Col>
        {/* ZIPCODE */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.zipcode')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.zipcode}
        </Col>
        {/* Location */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.location')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {extraData?.locationName}
        </Col>
        {/* Theme */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.theme')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.theme}
        </Col>
        {/* Mailbox */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.mailbox')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.mailbox}
        </Col>
        {/* Safe EmailAdress */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.safeEmailAddress')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.safeEmailAddresses?.map((safeMailAddress, index) => (
            <>
              <div key={index}>{safeMailAddress}</div>
            </>
          ))}
        </Col>

        {/* IDENTIFIERS */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.identifiers')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.identifiers.map(
            (relationIdentifier: RelationIdentifier, index) => {
              return (
                <div key={index}>
                  {relationIdentifier.identifier} -
                  {relationIdentifier.identifierType}
                </div>
              );
            },
          )}
        </Col>
        <Col span={24} className="text-center">
          <div style={{ marginTop: '2rem' }}>
            <CustomButton
              type="link"
              onClick={() => setCurrentPage?.((prev) => prev - 1)}
            >
              Ga terug
            </CustomButton>
            <CustomButton
              type="primary"
              loading={loading}
              onClick={() => submitConvert?.(relation)}
            >
              Bevestigen
            </CustomButton>
          </div>
        </Col>
      </Row>
    </>
  );
};
