import { Invoice, PaginationControls } from '@/lib/types';
import { List } from 'antd';
import { FC } from 'react';
import { DossierRow } from './dossierRow';
import styles from './styles.module.scss';

interface DossiersListProps {
  isLoading: boolean;
  invoices: Invoice[];
  pagination: PaginationControls;
}

export const DossierList: FC<DossiersListProps> = ({
  isLoading,
  invoices,
  pagination,
}) => {
  return (
    <List<Invoice>
      className={styles.dossierList}
      grid={{ column: 1 }}
      pagination={{
        current: pagination.page,
        defaultPageSize: pagination.pageSize,
        onChange: pagination.onChange,
        total: pagination.total,
        size: 'small',
        showSizeChanger: false,
        hideOnSinglePage: true,
        style: { textAlign: 'left' },
      }}
      loading={isLoading}
      dataSource={invoices}
      renderItem={(invoice) => (
        <List.Item key={invoice.invoiceId}>
          <DossierRow invoice={invoice} />
        </List.Item>
      )}
    />
  );
};
