import { Accent, CustomButton } from '@/lib/components';
import { AgrinotaImportRequest, AgrinotaRelation } from '@/lib/types';
import { Relation } from '@/lib/types/user';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IImportSettings } from '../ImportModal';

interface ISummary {
  selectedRelation?: Relation;
  agrinotaRelation?: AgrinotaRelation;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  submitImport?: (relation: AgrinotaImportRequest) => void;
  importSettings: IImportSettings;
  isLoading: boolean;
}

export const Summary: FC<ISummary> = ({
  selectedRelation,
  agrinotaRelation,
  setCurrentPage,
  submitImport,
  importSettings,
  isLoading,
}) => {
  const { t } = useTranslation();

  const request: AgrinotaImportRequest = {
    agrinotaId: agrinotaRelation!.id,
    customerNumbers: agrinotaRelation?.customerNumbers ?? [],
    relationId: selectedRelation!.id,
    ...importSettings,
  };

  return (
    <>
      <Accent type="h4" color="secondary">
        Samenvatting
      </Accent>
      <Row gutter={16}>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.name')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {agrinotaRelation?.name}
        </Col>
        {/* ADDRESS */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.address')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {agrinotaRelation?.street} {agrinotaRelation?.houseNumber},{' '}
          {agrinotaRelation?.city}
        </Col>
        {/* ZIPCODE */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.zipcode')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {agrinotaRelation?.zipcode}
        </Col>
        {/*KVK */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.coc')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {agrinotaRelation?.coC}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('relation.customerNumber')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {agrinotaRelation?.customerNumbers}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            Vanaf Datum
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {dateTimeFormatter.toDateOnly(importSettings.fromDate)}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            Import facturen
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {importSettings.includeInvoices ? (
            <FontAwesomeIcon icon="check-circle" />
          ) : (
            <FontAwesomeIcon icon="times-circle" />
          )}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            Import betaalinstellingen
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {importSettings.inlcudePaymentSettings ? (
            <FontAwesomeIcon icon="check-circle" />
          ) : (
            <FontAwesomeIcon icon="times-circle" />
          )}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            Import documenten
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {importSettings.includeDocuments ? (
            <FontAwesomeIcon icon="check-circle" />
          ) : (
            <FontAwesomeIcon icon="times-circle" />
          )}
        </Col>
        <Col span={24} className="text-center">
          <div style={{ marginTop: '2rem' }}>
            <CustomButton
              type="link"
              onClick={() => setCurrentPage?.((prev) => prev - 1)}
            >
              Ga terug
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={() => submitImport?.(request)}
              disabled={isLoading}
            >
              Bevestigen
            </CustomButton>
          </div>
        </Col>
      </Row>
    </>
  );
};
