import {
  deleteBankAccount,
  postBankAccount,
  putBankAccount,
} from '@/lib/adapters/payment-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton, CustomColumnType, CustomTable } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { useGetBankAccounts } from '@/lib/queries';
import { BankAccount } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Space } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIbanModal } from './_components/AddIbanModal';

interface ModalState {
  visible: boolean;
  loading?: boolean;
  bankAccount?: BankAccount;
}

const RESET_MODAL_STATE: ModalState = {
  visible: false,
  loading: false,
};

export const BankAccountSettings = () => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalState>(RESET_MODAL_STATE);
  const { relation, isFrozen } = useRelationContext();
  const hasAccess = useAtomValue(hasAccessAtom);

  const { isLoading, refetch, data: bankAccounts } = useGetBankAccounts();
  const submitPostBankAccount = async (account: BankAccount) => {
    setModalState((prev) => ({ ...prev, loading: true }));
    try {
      await postBankAccount(account);
      showNotification('success', 'Bankrekening succesvol toegevoegd');
      setModalState(RESET_MODAL_STATE);
    } catch {
      showNotification(
        'error',
        'Fout tijdens toevoegen bankrekening. Probeer opnieuw',
      );
    } finally {
      refetch();
      setModalState((prev) => ({ ...prev, loading: false }));
    }
  };

  const submitPutBankAccount = async (id: number, account: BankAccount) => {
    setModalState((prev) => ({ ...prev, loading: true }));
    await putBankAccount(id, account);
    refetch();
    setModalState(RESET_MODAL_STATE);
  };

  const submitDeleteBankAccount = (id: number) => {
    deleteBankAccount(id).then(() => refetch());
  };

  const onBankAccountClick = (bankAccount: BankAccount) =>
    setModalState({ visible: true, bankAccount });

  const onFinish = (bankAccount: BankAccount) => {
    if (!bankAccount.iban.includes('ABNA')) {
      bankAccount.isBatchPayment = false;
    }

    if (bankAccount.id) submitPutBankAccount(bankAccount.id, bankAccount);
    else submitPostBankAccount(bankAccount);
  };
  const onModalClose = () => setModalState(RESET_MODAL_STATE);

  const tableColumns: CustomColumnType<BankAccount>[] = [
    {
      title: 'IBAN',
      dataIndex: 'iban',
    },
    {
      title: 'BIC',
      dataIndex: 'bankBic',
    },
    {
      align: 'right',
      render(text, record) {
        return (
          <Space direction="horizontal" size={0}>
            <CustomButton
              type="link"
              shape="circle"
              size="small"
              disabled={
                !(
                  hasAccess.isCustomer ||
                  (hasAccess.canImpersonate && relation)
                ) ||
                hasAccess.isAccountManager ||
                isFrozen
              }
              icon={
                <FontAwesomeIcon
                  icon="pencil-alt"
                  onClick={() => onBankAccountClick(record)}
                />
              }
            />

            <Popconfirm
              title="Weet je het zeker?"
              onConfirm={() => submitDeleteBankAccount(record.id)}
            >
              <CustomButton
                type="link"
                danger
                shape="circle"
                size="small"
                disabled={
                  !(
                    hasAccess.isCustomer ||
                    (hasAccess.canImpersonate && relation)
                  ) ||
                  hasAccess.isAccountManager ||
                  isFrozen
                }
                icon={<FontAwesomeIcon icon="trash-alt" />}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CustomTable
        size="small"
        style={{ width: 400 }}
        dataSource={bankAccounts}
        loading={isLoading}
        pagination={{
          pageSize: 30,
          hideOnSinglePage: true,
        }}
        columns={tableColumns}
      />

      <CustomButton
        type="primary"
        style={{ marginTop: '1rem' }}
        onClick={() => setModalState({ visible: true })}
        toolTipKey="settingsMyNotaPayment.action.addAccount"
        disabled={
          !(hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) ||
          hasAccess.isAccountManager ||
          isFrozen
        }
      >
        {t('settings.tabs.payment.settings.actions.add')}
      </CustomButton>

      <AddIbanModal
        {...modalState}
        onFinish={onFinish}
        onClose={onModalClose}
      />
    </>
  );
};
