import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

// @ts-ignore
const MAIL_DOMAIN_SUFFIX = `@${window.MAIL_DOMAIN}`;

interface MailboxInputProps {
  value?: string;
  onChange?: (value?: string) => void;
}

export const MailboxInput: React.FunctionComponent<MailboxInputProps> = ({
  value,
  onChange,
}) => {
  const [mailbox, setMailbox] = useState(value?.split('@')[0]);

  useEffect(() => {
    let value = mailbox;
    if (mailbox) {
      value += MAIL_DOMAIN_SUFFIX;
    }

    onChange?.(value);
  }, [mailbox]);

  return (
    <Input
      value={mailbox}
      onChange={(e) => setMailbox(e.target.value)}
      addonAfter={MAIL_DOMAIN_SUFFIX}
    />
  );
};
