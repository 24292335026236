import {
  PaymentSetting,
  PaymentSettingModifyRequest,
  PaymentSettingV1,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import {
  DefinedUseQueryResult,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['payment', 'paymentsettings'];
const BASE_URL_V1 = `${apiBasePaths.payments}/v1/paymentsettings`;
const BASE_URL = `${apiBasePaths.payments}/v2/paymentsettings`;

export const useGetPaymentSettingsV1 = (): DefinedUseQueryResult<
  PaymentSettingV1[],
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'v1'],
    queryFn: () =>
      api.get<PaymentSettingV1[]>(`${BASE_URL_V1}`).then((r) => r.data),
    initialData: [],
  });

export const useGetPaymentSettings = (): DefinedUseQueryResult<
  PaymentSetting[],
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'v2'],
    queryFn: () => api.get<PaymentSetting[]>(`${BASE_URL}`).then((r) => r.data),
    initialData: [],
  });

export const useUpdatePaymentSetting = (
  options?: Omit<
    UseMutationOptions<
      PaymentSetting,
      unknown,
      { id: number; request: PaymentSettingModifyRequest },
      unknown
    >,
    'mutationFn'
  >,
): UseMutationResult<
  PaymentSetting,
  unknown,
  { id: number; request: PaymentSettingModifyRequest },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, request }) =>
      api.patch(`${BASE_URL}/${id}`, request).then((r) => r.data),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
      options?.onSuccess?.(...props);
    },
  });
};
