import copyright from 'theme/logo-alfa-copyright.png';
import styles from './copyright.module.scss';
import moment from 'moment';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export const Copyright = () => {
  const { xl } = useBreakpoint();
  return (
    <div className={styles.copyright}>
      {xl && (
        <>
          <span className={styles.text}>© Copyright {moment().year()}</span>
          <img className={styles.img} src={copyright} alt="copyright logo" />
        </>
      )}
    </div>
  );
};
