import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  GenericModal,
} from '@/lib/components';
import {
  useCreateInvoiceStatusReason,
  useDeleteInvoiceStatusReason,
  useGetInvoiceStatusReasons,
  useUpdateInvoiceStatusReason,
} from '@/lib/queries';
import {
  InvoiceProcessStatus,
  InvoiceStatusReason,
  InvoiceStatusReasonModifyRequest,
} from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Popconfirm, Row, Tag } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddEditInvoiceRejectReasonForm } from '../_components/AddEditInvoiceRejectReasonForm';

export const InvoiceRejectReasonSettings = () => {
  const { t } = useTranslation('management', {
    keyPrefix: 'settings.applicationSettings.invoiceRejectReasons',
  });
  const { data, isLoading } = useGetInvoiceStatusReasons();
  const { mutateAsync: createItem } = useCreateInvoiceStatusReason();
  const { mutateAsync: updateItem } = useUpdateInvoiceStatusReason();
  const { mutateAsync: deleteItem } = useDeleteInvoiceStatusReason();

  const [isModalVisible, setisModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<InvoiceStatusReason>();

  const columns: CustomColumnType<InvoiceStatusReason>[] = [
    {
      title: t('fields.id'),
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: t('fields.statusReason'),
      key: 'statusReason',
      dataIndex: 'statusReason',
    },
    {
      title: t('fields.statusDescription'),
      key: 'statusDescription',
      dataIndex: 'statusDescription',
      render: (value?: string) => {
        if (value) {
          return value;
        }
        return <Tag color="blue">{t('fields.freeDescription')}</Tag>;
      },
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'id',
      render: (id: number, record: InvoiceStatusReason) => {
        return (
          <>
            <CustomButton
              type="link"
              onClick={() => openEditModal(record)}
              icon={<FontAwesomeIcon icon="pencil-alt" />}
            />
            <Popconfirm
              title={t('actions.delete.single', {
                reason: record.statusReason,
              })}
              onConfirm={() => onDelete(id)}
            >
              <CustomButton
                type="link"
                toolTipKey="invoiceRejectReasons.actions.delete"
                danger
                shape="circle"
                size="small"
                icon={<FontAwesomeIcon icon="trash-alt" />}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const openEditModal = (record: InvoiceStatusReason) => {
    setSelectedItem(record);
    setisModalVisible(true);
  };

  const onModalClose = () => {
    setSelectedItem(undefined);
    setisModalVisible(false);
  };

  const onModalFinish = async (request: InvoiceStatusReasonModifyRequest) => {
    // At this moment we only want to support Rejected
    request = { ...request, status: InvoiceProcessStatus.Rejected };
    if (selectedItem) {
      await updateItem({ id: selectedItem.id, request });
      showNotification('success', t('messages.updateSuccess'));
    } else {
      await createItem(request);
      showNotification('success', t('messages.createSuccess'));
    }

    onModalClose();
  };

  const onDelete = async (id: number) => {
    await deleteItem(id);
    showNotification('success', t('messages.deleteSuccess'));
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setisModalVisible(true)}
          >
            {t('actions.add')}
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <CustomTable
            rowKey="id"
            columns={columns}
            dataSource={data}
          ></CustomTable>
        </Col>
      </Row>
      <GenericModal
        isVisible={isModalVisible}
        hideModal={() => setisModalVisible(false)}
        onCancelHandler={onModalClose}
        footer={false}
        destroyOnClose
        title={selectedItem ? t('actions.edit') : t('actions.add')}
      >
        <AddEditInvoiceRejectReasonForm
          isLoading={isLoading}
          onModalClose={onModalClose}
          onFinish={onModalFinish}
          value={selectedItem}
        />
      </GenericModal>
    </>
  );
};
