import {
  getApplicationSettings,
  postApplicationSettings,
} from '@/lib/adapters/users-adapter';
import { applicationSettingsAtom } from '@/lib/atoms/atoms';
import { CustomButton } from '@/lib/components';
import { ApplicationSettingsProps } from '@/lib/types';
import { Form, InputNumber } from 'antd';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const INITIAL_VALUES = {
  maxFileSize: 4,
  maxFileCount: 20,
  pageSize: 10,
};

export const GeneralSettings = () => {
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const setApplicationSettings = useUpdateAtom(applicationSettingsAtom);
  const [form] = Form.useForm<ApplicationSettingsProps>();

  const onFinish = (values: ApplicationSettingsProps) => {
    setLoading(true);
    postApplicationSettings(values)
      .then((r) => {
        form.setFieldsValue(r);
        setApplicationSettings(r);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const getSettings = () => {
      setLoading(true);
      getApplicationSettings()
        .then((r) => {
          form.setFieldsValue(r);
          setApplicationSettings(r);
        })
        .finally(() => setLoading(false));
    };
    getSettings();
  }, [form]);

  return (
    <>
      <Form
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 3 }}
        labelAlign="left"
        initialValues={INITIAL_VALUES}
      >
        <Form.Item
          name="maxFileSize"
          label={t('settings.tabs.upload.fileSize.title')}
          help={t('settings.tabs.upload.fileSize.description')}
        >
          <InputNumber min={1} max={4} />
        </Form.Item>
        <Form.Item
          name="maxFileCount"
          label={t('settings.tabs.upload.fileCount.title')}
          help={t('settings.tabs.upload.fileCount.description')}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item
          name="pageSize"
          label={t('settings.tabs.pagination.invoiceCount.title')}
          help={t('settings.tabs.pagination.invoiceCount.description')}
        >
          <InputNumber min={10} />
        </Form.Item>
        <Form.Item label=" " colon={false} style={{ marginTop: 16 }}>
          <CustomButton loading={isLoading} htmlType="submit" type="primary">
            {t('general.actions.save')}
          </CustomButton>
        </Form.Item>
      </Form>
    </>
  );
};
