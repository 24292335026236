import {
  Accent,
  CustomButton,
  CustomColumnType,
  CustomTable,
} from '@/lib/components';
import { useGetAdministrationsByIdentifier } from '@/lib/queries';
import { AdministrationIdentification } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { Result, Spin } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LyantheAdministrationDetails } from './lyantheAdministrationDetails';
import { CustomStepProps } from './types';

const baseColumnSettings: CustomColumnType<AdministrationIdentification> = {
  enableDefaultSorter: false,
  enableDefaultSearch: false,
  filterMultiple: false,
};

export const Lyanthe: FC<CustomStepProps> = ({
  identifier,
  setCurrentPage,
  setLyantheRelation,
}) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'relations.addRelation.steps.lyanthe',
  });
  const [selectedRelation, setSelectedRelation] =
    useState<AdministrationIdentification>();

  const { data, isLoading, isError } =
    useGetAdministrationsByIdentifier(identifier);

  const onPrev = () => {
    setCurrentPage?.((prev) => prev - 1);
  };

  const onNext = () => {
    if (!selectedRelation) {
      showNotification('warning', t('noSelection'));
      return;
    }

    setLyantheRelation?.(selectedRelation);
    setCurrentPage?.((prev) => prev + 1);
  };

  const onSelect = (row: AdministrationIdentification) => {
    setSelectedRelation(row);
  };

  const tableColumns: CustomColumnType<AdministrationIdentification>[] = [
    {
      title: t('labels.name'),
      dataIndex: 'name',
      ...baseColumnSettings,
    },
    {
      title: t('labels.cocNumber'),
      dataIndex: 'cocNumber',
      ...baseColumnSettings,
    },
    {
      title: t('labels.vatNumber'),
      dataIndex: 'vatNumber',
      ...baseColumnSettings,
    },
    {
      title: t('labels.customerNumber'),
      dataIndex: 'thirdPartyIdentifier',
      ...baseColumnSettings,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      {data?.length ? (
        <>
          <Accent type="h2" color="secondary">
            {t('results.success.title')}
          </Accent>
          <CustomTable
            expandable={{
              expandedRowRender: (record: AdministrationIdentification) => {
                return (
                  <LyantheAdministrationDetails
                    administrationId={record.administrationId}
                  />
                );
              },
            }}
            clearFilter={false}
            rowKey="id"
            columns={tableColumns}
            dataSource={data}
            rowSelection={{
              type: 'radio',
              onChange: (
                _selectedRowKeys: React.Key[],
                selectedRows: AdministrationIdentification[],
              ) => {
                onSelect(selectedRows[0]);
              },
            }}
          />
        </>
      ) : (
        <Result
          status="error"
          title={t('results.notFound.title')}
          subTitle={t('results.notFound.subtitle', { identifier })}
        ></Result>
      )}
      <div style={{ marginTop: '2rem' }}>
        <CustomButton type="link" onClick={onPrev}>
          {t('actions.back')}
        </CustomButton>
        <CustomButton
          type="primary"
          disabled={!!isError || !data || !selectedRelation}
          onClick={onNext}
        >
          {t('actions.continue')}
        </CustomButton>
      </div>
    </Spin>
  );
};
