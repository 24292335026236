import {
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import { Invoice } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';

type CreditRowProps = {
  invoice: Invoice;
  remove: (invoice: Invoice) => void;
};

export const CreditRow = ({ invoice, remove }: CreditRowProps) => {
  return (
    <Row gutter={10}>
      <Col span={5}>{dateTimeFormatter.toDateOnly(invoice.invoiceDate)}</Col>
      <Col span={5}>{invoice.invoiceNumber}</Col>

      <Col span={10}>
        <DocumentViewModal id={invoice.sourceIdentifier} idType="processfile" />
        <CustomButton
          type="text"
          shape="circle"
          toolTipKey="payment.action.deleteInvoice"
          onClick={() => remove(invoice)}
          icon={<FontAwesomeIcon color="red" icon="trash-alt" />}
        />
      </Col>

      <Col span={4}>
        <span style={{ color: 'red' }}>
          <CurrencyDisplay amount={invoice.amount} />
        </span>
      </Col>
    </Row>
  );
};
