import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Help } from './_tabs/help';
import { Guide } from './_tabs/guide';
import { SupportTickets } from './_tabs/supportTickets';
import { ReleaseNotes } from './_tabs/releaseNotes';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const Support = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.settings.support.tabs',
  });

  const location = useLocation();
  const navigate = useNavigate();

  const getDefaultActiveTab = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('tab') || 'guide';
  };

  const [activeTab, setActiveTab] = useState(getDefaultActiveTab());

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`/settings/support?tab=${key}`);
  };

  const items = [
    {
      label: t('guide'),
      key: 'guide',
      children: <Guide key='guide' />,
    },
    {
      label: t('help'),
      key: 'help',
      children: <Help key='help' />,
    },
    {
      label: t('supportTickets'),
      key: 'supportTickets',
      children: <SupportTickets key='supportTickets' />,
    },
    {
      label: t('releaseNotes'),
      key: 'releaseNotes',
      children: <ReleaseNotes key='releaseNotes' />,
    }
  ];

  return <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />;
};
