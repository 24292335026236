import {
  OtherDocument,
  OtherDocumentsFilter,
  PagedResponse,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';
import { showNotification } from '@/lib/utils/showNotification';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const BASE_URL = `${apiBasePaths.invoices}/v1/documents`;
const QUERY_KEY = ['documents'];

export const useGetDocuments = (
  filter?: OtherDocumentsFilter,
): UseQueryResult<PagedResponse<OtherDocument[]> | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, filter],
    queryFn: () =>
      api
        .get<PagedResponse<OtherDocument[]>>(`${BASE_URL}`, {
          params: createFilterParams(filter),
        })
        .then((r) => r.data),
  });

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`${BASE_URL}/${id}`).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
    onError: (error: AxiosError) => {
      if (error.status == 403) {
        showNotification("error", t('general.errors.forbidden'));
      }
    }
  });
};

export const useBatchDeleteDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids: number[]) =>
      api
        .post(
          `${BASE_URL}/batch/delete`,
          ids.map((x) => ({ id: x })),
        )
        .then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};