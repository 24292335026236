import { HeaderItem } from '@/lib/components';
import { Col, Row } from 'antd';

export const PaymentHeader = () => {
  return (
    <Row gutter={10}>
      <Col span={12} className='font-scale'>
        <Row gutter={10}>
          <Col span={4}>
            <HeaderItem textKey="payment.header.invoiceDate" />
          </Col>
          <Col span={5}>
            <HeaderItem textKey="payment.header.invoice" />
          </Col>
          <Col span={5}>
            <HeaderItem textKey="payment.header.supplier" />
          </Col>
          <Col span={4}>
            <HeaderItem textKey="payment.header.invoiceAmount" />
          </Col>
          <Col span={3}>
            <HeaderItem textKey="payment.header.discount" />
          </Col>
          <Col span={3}>
            <HeaderItem textKey="payment.header.toPay" />
          </Col>
        </Row>
      </Col>
      <Col span={9} className='font-scale'>
        <Row gutter={10}>
          <Col span={10}>
            <HeaderItem textKey="payment.header.ibanSupplier" />
          </Col>
          <Col span={3}>
            <HeaderItem textKey="payment.header.term" />
          </Col>
          <Col span={5}>
            <HeaderItem textKey="payment.header.executionDate" />
          </Col>
          <Col span={4} className="text-right">
            <HeaderItem textKey="payment.header.openPayment" />
          </Col>
          <Col span={2}></Col>
        </Row>
      </Col>
      <Col span={2}></Col>
    </Row>
  );
};
