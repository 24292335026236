import {
  getDocument,
  getIncomingFileSourceFile,
  getInvoiceDetails,
  getProcessFileSourceFile,
} from '@/lib/adapters/invoice-adapter';
import { CustomButton, ImageView, PdfView } from '@/lib/components';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { useState } from 'react';
import { DocumentViewErrorComponent } from './documentViewErrorComponent';
import styles from './documentviewer.module.scss';

type DocumentViewModalProps = {
  id: string | (() => string | Promise<string>);
  idType: 'invoice' | 'processfile' | 'incomingfile' | 'document' | 'url';
  data?: any;
};

type fileUrlType = {
  url: string;
  documentType: string;
};

const documentType = (documentName: string) =>
  (documentName.split('.').pop() || 'pdf').toLowerCase();

const fileUrlFunctions: {
  [key: string]: (id: string) => Promise<fileUrlType>;
} = {
  invoice: async (id) => {
    const result = await getInvoiceDetails(id);
    return { url: result.fileUrl, documentType: 'pdf' };
  },
  processfile: async (id) => {
    const result = await getProcessFileSourceFile(id);
    return {
      url: result.blobStorageUri,
      documentType: documentType(result.fileName),
    };
  },
  incomingfile: async (id) => {
    const result = await getIncomingFileSourceFile(id);
    return {
      url: result.blobStorageUri,
      documentType: documentType(result.fileName),
    };
  },
  document: async (id) => {
    const result = await getDocument(id);
    const docName = result.blobStorageUri?.split('?')[0] || result.documentName;

    return {
      url: result.blobStorageUri,
      documentType: documentType(docName),
    };
  },
  url: async (id) => {
    const docName = id.split('?')[0];

    return {
      url: id,
      documentType: documentType(docName),
    };
  },
  default: (id) => {
    throw new Error('Invalid file url');
  },
};

export const DocumentViewModal = ({
  id,
  idType,
  data,
}: DocumentViewModalProps) => {
  const [isLoading, setLoading] = useState(false);

  const showModal = async (e) => {
    e.stopPropagation();
    setLoading(true);

    try {
      const fileId = typeof id === 'function' ? await id() : id;

      const fileUrlFn = fileUrlFunctions[idType] || fileUrlFunctions['default'];
      const fileUrl = await fileUrlFn(fileId);

      const errorComponent = (
        <DocumentViewErrorComponent
          idType={idType}
          data={data}
          url={fileUrl?.url}
        />
      );

      const MODALCONTENT = {
        pdf: <PdfView url={fileUrl?.url} onErrorComponent={errorComponent} />,
        png: <ImageView url={fileUrl?.url} onErrorComponent={errorComponent} />,
        jpg: <ImageView url={fileUrl?.url} onErrorComponent={errorComponent} />,
        jpeg: (
          <ImageView url={fileUrl?.url} onErrorComponent={errorComponent} />
        ),
        default: (
          <ImageView url={fileUrl?.url} onErrorComponent={errorComponent} />
        ),
      };

      Modal.info({
        width: '100%',
        icon: false,
        centered: true,
        okText: 'Sluiten',
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        zIndex: 9999,
        className: styles.modal,
        content: MODALCONTENT[fileUrl?.documentType] || MODALCONTENT['default'],
      });
    } catch {
      showNotification('error', 'Er is geen factuurbestand gevonden');
      Modal.info({
        width: '100%',
        icon: false,
        centered: true,
        okText: 'Sluiten',
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        zIndex: 9999,
        className: styles.modal,
        content: <DocumentViewErrorComponent idType={idType} data={data} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomButton
      shape="circle"
      loading={isLoading}
      icon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
      toolTipKey="document.action.show"
      onClick={showModal}
    />
  );
};
