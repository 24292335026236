import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FC, ReactNode } from "react";

export interface CombinedIconProps {
  children: ReactNode[] | ReactNode;
  size?: SizeProp;
}

export const CombinedIcon: FC<CombinedIconProps> = ({ children, size = "1x" }) => {
  return <span className={"fa-layers fa-fw fa-" + size}>
    {children}
  </span>
}
