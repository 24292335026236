import { AgrinotaImportRequest, AgrinotaRelation } from '@/lib/types';
import { Relation } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { Form, Modal, Steps } from 'antd';
import { FC, useState } from 'react';
import { createImportRequest } from '../../../../lib/adapters/agrinota-service-adapter';
import { Agrinota } from './Steps/Agrinota';
import { Identifier } from './Steps/Identifier';
import { Settings } from './Steps/Settings';
import { Summary } from './Steps/Summary';

const { Step } = Steps;

interface IImportModal {
  relation?: Relation;
  visible: boolean;
  onRelationFormClose?: () => void;
}

export interface IImportSettings {
  fromDate: Date;
  includeInvoices: boolean;
  inlcudePaymentSettings: boolean;
  includeDocuments: boolean;
}

export const ImportModal: FC<IImportModal> = ({
  relation,
  visible,
  onRelationFormClose,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [form] = Form.useForm();
  const [agrinotaRelation, setAgrinotaRelation] = useState<AgrinotaRelation>();
  const [identifier, setIdentifier] = useState<number>();
  const [importSettings, setImportSettings] = useState<IImportSettings>({
    fromDate: new Date(),
    includeDocuments: true,
    includeInvoices: true,
    inlcudePaymentSettings: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const resetState = () => {
    setCurrentPage(0);
    setAgrinotaRelation(undefined);
    setIdentifier(undefined);
    onRelationFormClose?.();
  };

  const importAgrinota = async (request: AgrinotaImportRequest) => {
    try {
      setIsLoading(true);
      await createImportRequest(request);
      showNotification('success', `Import gestart`);
      resetState();
    } catch (e) {
      showNotification('error', 'Fout tijdens aanvragen van import.');
    } finally {
      setIsLoading(false);
    }
  };

  const StepPropsObj = {
    form,
    setCurrentPage,
  };

  const FormSteps = [
    {
      title: 'KvK',
      content: <Identifier setIdentifier={setIdentifier} {...StepPropsObj} />,
    },
    {
      title: 'Agrinota Relatie',
      content: (
        <Agrinota
          agrinotaRelation={agrinotaRelation}
          setAgrinotaRelation={setAgrinotaRelation}
          identifier={identifier}
          {...StepPropsObj}
        />
      ),
    },
    {
      title: 'Instellingen',
      content: (
        <Settings setImportSettings={setImportSettings} {...StepPropsObj} />
      ),
    },
    {
      title: 'Samenvatting',
      content: (
        <Summary
          {...StepPropsObj}
          agrinotaRelation={agrinotaRelation}
          submitImport={importAgrinota}
          selectedRelation={relation}
          importSettings={importSettings}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      footer={false}
      title="Agrinota import"
      onCancel={resetState}
      width={700}
    >
      <Steps
        current={currentPage}
        labelPlacement="vertical"
        style={{ marginBottom: '2rem' }}
      >
        {FormSteps.map((step) => (
          <Step title={step.title} key={step.title} />
        ))}
      </Steps>
      <div className="text-center">{FormSteps[currentPage].content}</div>
    </Modal>
  );
};
