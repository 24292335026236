import { CurrencyDisplay } from '@/lib/components';
import { currencyFormatter, dateTimeFormatter } from '@/lib/utils/formatters';
import i18n from '@/lib/utils/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DatePicker, Input, Space } from 'antd';
import { ColumnTitle } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';

export type DefaultRender =
  | 'dateonly'
  | 'datetime'
  | 'datetimeutc'
  | 'currency'
  | 'string';

export type DefaultSearch = 'dateonly';

const handleSearch = (confirm: () => void) => {
  confirm();
};

const handleReset = (clearFilters: () => void, confirm: () => void) => {
  clearFilters();
  confirm();
};

export const getDefaultSearch = (
  dataIndex: any | undefined,
  title: ColumnTitle<any> | undefined,
  defaultRender?: DefaultRender,
  onFilter?: (value: string | number | boolean, record: any) => boolean,
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`${title ?? ''}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onKeyDown={(e) => e.stopPropagation()}
        onPressEnter={() => handleSearch(confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<FontAwesomeIcon icon="search" />}
          size="small"
          style={{ width: 90 }}
        >
          <Trans i18nKey="button.search"></Trans>
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          <Trans i18nKey="button.reset"></Trans>
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FontAwesomeIcon
      icon="search"
      style={{ color: filtered ? '#1890ff' : undefined }}
    />
  ),
  onFilter: (value, record) => {
    if (onFilter) return onFilter(value, record);

    let compareValue = record[dataIndex as string];
    if (!compareValue) {
      return;
    }

    if (defaultRender) {
      if (defaultRender === 'currency') {
        compareValue = currencyFormatter(i18n.language, 'EUR').format(
          compareValue,
        );
      } else if (defaultRender === 'dateonly') {
        compareValue = dateTimeFormatter.toDateOnly(compareValue);
      }
    }

    return compareValue.toString().toLowerCase().includes(value.toLowerCase());
  },
});

export const getDatePickerSearch = (
  dataIndex: any | undefined,
  defaultRender?: DefaultRender,
  onFilter?: (value: string | number | boolean, record: any) => boolean,
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <Space direction="vertical" style={{ padding: '8px' }}>
      <DatePicker
        //@ts-ignore
        value={selectedKeys[0] ? dayjs(selectedKeys[0]) : undefined}
        style={{ display: 'block' }}
        format={dayjs.Ls.nl.formats.L}
        picker="date"
        onChange={(e) => {
          //@ts-ignore
          setSelectedKeys([dayjs(e).format('YYYY-MM-DD')]);
        }}
        allowClear={false}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm({ closeDropdown: true })}
          icon={<FontAwesomeIcon icon="search" />}
          size="small"
          style={{ width: 90 }}
        >
          <Trans i18nKey="button.search"></Trans>
        </Button>
        <Button
          onClick={() => {
            clearFilters?.();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90 }}
        >
          <Trans i18nKey="button.reset"></Trans>
        </Button>
      </Space>
    </Space>
  ),
  filterIcon: (filtered) => (
    <FontAwesomeIcon
      icon="search"
      style={{ color: filtered ? '#1890ff' : undefined }}
    />
  ),
  onFilter: (value, record) => {
    if (onFilter) return onFilter(value, record);

    let compareValue = record[dataIndex as string];
    if (!compareValue) {
      return;
    }

    if (defaultRender) {
      if (defaultRender === 'currency') {
        compareValue = currencyFormatter(i18n.language, 'EUR').format(
          compareValue,
        );
      } else if (defaultRender === 'dateonly') {
        compareValue = dayjs(compareValue).format('YYYY-MM-DD');
      }
    }

    return compareValue.toString().toLowerCase().includes(value.toLowerCase());
  },
});

export const getDefaultSorter = (a, b, dataIndex, sortType) => {
  const sorterFunctions = {
    string: (a, b) => (a ?? '').localeCompare(b ?? ''),
    number: (a, b) => ((a ?? 0) > (b ?? 0) ? 1 : 0),
    default: (a, b) =>
      JSON.stringify(a ?? '').localeCompare(JSON.stringify(b ?? '')),
  };

  const sorterFn = sorterFunctions[sortType] || sorterFunctions['default'];
  return sorterFn(a[dataIndex], b[dataIndex]);
};

export const getDefaultRender = (type) => {
  const renderFunctions: { [key in DefaultRender]: (value: any) => any } = {
    string: (value) => value,
    dateonly: (value) => dateTimeFormatter.toDateOnly(value),
    datetime: (value) => dateTimeFormatter.toDateTime(value),
    datetimeutc: (value) => dateTimeFormatter.toDateTimeFromUtc(value),
    currency: (value) => <CurrencyDisplay amount={value} />,
  };

  return renderFunctions[type] || renderFunctions['string'];
};
