import { infoButtonsAtom } from '@/lib/atoms/atoms';
import { Tooltip } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { FC, PropsWithChildren } from 'react';

interface ICmsTooltip extends PropsWithChildren {
  toolTipKey?: string;
}

export const CmsTooltip: FC<ICmsTooltip> = ({ children, toolTipKey }) => {
  //@ts-ignore
  const { props } = children;
  const infoButtons = useAtomValue(infoButtonsAtom);
  const toolTipContent = infoButtons.find(
    (button) => button.key === toolTipKey,
  );

  //not checking for this causes tooltip to stay stuck on screen
  return props?.disabled ? (
    <>{children}</>
  ) : (
    <Tooltip
      placement="bottomRight"
      title={toolTipContent?.value}
      mouseLeaveDelay={0}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
