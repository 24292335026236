import { Relation, RelationIdentifier } from '@/lib/types/user';
import { Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailItem } from '../core/detail-item/detailItem';

interface RelationDetailsProps {
  style?: React.CSSProperties;
  relation?: Relation;
}
export const RelationDetails: React.FunctionComponent<RelationDetailsProps> = ({
  relation,
  style,
}) => {
  const { t } = useTranslation();

  const labels = {
    kvk: t('renders.invoice.relation.coc'),
    vat: t('renders.invoice.relation.vat'),
    customernumber: t('renders.invoice.relation.customerNumber'),
    0: t('renders.invoice.relation.coc'),
    1: t('renders.invoice.relation.vat'),
    2: t('renders.invoice.relation.customerNumber'),
  };

  const getLabel = (identifier: RelationIdentifier) => {
    // Try based on type
    if (identifier.identifierType) {
      const type = identifier.identifierType.split(':');
      if (type.length > 1) {
        return labels[type[1].toLowerCase()];
      }
    }

    if (typeof identifier.category !== 'undefined') {
      return labels[identifier.category];
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%', ...style }}>
      <DetailItem name={t('relation.name')} value={relation?.name} />
      <DetailItem
        name={t('relation.address')}
        value={`${relation?.street || ''} ${relation?.houseNumber || ''}`}
      />
      <DetailItem name={t('relation.zipcode')} value={relation?.zipcode} />
      <DetailItem name={t('relation.city')} value={relation?.city} />
      <DetailItem name={t('relation.country')} value={relation?.country} />

      {relation?.identifiers.map((x) => (
        <DetailItem name={getLabel(x)} value={x?.identifier} />
      ))}
    </Space>
  );
};
