import { useRelationContext } from '@/lib/context';
import React from 'react';
import { ProtectedRoute } from './protectedRoute';

export const ImpersonatedRoute: React.FunctionComponent = () => {
  const { relation } = useRelationContext();

  return (
    <ProtectedRoute isValid={!!relation} redirectPath="/relation-selection" />
  );
};
