import { ControlledInput, CustomButton } from '@/lib/components';
import { BankAccount } from '@/lib/types';
import { Form, Input, Modal, Space, Switch } from 'antd';
import { isValidIBAN } from 'ibantools';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AddIbanModalProps {
  bankAccount?: BankAccount;
  visible: boolean;
  loading?: boolean;

  onFinish: (bankAccount: BankAccount) => void;
  onClose: () => void;
}

export const AddIbanModal: FC<AddIbanModalProps> = ({
  visible,
  bankAccount,
  onFinish,
  onClose,
  loading,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  if (bankAccount) form.setFieldsValue(bankAccount);

  const onCloseClick = () => onClose();

  return (
    <Modal
      open={visible}
      footer={false}
      destroyOnClose
      onCancel={onCloseClick}
      title={
        bankAccount
          ? t('settings.tabs.payment.settings.labels.changeAccount')
          : t('settings.tabs.payment.settings.labels.addAccount')
      }
    >
      <Form
        preserve={false}
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        labelAlign="left"
        colon={false}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="iban"
          label={t('settings.tabs.payment.settings.labels.iban')}
          rules={[
            {
              validator: async (rule, value) => {
                if (!isValidIBAN(value))
                  throw new Error('Geen geldig IBAN nummer');
              },
            },
          ]}
        >
          <ControlledInput normalize={(value) => (value || '').toUpperCase()} />
        </Form.Item>
        <Space size={5} style={{ marginTop: 16 }}>
          <CustomButton htmlType="submit" type="primary" loading={loading}>
            {t('general.actions.save')}
          </CustomButton>
          <CustomButton type="link" onClick={onCloseClick}>
            {t('general.actions.cancel')}
          </CustomButton>
        </Space>
      </Form>
    </Modal>
  );
};
