import { Accent } from '@/lib/components';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoSVG from 'theme/logo.svg';

interface LogoProps {
  text: string;
  iconOnly?: boolean;
}

export const Logo: FC<LogoProps> = ({ text, iconOnly }) => {
  const navigate = useNavigate();
  const goToHome = () => navigate('/');
  return (
    <Row
      gutter={8}
      onClick={goToHome}
      align="middle"
      style={{ lineHeight: 'normal' }}
    >
      <Col>
        <img src={LogoSVG}></img>
      </Col>
      {!iconOnly && (
        <Col>
          <Accent style={{ fontSize: '1.5rem' }}>{text}</Accent>
        </Col>
      )}
    </Row>
  );
};
