import { CurrencyDisplay, CustomButton, HeaderItem } from '@/lib/components';
import { PaymentEntry } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'antd';
import { InvoicePaymentRow } from './invoicePaymentRow';

type InvoicePaymentListProps = {
  paymentEntries: PaymentEntry[];
  downloadSepa: (paymentId: number) => void;
};

export const InvoicePaymentList = ({
  paymentEntries,
  downloadSepa,
}: InvoicePaymentListProps) => {
  if (!paymentEntries || !paymentEntries.length) {
    return null;
  }

  return (
    <>
      <InvoicePaymentRow
        className="font-bold"
        executionDate={<HeaderItem textKey="payment.header.executionDate" />}
        iban={<HeaderItem textKey="payment.iban" />}
        amount={<HeaderItem textKey="payment.amount" />}
      />
      <List
        split={false}
        bordered={false}
        dataSource={paymentEntries}
        renderItem={(item) => (
          <InvoicePaymentRow
            key={item.id}
            executionDate={dateTimeFormatter.toDateOnly(item.executionDate)}
            iban={item.receiverIban}
            amount={<CurrencyDisplay amount={item.amount} />}
            actions={
              <CustomButton
                size="small"
                type="text"
                toolTipKey="payment.action.downloadSepa"
                onClick={() => downloadSepa(item.paymentId)}
              >
                <FontAwesomeIcon
                  color="var(--secondary)"
                  icon="file-download"
                />
              </CustomButton>
            }
          />
        )}
      />
    </>
  );
};
