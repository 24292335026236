import {
  getInvoiceWithouthRelation,
  postReprocessInvoiceWithouthRelation,
  rejectProcessFile,
} from '@/lib/adapters/invoice-adapter';
import {
  getRelations,
  postRelation,
  postRelationIdentifier,
} from '@/lib/adapters/users-adapter';
import {
  Accent,
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
  InvoiceRejectModal,
} from '@/lib/components';
import {
  InvoiceWithouthRelation,
  RejectFile,
  RejectModalState,
} from '@/lib/types';
import { IdentifierCategory } from '@/lib/types/enums';
import { Relation, RelationIdentifier } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Descriptions, Form, Input, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const DEFAULT_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const AttachUnknownRelation = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [modalState, setModalState] = useState<RejectModalState>('hidden');

  const [invoice, setInvoiceRelation] = useState<InvoiceWithouthRelation>();
  const [possibleRelations, setPosibbleRelations] = useState<Relation[]>();

  const [form] = Form.useForm();
  const { id: invoiceId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const onFormFinish = (values) => {
    const { identifier, identifierType, ...rest } = values;
    const mappedRelationIdentifiers = {
      ...rest,
      type: 'external',
      identifiers: [
        {
          identifier,
          identifierType,
        },
      ],
    };
    setLoading(true);
    postRelation(mappedRelationIdentifiers)
      .then(() => handleSuccess())
      .catch((e) => {
        showNotification(
          'error',
          'Fout bij opslaan, probeer opnieuw te verwerken.',
        );
      })
      .finally(() => setLoading(false));
  };

  const handleAddIdentifier = (id: string) => {
    if (!invoice) return;
    setLoading(true);
    postRelationIdentifier(id, {
      identifier: invoice.relation.identifier,
      identifierType: invoice.relation.identifierType,
    })
      .then(() => handleSuccess())
      .finally(() => setLoading(false));
  };

  const handleSuccess = () => {
    setLoading(true);
    if (invoice)
      postReprocessInvoiceWithouthRelation(invoice?.id).then(() =>
        navigate('/settings/invoiceswithouthrelation'),
      );
  };

  const rejectFile = async (
    file: RejectFile,
    reason: string,
    description?: string,
  ) => {
    try {
      await rejectProcessFile(file.id as string, reason, description);
      showNotification('success', 'Afgewezen.');
      closeRejectModal();
    } catch {
      showNotification('error', 'Fout tijdens afwijzen.');
    }
  };

  const possibleTableColumns: CustomColumnType<Relation>[] = [
    {
      title: t('relation.name'),
      dataIndex: 'name',
    },
    {
      title: t('relation.address'),
      render: (text, relation) =>
        `${relation.street} ${relation.houseNumber}, ${relation.zipcode}`,
    },
    {
      title: t('relation.city'),
      dataIndex: 'city',
      width: 200,
    },
    {
      title: t('relation.coc'),
      key: 'coc',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.COC,
        )?.identifier,
    },
    {
      title: t('relation.vat'),
      key: 'vat',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.VAT,
        )?.identifier,
    },
    {
      render: (text, relation) => (
        <>
          <CustomButton
            size="small"
            color="secondary"
            toolTipKey="linkrelations.action.linkSelected"
            onClick={() => handleAddIdentifier(relation.id)}
          >
            Koppelen
          </CustomButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchInvoiceData = async (id: string) => {
      setLoading(true);
      try {
        const invoiceRelationData = await getInvoiceWithouthRelation(id);
        setInvoiceRelation(invoiceRelationData);
        form.setFieldsValue(invoiceRelationData.relation);

        const possibleRelationsData = await getRelations({
          zipcode: invoiceRelationData.relation.zipcode,
        });

        setPosibbleRelations(possibleRelationsData?.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData(invoiceId!);
  }, [form, invoiceId]);

  const openRejectModal = () => {
    setModalState('single');
  };

  const closeRejectModal = () => {
    setModalState('hidden');
  };

  return (
    <>
      <InvoiceRejectModal
        modalState={modalState}
        hideModal={closeRejectModal}
        singleFile={
          invoice
            ? {
                id: invoice.id,
                name: invoice?.documentName,
              }
            : undefined
        }
        rejectSingle={rejectFile}
        multiFiles={[]}
      />
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: '1rem' }}>
            <CustomButton
              icon={<FontAwesomeIcon icon="long-arrow-alt-left" />}
              color="secondary"
              onClick={() => navigate('/settings/invoiceswithouthrelation')}
            >
              Ga terug
            </CustomButton>
            {invoice && (
              <CustomButton
                type="link"
                toolTipKey="linkrelations.action.reject"
                danger
                onClick={() => openRejectModal()}
                shape="circle"
                size="small"
                icon={<FontAwesomeIcon icon="ban" />}
              />
            )}
          </Col>
          <Col span={6}>
            <Accent type="h3" color="primary">
              Factuurgegevens
            </Accent>

            <Descriptions labelStyle={{ fontWeight: 'bold' }} colon={false}>
              <Descriptions.Item span={3} label={t('relation.name')}>
                {invoice?.relation.name}
              </Descriptions.Item>
              <Descriptions.Item span={3} label={t('relation.address')}>
                {invoice?.relation.street}, {invoice?.relation.city}{' '}
                {invoice?.relation.country}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('invoiceWithouthRelation.documentName')}
              >
                {invoice?.documentName}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('invoiceWithouthRelation.supplierIdentifier')}
              >
                {invoice?.supplierIdentifier} -{' '}
                {invoice?.supplierIdentifierType}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('invoiceWithouthRelation.customerIdentifier')}
              >
                {invoice?.customerIdentifier} -{' '}
                {invoice?.customerIdentifierType}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('invoiceWithouthRelation.showPdf')}
              >
                {invoice && (
                  <DocumentViewModal id={invoice.id} idType="processfile" />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={10}>
            <Accent type="h3" color="primary">
              Relaties met overeenkomstige postcode
            </Accent>
            <CustomTable
              locale={{ emptyText: 'Er zijn geen relaties gevonden' }}
              dataSource={possibleRelations}
              columns={possibleTableColumns}
            />
          </Col>
          <Col span={8}>
            <Accent type="h3" color="primary">
              Nieuwe relatie maken
            </Accent>

            <Accent type="small" style={{ marginBottom: '1rem' }}>
              De nieuwe relatie wordt gemaakt op basis van gevonden gegevens uit
              het bestand. Je kunt hieronder de gegevens aanpassen of aanvullen.
            </Accent>

            <Form form={form} {...DEFAULT_LAYOUT} onFinish={onFormFinish}>
              <Form.Item name="name" label={t('relation.name')}>
                <Input />
              </Form.Item>
              <Form.Item name="zipcode" label={t('relation.zipcode')}>
                <Input />
              </Form.Item>
              <Form.Item name="street" label={t('relation.street')}>
                <Input />
              </Form.Item>
              <Form.Item name="housenumber" label={t('relation.housenumber')}>
                <Input />
              </Form.Item>
              <Form.Item name="city" label={t('relation.city')}>
                <Input />
              </Form.Item>
              <Form.Item name="country" label={t('relation.country')}>
                <Input />
              </Form.Item>

              <Form.Item
                name="identifier"
                label={t('relation.identifier.identifier')}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="identifierType"
                label={t('relation.identifier.type')}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item label=" " colon={false}>
                <CustomButton
                  htmlType="submit"
                  type="primary"
                  style={{ marginTop: '1rem' }}
                  toolTipKey="linkrelations.action.createNew"
                >
                  {t('general.actions.save')}
                </CustomButton>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
