import { Accent, CustomButton, CustomColumnType } from '@/lib/components';
import {
  AgrinotaImportInvoice,
  AgrinotaImportLog,
  FilterBase,
  ImportEntryStatus,
  ImportStatus,
  ImportType,
  PagedResponse,
  UploadType,
} from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Radio, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getImportLogDetails,
  getImportLogDocuments,
  getImportLogInvoices,
  getImportLogPaymentSettings,
} from '../../../../lib/adapters/agrinota-service-adapter';
import { AgrinotaImportDetailsTable } from '../_components/AgrinotaImportDetailsTable';

type TableType = 'invoices' | 'paymentSettings' | 'documents';

export const AgrinotaImportDetails = () => {
  const { id } = useParams<{ id: string }>();

  const [tableType, setTableType] = useState<TableType>('invoices');

  const [importLogDetails, setImportLogDetails] = useState<AgrinotaImportLog>();
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const fetchData = (id) => {
    setLoading(true);
    getImportLogDetails(id)
      .then((r) => setImportLogDetails(r))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const tableColumns: {
    [key: string]: CustomColumnType<AgrinotaImportInvoice>[];
  } = {
    invoices: [
      {
        title: 'Id',
        dataIndex: 'fileIdentifier',
      },
      {
        title: 'FactuurId',
        dataIndex: 'factuurId',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (value: number) =>
          t(`agrinota.importEntryStatus.${ImportEntryStatus[value]}`),
        onFilter: (value: any, record) => {
          const columnValue = t(
            `agrinota.importEntryStatus.${ImportEntryStatus[value]}`,
          );
          return columnValue.includes(value);
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Type',
        dataIndex: 'uploadType',
        render: (value: number) =>
          t(`agrinota.uploadType.${UploadType[value]}`),
      },
      {
        title: 'Import methode',
        dataIndex: 'importType',
        render: (value: number) =>
          t(`agrinota.importType.${ImportType[value]}`),
      },
    ],
    paymentSettings: [
      {
        title: 'Id',
        dataIndex: 'id',
      },
      {
        title: 'AgrinotaId',
        dataIndex: 'agrinotaPaymentSettingId',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (value: number) =>
          t(`agrinota.importEntryStatus.${ImportEntryStatus[value]}`),
        onFilter: (value: any, record) => {
          const columnValue = t(
            `agrinota.importEntryStatus.${ImportEntryStatus[value]}`,
          );
          return columnValue.includes(value);
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
    ],
    documents: [
      {
        title: 'Id',
        dataIndex: 'id',
      },
      {
        title: 'AgrinotaId',
        dataIndex: 'agrinotaDocumentId',
      },
      {
        title: 'Bestandsnaam',
        dataIndex: 'filename',
      },
      {
        title: 'Documentnaam',
        dataIndex: 'documentname',
      },
      {
        title: 'Jaar',
        dataIndex: 'year',
      },
      {
        title: 'Upload datum',
        dataIndex: 'uploadDate',
        defaultRender: 'dateonly',
        defaultSearch: 'dateonly',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (value: number) =>
          t(`agrinota.importEntryStatus.${ImportEntryStatus[value]}`),
        onFilter: (value: any, record) => {
          const columnValue = t(
            `agrinota.importEntryStatus.${ImportEntryStatus[value]}`,
          );
          return columnValue.includes(value);
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
    ],
  };

  const tableFetchFunctions: {
    [key: string]: (tableSetting: FilterBase) => Promise<PagedResponse<any[]>>;
  } = {
    invoices: () => getImportLogInvoices(id!),
    paymentSettings: () => getImportLogPaymentSettings(id!),
    documents: () => getImportLogDocuments(id!),
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row>
        <CustomButton
          type="link"
          onClick={() => navigate(-1)}
          toolTipKey="agrinotaimport.action.backToOverview"
          icon={<FontAwesomeIcon icon="arrow-left" />}
        />
        <Accent type="h1" color="primary">
          Import Details
        </Accent>
      </Row>
      <Row justify="space-between">
        <Col span={16}>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Relatie Id
              </Accent>
            </Col>
            <Col>{importLogDetails?.relationId}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Agrinota Id
              </Accent>
            </Col>
            <Col>{importLogDetails?.agrinotaId}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Klantnummer
              </Accent>
            </Col>
            <Col>{importLogDetails?.customerNumber}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Vanaf datum
              </Accent>
            </Col>
            <Col>
              {importLogDetails &&
                dateTimeFormatter.toDateOnly(importLogDetails?.fromDate)}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Facturen
              </Accent>
            </Col>
            <Col>
              {importLogDetails?.invoiceImportStatus
                ? t(
                    `agrinota.importStatus.${
                      ImportStatus[importLogDetails?.invoiceImportStatus]
                    }`,
                  )
                : null}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Betaalinstellingen
              </Accent>
            </Col>
            <Col>
              {importLogDetails?.paymentImportStatus
                ? t(
                    `agrinota.importStatus.${
                      ImportStatus[importLogDetails?.paymentImportStatus]
                    }`,
                  )
                : null}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Accent color="primary" type="strong">
                Documenten
              </Accent>
            </Col>
            <Col>
              {importLogDetails?.documentImportStatus
                ? t(
                    `agrinota.importStatus.${
                      ImportStatus[importLogDetails?.documentImportStatus]
                    }`,
                  )
                : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={24}>
          Toon:
          <Radio.Group
            disabled={isLoading}
            style={{ marginLeft: 10 }}
            optionType="button"
            onChange={(e) => setTableType(e.target.value)}
            defaultValue={tableType}
            buttonStyle="solid"
          >
            <Radio.Button value="invoices">Facturen</Radio.Button>
            <Radio.Button value="paymentSettings">
              Betaalinstellingen
            </Radio.Button>
            <Radio.Button value="documents">Documenten</Radio.Button>
          </Radio.Group>
        </Col>
        <Col flex={1}>
          <AgrinotaImportDetailsTable
            tableColumns={tableColumns[tableType]}
            fetchFunction={tableFetchFunctions[tableType]}
          />
        </Col>
      </Row>
    </Space>
  );
};
