import { Layout } from 'antd';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './_components/header';
import styles from './styles.module.scss';

export const RootLayout: React.FunctionComponent = () => {
  return (
    <Layout className={styles['layout']}>
      <Layout.Header className={styles['layout__header']}>
        <Header />
      </Layout.Header>
      <Layout.Content className={styles['layout__content']}>
        <Suspense fallback={<p>loading</p>}>
          <Outlet />
        </Suspense>
      </Layout.Content>
    </Layout>
  );
};
