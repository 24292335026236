import { PropsWithChildren, createContext } from 'react';
import jsonData from './feature-flags/flags.json';
import { FeatureFlags } from './feature-flags/types';

export const FeatureFlagContext = createContext<FeatureFlags>({});

export const FeatureFlagProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  // Currently we use hard-coded features - as we do not have a feature manager yet.
  // Using this hard-coded features it does give the oppertunity to keep one main branch.
  const data: FeatureFlags = jsonData;
  return (
    <FeatureFlagContext.Provider value={data}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
