import { CustomButton, CustomColumnType, CustomTable } from '@/lib/components';
import { AgrinotaImportLog, ImportStatus } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getImportLog } from '../../../../lib/adapters/agrinota-service-adapter';

export const AgrinotaImportOverview = () => {
  const [importLogs, setImportLogs] = useState<AgrinotaImportLog[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const fetchData = () => {
    setLoading(true);
    getImportLog()
      .then((r) => setImportLogs(r))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableColumns: CustomColumnType<AgrinotaImportLog>[] = [
    {
      title: 'Klantnummer',
      dataIndex: 'customerNumber',
    },
    {
      title: 'AgrinotaId',
      dataIndex: 'agrinotaId',
    },
    {
      title: 'RelationId',
      dataIndex: 'relationId',
    },
    {
      title: 'Vanaf datum',
      dataIndex: 'fromDate',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: 'Facturen van klant',
      dataIndex: 'totalInvoices',
    },
    {
      title: 'Facturen status',
      dataIndex: 'invoiceImportStatus',
      render: (value) =>
        value ? t(`agrinota.importStatus.${ImportStatus[value]}`) : null,
    },
    {
      title: 'Betaalinstellingen status',
      dataIndex: 'paymentImportStatus',
      render: (value) =>
        value ? t(`agrinota.importStatus.${ImportStatus[value]}`) : null,
    },
    {
      title: 'Documenten status',
      dataIndex: 'documentImportStatus',
      render: (value) =>
        value ? t(`agrinota.importStatus.${ImportStatus[value]}`) : null,
    },
    {
      title: 'Uitvoerdatum',
      dataIndex: 'created',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
      defaultSortOrder: 'descend',
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: number) => (
        <CustomButton
          type="link"
          toolTipKey="agrinotaimport.action.showDetails"
          onClick={() => navigate(`${id}`)}
          icon={<FontAwesomeIcon icon="eye" />}
        />
      ),
    },
  ];

  return (
    <>
      <CustomTable
        rowKey="id"
        style={{ marginTop: '2rem' }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={importLogs}
        pagination={{ hideOnSinglePage: true }}
        size="small"
      />
    </>
  );
};
