import {
  AgrinotaImportDocument,
  AgrinotaImportInvoice,
  AgrinotaImportLog,
  AgrinotaImportPaymentSetting,
  AgrinotaImportRequest,
  AgrinotaRelation,
  PagedResponse,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';

const BASE_URL = 'agrinota-data/api';

export const getAgrinotaRelation = ({
  customerNumber,
  coc,
}: {
  customerNumber?: number;
  coc?: number;
}): Promise<AgrinotaRelation | null> =>
  api
    .get(`${BASE_URL}/relations`, {
      params: {
        customerNumber,
        coc,
      },
    })
    .then((r) => r.data);

export const createImportRequest = (request: AgrinotaImportRequest) =>
  api.post(`${BASE_URL}/relations/import`, request).then((r) => r.data);

export const getImportLog = (): Promise<AgrinotaImportLog[]> =>
  api
    .get(`${BASE_URL}/importlog`, {
      params: {},
    })
    .then((r) => r.data);

export const getImportLogDetails = (id: string): Promise<AgrinotaImportLog> =>
  api.get(`${BASE_URL}/importlog/${id}`).then((r) => r.data);

export const getImportLogInvoices = (
  id: string,
): Promise<PagedResponse<AgrinotaImportInvoice[]>> =>
  api.get(`${BASE_URL}/importlog/${id}/invoices`).then((r) => r.data);

export const getImportLogPaymentSettings = (
  id: string,
): Promise<PagedResponse<AgrinotaImportPaymentSetting[]>> =>
  api.get(`${BASE_URL}/importlog/${id}/paymentsettings`).then((r) => r.data);

export const getImportLogDocuments = (
  id: string,
): Promise<PagedResponse<AgrinotaImportDocument[]>> =>
  api.get(`${BASE_URL}/importlog/${id}/documents`).then((r) => r.data);
