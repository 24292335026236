import { CustomTable } from '@/lib/components';
import { InvoiceDelivery, InvoiceProduct } from '@/lib/types';
import { useTranslation } from 'react-i18next';

export const InvoiceProductTable = ({
  deliveries,
}: {
  deliveries: InvoiceDelivery[];
}) => {
  const { t } = useTranslation();

  return (
    <CustomTable
      rowKey="orderLine"
      dataSource={deliveries}
      columns={[
        {
          title: t('renders.invoice.product.orderLine'),
          dataIndex: 'orderLine',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
        },
        {
          title: t('renders.invoice.product.documentReference'),
          dataIndex: 'documentReference',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
        },
        {
          title: t('renders.invoice.product.despatchLineReference'),
          dataIndex: 'despatchLineReference',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
        },
        {
          title: t('renders.invoice.product.deliveryDate'),
          dataIndex: 'deliveryDate',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'dateonly',
        },
        {
          title: t('renders.invoice.product.invoiceProductName'),
          dataIndex: 'invoiceProduct',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          render: (value: InvoiceProduct) => value.name,
        },
        {
          title: t('renders.invoice.product.invoiceProductDescription'),
          dataIndex: 'invoiceProduct',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          render: (value: InvoiceProduct) => value.description,
        },
        {
          title: t('renders.invoice.product.invoicedQuantity'),
          dataIndex: 'invoicedQuantity',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
        },
        {
          title: t('renders.invoice.product.amountExPrice'),
          dataIndex: 'amountEx',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'currency',
        },
        {
          title: t('renders.invoice.product.amountEx'),
          dataIndex: 'amountEx',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'currency',
        },
        {
          title: t('renders.invoice.product.netAmountEx'),
          dataIndex: 'netAmountEx',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'currency',
        },
        {
          title: t('renders.invoice.product.vatPercentage'),
          dataIndex: 'invoiceProduct',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          render: (value: InvoiceProduct) => {
            if (!value || !Array.isArray(value.vats) || !value.vats.length) {
              return;
            }

            return value.vats[0].percentage;
          },
        },
      ]}
    />
  );
};
