import { getAfasRelation } from '@/lib/adapters/users-adapter';
import { Accent, CustomButton } from '@/lib/components';
import { RelationDetails } from '@/lib/components/relations/relationDetails';
import { Result, Spin } from 'antd';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomStepProps } from './types';

export const Afas: FC<CustomStepProps> = ({
  setCurrentPage,
  setAfasRelation,
  afasRelation,
  identifier,
}) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'relations.addRelation.steps.afas',
  });

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (identifier)
      getAfasRelation(identifier)
        .then((r) => setAfasRelation?.(r))
        .catch((e: AxiosError) => setError(e))
        .finally(() => setLoading(false));
  }, [identifier, setAfasRelation]);

  const Error404 = (
    <Result
      status="error"
      title={t('results.notFound.title')}
      subTitle={t('results.notFound.subtitle', { identifier })}
    ></Result>
  );
  const Error409 = (
    <Result
      status="error"
      title={t('results.multipleFound.title')}
      subTitle={t('results.multipleFound.subtitle')}
    ></Result>
  );
  const ErrorDefault = (
    <Result
      status="error"
      title={t('results.unexpected.title')}
      subTitle={t('results.unexpected.subtitle')}
    ></Result>
  );

  const ErrorComps = {
    404: Error404,
    409: Error409,
    default: ErrorDefault,
  };

  const ValidComp = (
    <>
      <Accent type="h2" color="secondary">
        {t('results.success.title')}
      </Accent>
      <RelationDetails relation={afasRelation} style={{ maxWidth: '400px' }} />
    </>
  );

  return (
    <Spin spinning={isLoading}>
      {error ? ErrorComps[error.response?.status || 'default'] : ValidComp}
      <div style={{ marginTop: '1rem' }}>
        <CustomButton
          type="link"
          onClick={() => setCurrentPage?.((prev) => prev - 1)}
        >
          {t('actions.back')}
        </CustomButton>
        <CustomButton
          type="primary"
          onClick={() => setCurrentPage?.((prev) => prev + 1)}
          disabled={!!error}
        >
          {t('actions.continue')}
        </CustomButton>
      </div>
    </Spin>
  );
};
