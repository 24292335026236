import { Col, Result, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserDetailEntry } from './userDetailEntry';

export const VerifyUser = ({
  username,
  relationName,
  locatioName,
}: {
  username?: string;
  relationName?: string;
  locatioName?: string;
}) => {
  const { t } = useTranslation();
  return (
    <Result
      status="info"
      title={t('userverification.title')}
      extra={[
        <Row>
          <Col flex={1}>
            <UserDetailEntry label="profile.username" value={username} />
            <UserDetailEntry label="profile.relation" value={relationName} />
            <UserDetailEntry label="profile.location" value={locatioName} />
          </Col>
        </Row>,
      ]}
    />
  );
};
