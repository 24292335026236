import { ScopeItem } from '@/lib/types/scope';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['lyanthe', 'scope'];
const BASE_URL = `${apiBasePaths.lyanthe}/v2/scope`;

export const useGetLyantheScope = (
  id?: string,
): UseQueryResult<ScopeItem[] | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () =>
      id
        ? api.get<ScopeItem[]>(`${BASE_URL}/${id}`).then((r) => r.data)
        : Promise.reject(),
    enabled: !!id,
  });
