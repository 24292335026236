import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const HeaderItem = ({ textKey }: { textKey: string }) => {
  const { t } = useTranslation();

  return (
    <Typography.Text ellipsis className={styles.header}>
      {t(textKey)}
    </Typography.Text>
  );
};
