import { Accent, CustomButton } from '@/lib/components';
import { UserRoles } from '@/lib/types/enums';
import { Relation, User } from '@/lib/types/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ExtraAccountantRelationProps {
  loading: boolean;
  user?: User;
  onFinish: (user: User) => void;
  onModalClose: () => void;
  relations: Relation[];
}

export const ExtraAccountantRelations = ({
  loading,
  user,
  onModalClose,
  onFinish,
  relations,
}: ExtraAccountantRelationProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    const nonPrimaryRelations = user?.relations.filter(
      (x) => x.id !== user.relationId,
    );
    form.setFieldsValue({ ...user, relations: nonPrimaryRelations });
  }, [form, user]);

  if (!user?.roles.includes(UserRoles.ACCOUNT_MANAGER)) {
    return <div></div>;
  }
  return (
    <Form onFinish={(values) => onFinish({ ...user, ...values })} form={form}>
      <Row gutter={16}>
        <Col span={8}>
          <Accent color="primary" type="strong">
            {t('user.username')}
          </Accent>
        </Col>
        <Col>
          <Accent>{user.username}</Accent>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Accent color="primary" type="strong">
            {t('user.extra-accountant-relations.primary-relation')}
          </Accent>
        </Col>
        <Col>
          <Accent>{user.relation.name}</Accent>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Accent color="primary" type="strong">
            {t('user.extra-accountant-relations.relations')}
          </Accent>
        </Col>
      </Row>
      <Form.List name="relations">
        {(fields, { add, remove }, { errors }) => (
          <Row>
            <Col span={24}>
              {fields.map((field, index) => (
                <Row key={index}>
                  <Col flex={1}>
                    <Form.Item
                      name={[field.name, 'id']}
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        options={relations
                          .filter((x) => x.id !== user.relationId)
                          .map((relation) => ({
                            label: relation.name,
                            value: relation.id,
                          }))}
                        filterOption={(input, option) => {
                          if (!option?.label) return false;

                          return (
                            option?.label
                              ?.toLocaleString()
                              ?.toLowerCase()
                              ?.indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item {...field}>
                      <CustomButton
                        type="link"
                        danger
                        shape="circle"
                        size="small"
                        onClick={() => remove(field.name)}
                        icon={<FontAwesomeIcon icon="trash-alt" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col>
              <CustomButton onClick={() => add()}>
                {t('user.extra-accountant-relations.actions.add-relation')}
              </CustomButton>
            </Col>
          </Row>
        )}
      </Form.List>
      <Row justify="center">
        <CustomButton loading={loading} type="link" onClick={onModalClose}>
          Annuleren
        </CustomButton>
        <CustomButton loading={loading} htmlType="submit" type="primary">
          {t('general.actions.save')}
        </CustomButton>
      </Row>
    </Form>
  );
};
