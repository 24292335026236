import { NotificationsRequest, PagedNotificationsResult } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['notification', 'notifications'];
const BASE_URL = `${apiBasePaths.notifications}/v1/notifications`;

export const useGetNotifications = (
  params?: NotificationsRequest,
  refetchInterval?: number,
): UseQueryResult<PagedNotificationsResult | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => api.get(`${BASE_URL}`, { params }).then((r) => r.data),
    enabled: !!params,
    refetchInterval: refetchInterval,
  });
