import {
  BatchLyantheFileRequest,
  CreateRelationAdministrationRequest,
  LyantheFile,
  LyantheFileFilter,
  PagedResponse,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.lyanthe}/v1`;
const BASE_URL_V2 = `${apiBasePaths.lyanthe}/v2`;

export const getFileScanStatus = (
  filter: LyantheFileFilter,
): Promise<PagedResponse<LyantheFile[]>> =>
  api
    .get(`${BASE_URL}/files`, {
      params: createFilterParams(filter),
    })
    .then((r) => r.data);

export const reprocessFile = (id: number) =>
  api.post(`${BASE_URL}/files/${id}/reprocess`).then((r) => r.data);

export const reprocessFileBatch = (request: BatchLyantheFileRequest[]) =>
  api.post(`${BASE_URL}/files/batch/reprocess`, request).then((r) => r.data);

export const deleteFile = (id: number, reason: string, description?: string) =>
  api
    .delete(
      `${BASE_URL_V2}/files/${id}?reason=${reason}&description=${description}`,
    )
    .then((r) => r.data);

export const deleteFileBatch = (request: BatchLyantheFileRequest[]) =>
  api.post(`${BASE_URL_V2}/files/batch/delete`, request).then((r) => r.data);

export const createRelationAdministration = (
  request: CreateRelationAdministrationRequest,
) => api.post(`${BASE_URL_V2}/administrations`, request).then((r) => r.data);
