import { getInfoButtons } from '@/lib/adapters/cms-adapter';
import { getApplicationSettings } from '@/lib/adapters/users-adapter';
import { applicationSettingsAtom, infoButtonsAtom } from '@/lib/atoms/atoms';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';

export const useAppSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const setInfoButtons = useUpdateAtom(infoButtonsAtom);
  const setApplicationSettings = useUpdateAtom(applicationSettingsAtom);

  useEffect(() => {
    const loadData = async () => {
      const infoButtons = await getInfoButtons();
      setInfoButtons(infoButtons);
      const applicationSettings = await getApplicationSettings();
      setApplicationSettings(applicationSettings);
    };

    setIsLoading(true);
    loadData().finally(() => setIsLoading(false));
  }, [setApplicationSettings, setInfoButtons]);

  return isLoading;
};
