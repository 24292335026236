import { Layout, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const PaymentLayout = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const items = [
    {
      key: `/payment/inpayment`,
      label: (
        <>
          <Link to={`/payment/inpayment`} />
          {t('nav.inpayment')}
        </>
      ),
    },
    {
      key: `/payment/paid`,
      label: (
        <>
          <Link to={`/payment/paid`} />
          {t('nav.paid')}
        </>
      ),
    },
    {
      key: `/payment/suppliers`,
      label: (
        <>
          <Link to={`/payment/suppliers`} />
          {t('nav.supplierSettings')}
        </>
      ),
    },
  ];

  return (
    <Layout
      hasSider
      style={{ paddingRight: '16px', backgroundColor: 'transparent' }}
    >
      <Sider
        theme="light"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Menu
          mode="inline"
          theme="light"
          style={{ background: 'transparent' }}
          activeKey={location.pathname}
          selectedKeys={[location.pathname]}
          items={items}
        />
      </Sider>
      <Content style={{ backgroundColor: 'white', padding: '30px' }}>
        <Outlet />
      </Content>
    </Layout>
  );
};
