import { User } from '@/lib/types/user';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['user', 'users'];
const BASE_URL = `${apiBasePaths.user}/v1/users`;

export const useGetCurrentUser = (): UseQueryResult<User, unknown> =>
  useQuery({
    meta: {
      persistOnReset: true,
    },
    queryKey: [...QUERY_KEY, 'current'],
    queryFn: () => api.get<User>(`${BASE_URL}/current`).then((r) => r.data),
  });
