export enum RelationType {
  Customer = 'Customer',
  External = 'External',
  Accountant = 'Accountant',
}

export enum IdentifierCategory {
  COC = 0,
  VAT = 1,
  CUSTOMER = 2,
}

export enum UserRoles {
  APP_ADMIN = 'app-administrator',
  ACCOUNT_MANAGER = 'account-manager',
}

export enum SingleTaskType {
  NotifyUsersViaEmail,
  NotifyUsersInApp,
}

export enum SingleTaskState {
  Queued,
  Failed,
  Cancelled,
  Success,
}

export enum LyantheFileStatus {
  None = 'None',
  Uploaded = 'Uploaded',
  Processing = 'Processing',
  Error = 'Error',
  Success = 'Success',
}

export enum SortOrder {
  Ascending,
  Descending,
}

export enum InvoiceType {
  Purchase = 'Purchase',
  SelfBilling = 'SelfBilling',
  CreditNote = 'CreditNote',
  Sales = 'Sales',
  SalesCreditNote = 'SalesCreditNote',
}

export enum NotificationLevel {
  Info,
  Success,
  Error,
}

export enum ImportStatus {
  None,
  Error,
  Success,
  Disabled,
}

export enum ImportEntryStatus {
  None,
  Success,
  Error,
  InvalidStatus,
  NotDateInRange,
  NotLinkedToRelation,
  Duplicate,
}

export enum ImportType {
  None,
  Ubl,
  Data,
}

export enum UploadType {
  Purchase,
  Sales,
  External = 999,
}

export enum InvoiceProcessStatus {
  None,
  Uploaded,
  OcrUploaded,
  OcrProcessing,
  Processing,
  Success,
  Error,
  Rejected,
}