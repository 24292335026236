import { CustomButton } from "@/lib/components";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

export const Guide = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.settings.support.guide',
  });
  return (
    <>
      <Row style={{ marginBottom: '5px' }}>
        <CustomButton
          color="primary">
          <a target="_blank" href="https://www.alfa.nl/supportmijnalfa">
            {t('button.guide')}
          </a>
        </CustomButton>
      </Row>
      <Row style={{ marginBottom: '5px' }}>
        <CustomButton
          color="primary">
          <a target="_blank" href="https://www.alfa.nl/aanmelden-online-trainingen-mijn-alfa">
            {t('button.training')}
          </a>
        </CustomButton>
      </Row>
      <Row>
        <CustomButton
          color="primary">
          <a target="_blank" href="https://www.alfa.nl/supportmijnalfa-veelgesteldevragen">
            {t('button.faq')}
          </a>
        </CustomButton>
      </Row>
    </>
  )
}