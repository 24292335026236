import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverProps } from 'antd';
import React from 'react';

interface InfoButtonProps extends PopoverProps {
  showIfEmpty?: boolean;
}

export const InfoButton: React.FunctionComponent<InfoButtonProps> = ({
  showIfEmpty = false,
  ...props
}) => {
  if (!props.content && !showIfEmpty) {
    return;
  }
  return (
    <Popover {...props}>
      <FontAwesomeIcon size="sm" color="var(--info-color)" icon="info-circle" />
    </Popover>
  );
};
