import { Accent, CustomButton } from '@/lib/components';
import { Form, Input } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomStepProps } from './types';

export const Identifier: FC<CustomStepProps> = ({
  form,
  setCurrentPage,
  setIdentifier,
  identifier,
  disabled,
}) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'relations.addRelation.steps.identifier',
  });
  const goNext = (values) => {
    setIdentifier?.(values.identifier);
    setCurrentPage?.((prev) => prev + 1);
  };

  return (
    <>
      <Accent type="h2" color="secondary">
        {t('inputLabel')}
      </Accent>
      <Form name="identifier" onFinish={goNext}>
        <Form.Item
          initialValue={identifier}
          name="identifier"
          rules={[{ required: true, min: 3 }]}
        >
          <Input
            size="large"
            placeholder={t('inputPlaceHolder')}
            disabled={disabled}
          />
        </Form.Item>
        <CustomButton htmlType="submit" type="primary">
          {t('actions.continue')}
        </CustomButton>
      </Form>
    </>
  );
};
