import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Navigatable } from '@/lib/types';
import { ActionScopeItem, FileType } from '@/lib/types/scope';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { Collapse, Descriptions } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScopeViewItemLine } from './scopeViewItemLine';
import styles from './styles.module.scss';

interface ScopeViewItemProps extends Navigatable {
  item: ActionScopeItem;
}

export const ScopeViewItem: React.FunctionComponent<ScopeViewItemProps> = ({
  item,
  onNavigate,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'scope' });
  const [hasAccess] = useAtom(hasAccessAtom);

  item.fileType =
    typeof item.fileType === 'number' ? FileType[item.fileType] : item.fileType;

  if (!hasAccess.isAdmin) {
    return <ScopeViewItemLine item={item} onNavigate={onNavigate} />;
  }

  return (
    <Collapse ghost>
      <Collapse.Panel
        key={item.id}
        className={styles.panelHeader}
        header={<ScopeViewItemLine item={item} onNavigate={onNavigate} />}
      >
        <Descriptions size="small" column={1}>
          <DescriptionsItem label={t('fields.id')}>{item.id}</DescriptionsItem>
          <DescriptionsItem label={t('fields.fileType')}>
            {item.fileType}
          </DescriptionsItem>
          <DescriptionsItem label={t('fields.status')}>
            {item.status || '-'}
          </DescriptionsItem>
          <DescriptionsItem label={t('fields.statusDescription')}>
            {item.statusDescription || '-'}
          </DescriptionsItem>
          <DescriptionsItem label={t('fields.created')}>
            {dateTimeFormatter.toDateTimeFromUtc(item.created)}
          </DescriptionsItem>
          <DescriptionsItem label={t('fields.updated')}>
            {dateTimeFormatter.toDateTimeFromUtc(item.updated)}
          </DescriptionsItem>
          <DescriptionsItem label={t('fields.deleted')}>
            {item.deleted
              ? dateTimeFormatter.toDateTimeFromUtc(item.deleted)
              : '-'}
          </DescriptionsItem>
          <DescriptionsItem label={t('fields.scopeId')}>
            {item.scopeId}
          </DescriptionsItem>
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  );
};
