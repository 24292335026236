import {
  AdministrationIdentification,
  AdministrationIdentificationDetails,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['lyanthe', 'lyantheadministrations'];
const BASE_URL = `${apiBasePaths.lyanthe}/v2`;

export const useGetAdministrationsByIdentifier = (
  cocNumber?: string,
  vatNumber?: string,
): UseQueryResult<AdministrationIdentification[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, cocNumber, vatNumber],
    queryFn: () =>
      api
        .get(`${BASE_URL}/lyantheadministrations/identifiers`, {
          params: {
            cocNumber,
            vatNumber,
          },
        })
        .then((r) => r.data),
  });

export const useGetAdministrationDetails = (
  administrationId?: string,
): UseQueryResult<AdministrationIdentificationDetails, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, administrationId],
    queryFn: () =>
      api
        .get(`${BASE_URL}/lyantheadministrations/${administrationId}`, {})
        .then((r) => r.data),
    enabled: !!administrationId,
  });
