import { notification } from 'antd';

export const showNotification = (
  type: 'success' | 'info' | 'warning' | 'error',
  text?: string,
) => {
  notification[type]({
    message: text,
  });
};
