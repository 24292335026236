import { SingleTask, SingleTaskModifyRequest } from '@/lib/types/scheduler';
import { api } from '@/lib/utils/api.axios';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.scheduler}/singletasks`;

export const getSingleTasks = () =>
  api.get<SingleTask[]>(`${BASE_URL}`).then((r) => r.data);

export const createSingleTask = (task: SingleTaskModifyRequest) =>
  api.post<SingleTask>(`${BASE_URL}`, task).then((r) => r.data);

export const updateSingleTask = (id: number, task: SingleTaskModifyRequest) =>
  api.put<SingleTask>(`${BASE_URL}/${id}`, task).then((r) => r.data);

export const cancelSingleTask = (id: number) =>
  api.patch<SingleTask>(`${BASE_URL}/${id}/cancel`).then((r) => r.data);

export const deleteSingleTask = (id: number) =>
  api.delete<SingleTask>(`${BASE_URL}/${id}`).then((r) => r.data);
