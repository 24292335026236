import { getSepaFile } from '@/lib/adapters/payment-adapter';
import { InvoicePayment } from '@/lib/types';
import { downloadUrl } from '@/lib/utils/download-file';
import { showNotification } from '@/lib/utils/showNotification';
import { useTranslation } from 'react-i18next';
import { InvoiceCreditList } from './invoiceCreditList';
import { InvoicePaymentList } from './invoicePaymentList';

type InvoicePaymentsProps = {
  invoicePayment: InvoicePayment | undefined;
};

export const InvoicePayments = ({ invoicePayment }: InvoicePaymentsProps) => {
  const { t } = useTranslation();

  const downloadSepa = async (paymentId: number) => {
    try {
      if (!paymentId) {
        throw new Error('Missing sepa payment id.');
      }

      const data = await getSepaFile(paymentId);
      downloadUrl(data.blobStorageUri, `sepa_${paymentId}.xml`);
    } catch (error) {
      console.log(error);
      showNotification('error', t('Error.FilesNotFound'));
    }
  };

  if (!invoicePayment) {
    return null;
  }

  return (
    <>
      <InvoicePaymentList
        downloadSepa={downloadSepa}
        paymentEntries={invoicePayment.paymentEntries}
      />
      <InvoiceCreditList
        downloadSepa={downloadSepa}
        nettingPaymentEntries={invoicePayment.nettingPaymentEntries}
      />
    </>
  );
};
