import { CustomButton, DocumentViewModal } from '@/lib/components';
import { Feature } from '@/lib/context';
import { useGetIncomingFile, useGetProcessFileDuplicate } from '@/lib/queries';
import { FolderViewOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface DuplicateInvoiceViewProps {
  duplicateIncomingFileId?: string;
  processFileId?: string;
}
export const DuplicateInvoiceView: React.FunctionComponent<
  DuplicateInvoiceViewProps
> = ({ duplicateIncomingFileId, processFileId }) => {
  const { t } = useTranslation();

  return (
    <Feature
      flag="notifications.invoiceDuplicate"
      enabled={
        <Popover
          title={t(`notifications.duplicateOf`)}
          content={
            processFileId ? (
              <DuplicateProcessFileView processFileId={processFileId} />
            ) : (
              <DuplicateIncomingFileView
                incomingFileId={duplicateIncomingFileId}
              />
            )
          }
        >
          <FontAwesomeIcon
            size="sm"
            color="var(--info-color)"
            icon="info-circle"
            style={{ marginRight: '5px' }}
          />
        </Popover>
      }
    ></Feature>
  );
};

const DuplicateProcessFileView = ({ processFileId }) => {
  const { t } = useTranslation();

  const { data: duplicate, isLoading } =
    useGetProcessFileDuplicate(processFileId);

  const navigate = useNavigate();

  if (isLoading) {
    return <Spin />;
  }

  if (!duplicate) {
    return <p>{t('notifications.duplicateNotFound')}</p>;
  }

  const handleDossierView = () => {
    navigate(`/dossier/${duplicate.invoiceId}`);
  };

  return (
    <>
      {duplicate.blobStorageUri && (
        <DocumentViewModal id={duplicate.blobStorageUri} idType="url" />
      )}
      {duplicate.invoiceId && (
        <CustomButton
          shape="circle"
          icon={<FolderViewOutlined />}
          toolTipKey="document.action.goDossier"
          onClick={handleDossierView}
        />
      )}
    </>
  );
};

const DuplicateIncomingFileView = ({ incomingFileId }) => {
  const { t } = useTranslation();

  const { data: duplicate, isLoading } = useGetIncomingFile(incomingFileId);

  if (isLoading) {
    return <Spin />;
  }

  if (!duplicate) {
    return <p>{t('notifications.duplicateNotFound')}</p>;
  }

  return (
    <>
      {duplicate.blobStorageUri && (
        <DocumentViewModal id={duplicate.blobStorageUri} idType="url" />
      )}
    </>
  );
};
