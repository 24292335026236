import { RefObject } from 'react';

export const pdfBackend = () => {
  const init = (source, element: RefObject<HTMLDivElement>) => {
    const iframe = document.createElement('iframe');
    iframe.src = `/pdfjs/web/viewer.html?file=${source}`;
    iframe.width = '100%';
    iframe.height = '100%';

    if (element.current?.firstChild) {
      element.current.firstChild.remove();
    }

    element.current?.appendChild(iframe);
  };

  return { init };
};
