import { infoButtonsAtom } from '@/lib/atoms/atoms';
import { useAtomValue } from 'jotai/utils';
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';

interface ExternalLinkProps {
  linkKey: string;
  defaultValue?: string;
  target: HTMLAttributeAnchorTarget;
}

export const ExternalLink: React.FunctionComponent<
  PropsWithChildren<ExternalLinkProps>
> = ({ children, linkKey, defaultValue, target }) => {
  const infoButtons = useAtomValue(infoButtonsAtom);
  const pathname =
    infoButtons.find((button) => button.key === linkKey)?.value || defaultValue;
  return (
    <a href={pathname} target={target}>
      {children}
    </a>
  );
};
