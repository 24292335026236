import { AdministrationIdentificationDetails } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['lyanthe', 'administrations'];
const BASE_URL = `${apiBasePaths.lyanthe}/v1`;

export const useGetAdministrationByOwner = (
  ownerRelationId?: string,
): UseQueryResult<AdministrationIdentificationDetails, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, ownerRelationId],
    queryFn: () =>
      api
        .get(`${BASE_URL}/administrations/by-owner/${ownerRelationId}`, {})
        .then((r) => r.data),
    enabled: !!ownerRelationId,
  });
