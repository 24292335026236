import { Grid } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UploadForm } from './_components/uploadForm';

const { useBreakpoint } = Grid;

export const RootPage: FC = () => {
  const { xl, xs } = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    if (xl) {
      navigate('/invoices');
    }
  }, [xl, xs]);

  if (xs) {
    return <UploadForm visible={true} />;
  }
  return null;
};
