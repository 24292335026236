import { CustomButton } from '@/lib/components';
import { downloadUrl } from '@/lib/utils/download-file';
import { showNotification } from '@/lib/utils/showNotification';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { InvoiceRender } from './renders/invoiceRender';

type DocumentViewErrorComponentProps = {
  idType: 'invoice' | 'processfile' | 'incomingfile' | 'document' | 'url';
  data?: any;
  url?: string;
};

export const DocumentViewErrorComponent = ({
  idType,
  data,
  url,
}: DocumentViewErrorComponentProps) => {
  const { t } = useTranslation();

  const downloadFile = async () => {
    try {
      if (!url) {
        throw new Error('no url');
      }

      await axios.get(url);
      downloadUrl(url, '');
    } catch (error) {
      console.log(error);
      showNotification('error', t('Error.FilesNotFound'));
    }
  };

  const DATAPREVIEW = {
    invoice: <InvoiceRender invoice={data} />,
    processfile: <InvoiceRender invoice={data} />,
    default: (
      <div style={{ width: '100%', height: '100%', backgroundColor: '#eee' }}>
        <h3>Kan voorbeeld niet laden.</h3>

        {url && (
          <CustomButton onClick={downloadFile}>Download bestand</CustomButton>
        )}
      </div>
    ),
  };

  if (!data) {
    return DATAPREVIEW['default'];
  }

  return DATAPREVIEW[idType] ?? DATAPREVIEW['default'];
};
