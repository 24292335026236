import { CustomButton } from '@/lib/components';
import {
  InvoiceStatusReason,
  InvoiceStatusReasonModifyRequest,
} from '@/lib/types';
import { Col, Form, Input, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

interface IAddEditInvoiceRejectReasonForm {
  isLoading: boolean;
  value?: InvoiceStatusReason;
  onFinish: (entity: InvoiceStatusReasonModifyRequest) => void;
  onModalClose: () => void;
}

export const AddEditInvoiceRejectReasonForm = ({
  onFinish,
  isLoading,
  value,
  onModalClose,
}: IAddEditInvoiceRejectReasonForm) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'settings.applicationSettings.invoiceRejectReasons',
  });
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onFinish(values)}
      initialValues={value}
    >
      <Form.Item
        label={t('fields.statusReason')}
        name="statusReason"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="freeDescription"
        label={t('fields.freeDescription')}
        initialValue={value ? !value.statusDescription : false}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.freeDescription !== curValues.freeDescription
        }
      >
        {() => {
          const isChecked = form.getFieldValue('freeDescription');
          if (isChecked) {
            return;
          }
          return (
            <Form.Item
              label={t('fields.statusDescription')}
              name="statusDescription"
              rules={[{ required: true, max: 70 }]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Row justify="space-between">
        <Col>
          <CustomButton type="link" loading={isLoading} onClick={onModalClose}>
            {t('actions.cancel')}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton htmlType="submit" type="primary" loading={isLoading}>
            {t('actions.save')}
          </CustomButton>
        </Col>
      </Row>
    </Form>
  );
};
