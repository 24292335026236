import { ThemeColors } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FC, MouseEvent } from 'react';
import styles from './accent.module.scss';

type AccentProps = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'div' | 'span' | 'p' | 'small' | 'strong';
  color?: ThemeColors;
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string[];
  prefix?: {
    value: string;
    inline?: boolean;
    color?: ThemeColors;
    type?: 'strong' | 'span';
  };
  inline?: boolean;
  onClick?: (e: MouseEvent) => void;
};

export const Accent: FC<AccentProps> = ({
  style,
  type = 'span',
  color = 'default',
  children,
  prefix,
  className = [],
  inline = false,
  onClick,
}) => {
  const TypeComp = type;

  const Prefix = () => {
    if (!prefix) return null;
    const PrefixTypeComp = prefix.type || 'span';
    const prefixStyles = [
      prefix.inline ? styles.prefixInline : styles.prefixBlock,
    ];

    if (prefix.color) prefixStyles.push(styles[prefix.color]);

    return (
      <PrefixTypeComp className={prefixStyles.join(' ')}>
        {prefix.value}
      </PrefixTypeComp>
    );
  };

  return (
    <TypeComp
      style={style}
      className={[
        styles.accent,
        styles[color],
        inline && styles.inline,
        onClick && styles.hasClickEvent,
        ...className,
      ].join(' ')}
      onClick={onClick}
    >
      <Prefix />
      {children}
      {onClick && (
        <FontAwesomeIcon
          icon={['far', 'question-circle']}
          style={{ marginLeft: 10 }}
          size="xs"
        />
      )}
    </TypeComp>
  );
};
