import { NotificationLevel, TrackingFilterOptions } from "@/lib/types";
import { convertEnumToArray } from "@/lib/utils/helpers";
import { Col, Select, Space } from "antd";
import Search from "antd/lib/input/Search";
import { useTranslation } from "react-i18next";
import { DateFilter } from "../datepickerFilter/dateFilter";
import { FC } from "react";

interface TrackingFilterProps {
  filter: TrackingFilterOptions;
  setFilter: React.Dispatch<React.SetStateAction<TrackingFilterOptions>>
}

export const TrackingFilter: FC<TrackingFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const enumList = convertEnumToArray(NotificationLevel);

  const search = (terms: string | undefined) => {
    setFilter(e => { return { ...e, terms: !!terms ? terms : undefined } })
  }

  const setActiveLevel = (level: number | undefined) => {
    setFilter(e => { return { ...e, level } })
  }

  return (
    <Space direction="vertical">
      <Search allowClear onSearch={search} placeholder='Zoek in bestandsnaam' />
      <Space direction="horizontal">
        <Select
          onChange={setActiveLevel}
          onClear={() => setActiveLevel(undefined)}
          placeholder="Kies een niveau"
          allowClear
          style={{ width: 200 }}
          value={filter.level}
        >
          {enumList.map((level) => (
            <Select.Option key={level.key} value={level.key}>
              {level.value}
            </Select.Option>
          ))}
        </Select>
        <Col>
          <DateFilter filterOptions={filter} setFilterOptions={setFilter}></DateFilter>
        </Col>
      </Space>
    </Space>
  );
}
