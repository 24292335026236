import {
  NotificationsRequest,
  PagedNotificationsResult,
  UINotificationSettings,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.notifications}/v1`;

// TRACKING
export const getNotifications = (
  params?: NotificationsRequest,
): Promise<PagedNotificationsResult> =>
  api.get(`${BASE_URL}/notifications`, { params }).then((r) => r.data);

export const patchNotification = (id, isSeen) =>
  api
    .patch(`${BASE_URL}/notifications/${id}`, { isMarkedAsSeen: isSeen })
    .then((r) => r.data);

export const markAsSeenBasedOnFilter = (request: NotificationsRequest) => {
  return api
    .patch(`${BASE_URL}/notifications/bulk`, request)
    .then((r) => r.data);
};

export const setNotificationSettings = (
  notificationSettings: UINotificationSettings[],
) => {
  return api
    .post(`${BASE_URL}/notificationsettings`, notificationSettings)
    .then((r) => r.data);
};

export const getNotificationSettings = () => {
  return api
    .get<UINotificationSettings[]>(`${BASE_URL}/notificationsettings`)
    .then((r) => r.data);
};

export const deleteNotification = (id) =>
  api.delete(`${BASE_URL}/notifications/${id}`).then((r) => r.data);
