import { ExtendedInvoice, Invoice, InvoiceType } from '@/lib/types';

export const convertToExtendedInvoice = (invoice: Invoice): ExtendedInvoice => {
  const counterInvoiceRelation =
    invoice.counterRelationId === invoice.supplier.relationId
      ? invoice.supplier
      : invoice.customer;

  const isPayable =
    invoice.type.toString() === InvoiceType[InvoiceType.Purchase] ||
    invoice.type.toString() === InvoiceType[InvoiceType.CreditNote];

  return {
    ...invoice,
    counterInvoiceRelation,
    isPayable: isPayable,
  };
};
