import { ApplicationSettingsProps, NotaKeycloakTokenParsed } from '@/lib/types';
import { InfoButtonType } from '@/lib/types/cms';
import { UserRoles } from '@/lib/types/enums';
import { atom } from 'jotai';
import Keycloak from 'keycloak-js';

export const payableInvoiceCountAtom = atom<number>(0);

export const applicationSettingsAtom = atom<ApplicationSettingsProps>({
  maxFileSize: 4,
  maxFileCount: 20,
  pageSize: 10,
});

const keycloak = new Keycloak({
  clientId: (window as any).AUTH_CLIENT_ID,
  realm: (window as any).AUTH_REALM,
  url: (window as any).AUTH_URL + '/auth',
});

export const keycloakInstanceAtom = atom(keycloak);

export const isAuthenticatedAtom = atom(false);

export const infoButtonsAtom = atom<InfoButtonType[]>([]);

export const appNameAtom = atom('Mkbnota');

export const userRolesAtom = atom<{
  isAccountManager: boolean;
  roles: string[];
}>((get) => {
  const keycloakInstance = get(keycloakInstanceAtom);

  const roles = (keycloakInstance.idTokenParsed as NotaKeycloakTokenParsed)
    ?.roles;

  const rolesObject = {
    isAccountManager: roles?.includes(UserRoles.ACCOUNT_MANAGER) ?? false,
    roles,
  };

  return rolesObject;
});

export const userTokenParsed = atom<NotaKeycloakTokenParsed>((get) => {
  const keycloakInstance = get(keycloakInstanceAtom);
  return (keycloakInstance.idTokenParsed as NotaKeycloakTokenParsed) ?? {};
});

export const hasAccessAtom = atom((get) => {
  const parsedToken = get(keycloakInstanceAtom)
    .idTokenParsed as NotaKeycloakTokenParsed;
  const userRoles = parsedToken.roles;

  const isAdmin = userRoles.includes(UserRoles.APP_ADMIN);
  const isAccountManager =
    userRoles.includes(UserRoles.ACCOUNT_MANAGER) && !isAdmin;
  const isCustomer = !isAdmin && !isAccountManager;
  const canImpersonate = isAdmin || isAccountManager;

  return {
    isSupport: parsedToken.preferred_username.endsWith('@vaa.com') && isAdmin, // possible future improvement: make this a seperate role
    roles: userRoles,
    isAdmin,
    isAccountManager,
    isCustomer,
    canImpersonate,
  };
});
