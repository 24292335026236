export const sum = (arr: any[], key: string) => {
  if (!Array.isArray(arr)) {
    return 0;
  }

  return arr.reduce((a, b) => a + (b[key] || 0), 0);
};

export const toQueryParams = (arr: string[], paramName: string) => {
  const result = {};
  arr.forEach((e, i) => result[`${paramName}[${i}]`] = e)
  return result;
}