interface SorterProperty<T> {
  property: keyof T | ((p: T) => unknown);
  emptyOnTop?: boolean;
  asc: boolean;
}

const compareValues = <T>(a: T, b: T, sortProperty: SorterProperty<T>) => {
  const sortOrder = sortProperty.asc ? 1 : -1;
  const property = sortProperty.property;
  const emptyRate = sortProperty.emptyOnTop ?? true ? 1 : -1;
  const aValue = typeof property === 'function' ? property(a) : a[property];
  const bValue = typeof property === 'function' ? property(b) : b[property];
  const result =
    aValue === bValue
      ? 0
      : aValue == null
      ? emptyRate
      : bValue == null
      ? emptyRate
      : aValue < bValue
      ? -1
      : 1;

  return result * sortOrder;
};

const sortProperty = <T>(sorter: SorterProperty<T>) => {
  return (a: T, b: T) => {
    return compareValues(a, b, sorter);
  };
};

const sortMultipleProperties = <T>(properties: SorterProperty<T>[]) => {
  return (a: T, b: T) => {
    for (const p of properties) {
      const result = compareValues(a, b, p);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
};

export const sorter = (() => {
  return {
    sortProperty,
    sortMultipleProperties,
  };
})();
