import { deleteRelation } from '@/lib/adapters/users-adapter';
import { CustomButton } from '@/lib/components';
import { GenericModal } from '@/lib/components/core/generic-modal/genericModal';
import { RelationDetails } from '@/lib/components/relations/relationDetails';
import { useGetRelationUsers } from '@/lib/queries';
import { Relation } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, List, Row, Spin, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RelationDeleteModalProps {
  relation: Relation;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  confirmText?: string;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}
export const RelationDeleteModal: React.FunctionComponent<
  RelationDeleteModalProps
> = ({
  relation,
  isModalVisible,
  confirmText,
  setIsModalVisible,
  onCancelHandler,
  onSubmitHandler,
}) => {
  const { t } = useTranslation();
  const { data: users, isLoading } = useGetRelationUsers(relation.id);

  const handleDelete = async () => {
    try {
      await deleteRelation(relation.id);
      onSubmitHandler?.();
      setIsModalVisible(false);
      showNotification('success', 'Succesvol uitgevoerd');
    } catch {
      showNotification('error', 'Fout tijdens opslaan');
    }
  };

  const onCancel = () => {
    onCancelHandler?.();
    setIsModalVisible(false);
  };

  return (
    <>
      <GenericModal
        title={t('general.actions.delete')}
        okText={t('action.delete.invoice.ok')}
        isVisible={isModalVisible}
        hideModal={() => setIsModalVisible(false)}
        onCancelHandler={onCancel}
        footer={false}
        destroyOnClose
      >
        <Spin spinning={isLoading}>
          <Row>
            <Col flex={1}>
              {!!users?.length ? (
                <>
                  <Typography.Paragraph>
                    {t('relation.deleteBlockedByUsers.prefix')}
                  </Typography.Paragraph>
                  <List
                    bordered={false}
                    split={false}
                    size="small"
                    dataSource={users}
                    renderItem={(item) => (
                      <List.Item>{item.username}</List.Item>
                    )}
                  />
                  <Typography.Paragraph>
                    {t('relation.deleteBlockedByUsers.suffix')}
                  </Typography.Paragraph>
                </>
              ) : (
                <Typography.Paragraph>
                  {confirmText
                    ? confirmText
                    : t('relation.actions.confirmDelete')}
                  <RelationDetails relation={relation} />
                </Typography.Paragraph>
              )}
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <CustomButton type="default" onClick={onCancel}>
                {t('general.actions.cancel')}
              </CustomButton>
            </Col>
            <Col>
              <CustomButton
                danger
                icon={<FontAwesomeIcon icon="trash-alt" />}
                onClick={handleDelete}
                disabled={!!users?.length}
              >
                {t('general.actions.delete')}
              </CustomButton>
            </Col>
          </Row>
        </Spin>
      </GenericModal>
    </>
  );
};
