import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const TeamviewerButton = ({ textKey }: { textKey: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.teamviewerBtn}>
      <a
        className={styles.teamviewerBtnA}
        href="https://www.teamviewer.com/link/?url=505374&id=1674462406396"
        target='_blank'
      >
        <img
          className={styles.teamviewerBtnImg}
          src="https://static.teamviewer.com/resources/badges/teamviewer_badge_flat3.png"
          title="Remote Access and Support over the Internet with TeamViewer"
        />
        <span className={styles.teamviewerBtnSpan}>{t(textKey)}</span>
      </a>
    </div>
  );
};
