import { PaymentSetting, PaymentSettingBankAccount, PaymentSettingTableEntry } from '@/lib/types';
import { BaseTableComponentProps } from '@/lib/types/table';
import { percentageFormatter } from '@/lib/utils/formatters';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CmsTooltip, CustomColumnType, CustomTable } from '../core';

interface PaymentSettingsTableProps
  extends BaseTableComponentProps<PaymentSettingTableEntry> { }

export const PaymentSettingsTable: React.FunctionComponent<
  PaymentSettingsTableProps
> = ({
  items,
  pagination,
  isLoading,
  additionalColumns = [],
  onTableChange,
}) => {
    const { t } = useTranslation();

    const tableColumns: CustomColumnType<PaymentSettingTableEntry>[] = [
      {
        dataIndex: 'bankAccounts',
        width: 40,
        enableDefaultSearch: false,
        render: (bankAccounts: PaymentSettingBankAccount[]) => {
          const validatedAccounts = bankAccounts?.filter(
            (x) => x.isValidated,
          ).length;
          const icon =
            !bankAccounts.length || !validatedAccounts ? (
              <CloseCircleFilled style={{ color: 'var(--danger)' }} />
            ) : validatedAccounts < bankAccounts.length ? (
              <MinusCircleFilled style={{ color: 'var(--secondary)' }} />
            ) : (
              <CheckCircleFilled style={{ color: 'var(--primary)' }} />
            );
          return (
            <CmsTooltip toolTipKey="settingsMyNotaSuppliers.label.validated">
              {icon}
            </CmsTooltip>
          );
        },
      },
      {
        title: t('settings.tabs.supplier.header.name'),
        dataIndex: 'name',
      },
      {
        title: t('settings.tabs.supplier.header.address'),
        dataIndex: 'street',
      },
      {
        title: t('settings.tabs.supplier.header.zipcode'),
        dataIndex: 'zipcode',
      },
      {
        title: t('settings.tabs.supplier.header.city'),
        dataIndex: 'city',
      },
      {
        title: t('settings.tabs.supplier.header.country'),
        dataIndex: 'country',
      },
      {
        title: t('settings.tabs.supplier.header.iban'),
        key: 'iban',
        dataIndex: 'bankAccounts',
        render: (value) => value?.[0]?.iban,
        onFilter: (value, record: PaymentSetting) =>
          record.bankAccounts[0]?.iban?.includes(value as string) || false,
      },
      {
        title: t('settings.tabs.supplier.header.bic'),
        key: 'bic',
        dataIndex: 'bankAccounts',
        render: (value) => value?.[0]?.bic,
        onFilter: (value, record: PaymentSetting) =>
          record.bankAccounts[0]?.bic?.includes(value as string) || false,
      },
      {
        title: t('settings.tabs.supplier.header.defaultDiscount'),
        dataIndex: 'defaultDiscount',
        render: (value) => (value ? percentageFormatter().format(value) : null),
      },
      {
        title: t('settings.tabs.supplier.header.paymentTerm'),
        dataIndex: 'paymentTerm',
      },
      ...additionalColumns,
    ];

    const sortedData = useMemo(
      () =>
        items.sort((a, b) => {
          const aLength = a.bankAccounts.length;
          const aValidated = a.bankAccounts?.filter((x) => x.isValidated).length;
          if (!aLength || !aValidated) {
            return -1;
          }

          const bLength = b.bankAccounts.length;
          const bValidated = b.bankAccounts?.filter((x) => x.isValidated).length;
          if (!bLength || !bValidated) {
            return 1;
          }

          if (aValidated === aLength) {
            return 1;
          }

          if (bValidated === bLength) {
            return -1;
          }

          return 0;
        }),
      [items],
    );

    return (
      <CustomTable
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={sortedData}
        size="small"
        pagination={pagination}
        onChange={onTableChange}
      />
    );
  };
