import { Col, DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CmsTooltip } from "../core";
import { DateFilterOptions } from "@/lib/types/dateFilter";

interface DateFilterProps<T extends DateFilterOptions> {
  filterOptions: T;
  setFilterOptions: React.Dispatch<React.SetStateAction<T>>;
}

export const DateFilter = <T extends DateFilterOptions>({ filterOptions, setFilterOptions }: DateFilterProps<T>) => {
  const { t } = useTranslation();

  const { RangePicker } = DatePicker;

  const ranges = (): {} => {
    const now = () => dayjs().startOf('day');
    const lastYear = dayjs().subtract(1, 'year');
    return {
      [t('general.calendar.rangePresets.today')]: [now(), now()],
      [t('general.calendar.rangePresets.30days')]: [now().add(-30, 'd'), now()],
      [t('general.calendar.rangePresets.60days')]: [now().add(-60, 'd'), now()],
      [t('general.calendar.rangePresets.90days')]: [now().add(-90, 'd'), now()],
      [t('general.calendar.rangePresets.thisYear')]: [
        now().startOf('year'),
        now().endOf('year'),
      ],
      [t('general.calendar.rangePresets.lastYear')]: [
        lastYear.startOf('year'),
        lastYear.endOf('year'),
      ],
    };
  };

  return (
    <>
      <CmsTooltip toolTipKey="dossier.label.dateRange">
        <RangePicker
          allowEmpty={[true, true]}
          size="middle"
          picker="date"
          format={dayjs.Ls.nl.formats.L}
          ranges={ranges()}
          value={[
            filterOptions?.from
              ? moment(filterOptions?.from?.toString())
              : null,
            filterOptions?.to
              ? moment(filterOptions?.to?.toString())
              : null,
          ]}
          onChange={(e) => {
            const fromDate = e?.[0]?.toISOString();
            const toDate = e?.[1]?.toISOString();

            const from = fromDate
              ? dayjs(fromDate).startOf('day')
              : undefined;
            const to = toDate ? dayjs(toDate).endOf('day') : undefined;
            setFilterOptions((prev) => ({ ...prev, page: 1, from, to }));
          }}
        />
      </CmsTooltip>
    </>
  );
};