import { createRelationAdministration } from '@/lib/adapters/lyanthe-adapter';
import { postRelation } from '@/lib/adapters/users-adapter';
import { CustomButton } from '@/lib/components';
import { AdministrationIdentification } from '@/lib/types';
import { Relation } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Modal, Steps } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Afas } from './Steps/afas';
import { Extra } from './Steps/extra';
import { Identifier } from './Steps/identifier';
import { Lyanthe } from './Steps/lyanthe';
import { Summary } from './Steps/summary';
import { ExtraData } from './Steps/types';

const { Step } = Steps;

export const AddRelation: FC<{ fetchData: () => void }> = ({ fetchData }) => {
  const { t } = useTranslation('management');

  const [isCreating, setIsCreating] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [form] = Form.useForm();

  const [afasRelation, setAfasRelation] = useState<Relation>();
  const [lyantheRelation, setLyantheRelation] =
    useState<AdministrationIdentification>();
  const [identifier, setIdentifier] = useState<string>();
  const [extraData, setextraData] = useState<ExtraData>();

  const resetState = () => {
    setCurrentPage(0);
    setAfasRelation(undefined);
    setLyantheRelation(undefined);
    setextraData(undefined);
    setIdentifier(undefined);
    setVisible(false);
  };

  const createRelation = async (newRelation: Relation) => {
    try {
      setIsCreating(true);
      if (!lyantheRelation || !lyantheRelation.administrationId) {
        showNotification('error', 'Fout tijdens aanmaken relatie.');
        return;
      }

      const createdRelation = await postRelation(newRelation);

      await createRelationAdministration({
        administrationId: lyantheRelation.administrationId,
        relationId: createdRelation.id,
        cocNumber: lyantheRelation.cocNumber,
        vatNumber: lyantheRelation.vatNumber,
        thirdPartyIdentifier: lyantheRelation.thirdPartyIdentifier,
      });

      resetState();
      fetchData();
      showNotification('success', `Relatie ${newRelation.name} aangemaakt`);
    } catch (e: any) {
      const errorText =
        e.statusCode === 409 ? e.message : 'Fout tijdens aanmaken relatie.';
      showNotification('error', errorText);
    } finally {
      setIsCreating(false);
    }
  };

  const StepPropsObj = {
    form,
    setCurrentPage,
    identifier,
    isCreating,
  };
  const FormSteps = [
    {
      title: t('relations.addRelation.steps.identifier.title'),
      content: <Identifier setIdentifier={setIdentifier} {...StepPropsObj} />,
    },
    {
      title: t('relations.addRelation.steps.afas.title'),
      content: (
        <Afas
          {...StepPropsObj}
          afasRelation={afasRelation}
          setAfasRelation={setAfasRelation}
        />
      ),
    },
    {
      title: t('relations.addRelation.steps.lyanthe.title'),
      content: (
        <Lyanthe
          {...StepPropsObj}
          lyantheRelation={lyantheRelation}
          setLyantheRelation={setLyantheRelation}
        />
      ),
    },
    {
      title: t('relations.addRelation.steps.extra.title'),
      content: (
        <Extra
          {...StepPropsObj}
          extraData={extraData}
          setExtraData={setextraData}
        />
      ),
    },
    {
      title: t('relations.addRelation.steps.summary.title'),
      content: (
        <Summary
          {...StepPropsObj}
          afasRelation={afasRelation}
          lyantheRelation={lyantheRelation}
          extraData={extraData}
          submitCreate={createRelation}
        />
      ),
    },
  ];

  return (
    <>
      <CustomButton
        type="primary"
        icon={<FontAwesomeIcon icon="plus" />}
        onClick={() => setVisible(true)}
        toolTipKey="relations.action.add"
      >
        {t('relations.actions.addRelation')}
      </CustomButton>
      <Modal
        open={isVisible}
        footer={false}
        title={t('relations.addRelation.title')}
        onCancel={resetState}
        width={700}
      >
        <Steps
          current={currentPage}
          labelPlacement="vertical"
          style={{ marginBottom: '2rem' }}
        >
          {FormSteps.map((step) => (
            <Step title={step.title} key={step.title} />
          ))}
        </Steps>
        <div className="text-center">{FormSteps[currentPage].content}</div>
      </Modal>
    </>
  );
};
