import { Accent } from '@/lib/components';
import { InvoiceDetails } from '@/lib/types';
import { Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { InvoiceDetailsTable } from './invoiceDetailsTable';
import { InvoiceProductTable } from './invoiceProductTable';
import { InvoiceRelationDescription } from './invoiceRelationDescriptions';
import { InvoiceTotalTable } from './invoiceTotalTable';
import { InvoiceVatTable } from './invoiceVatTable';

export const InvoiceRender = ({ invoice }: { invoice: InvoiceDetails }) => {
  const { t } = useTranslation();
  return (
    <div style={{ overflowX: 'auto' }}>
      <Space direction="vertical">
        <Row justify="space-around">
          <Col span={12} style={{ textAlign: 'left' }}>
            <Accent type="h2">{t('renders.invoice.customer')}</Accent>
            <InvoiceRelationDescription invoiceRelation={invoice.customer} />
          </Col>
          <Col span={12} style={{ textAlign: 'left' }}>
            <Accent type="h2">{t('renders.invoice.supplier')}</Accent>
            <InvoiceRelationDescription invoiceRelation={invoice.supplier} />
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <InvoiceDetailsTable invoice={[invoice]} />
          </Col>
        </Row>
        <Row justify="start">
          <Col>
            <Accent type="h2">{t('renders.invoice.products')}</Accent>
          </Col>
          <Col span={24}>
            <InvoiceProductTable deliveries={invoice.deliveries} />
          </Col>
        </Row>

        <Row justify="start">
          <Col>
            <Accent type="h2">{t('renders.invoice.totals')}</Accent>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={6}>
                <InvoiceVatTable invoiceVats={invoice.vats} />
              </Col>
              <Col span={4}>
                <InvoiceTotalTable totals={invoice.totals} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Space>
    </div>
  );
};
