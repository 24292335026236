import { CustomColumnType, CustomTable } from '@/lib/components';
import { Relation, RelationFilter, RelationIdentifier } from '@/lib/types/user';
import { useTranslation } from 'react-i18next';

import { IdentifierCategory } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import styles from './styles.module.scss';

const defaultColumnSettings: CustomColumnType<Relation> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

interface IRelationsTable {
  total: number;
  relations: Relation[];
  onRowClick?: (relation: Relation) => void;
  tableSettings: RelationFilter;
  setTableSettings: (RelationFilter: RelationFilter) => void;
  isLoading: boolean;
  renderActions?: (relation: Relation) => React.ReactNode;
  columnSettings?: CustomColumnType<Relation>;
  renderIcons?: (relation) => React.ReactNode;
}
export const RelationsTable = ({
  relations,
  onRowClick,
  total,
  tableSettings,
  setTableSettings,
  isLoading,
  renderActions,
  columnSettings = defaultColumnSettings,
  renderIcons,
}: IRelationsTable) => {
  const { t } = useTranslation();

  const tableColumns: CustomColumnType<Relation>[] = [
    {
      title: '',
      dataIndex: 'id',
      render: (_, relation) => (
        <Space>
          {relation.isFrozen && <FontAwesomeIcon icon="lock" />}
          {relation.isBlocked && <FontAwesomeIcon icon="users-slash" />}
          {renderIcons?.(relation)}
        </Space>
      ),
    },
    {
      onFilter: (value, relation: Relation) =>
        relation.identifiers
          ?.find(
            (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
          )
          ?.identifier.includes(value as string) || false,
      ...columnSettings,
      title: t('relation.customerNumber'),
      key: 'customerNumber',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier,
    },
    {
      ...columnSettings,
      title: t('relation.name'),
      dataIndex: 'name',
    },
    {
      ...columnSettings,
      title: t('relation.address'),
      dataIndex: 'street',
      render: (value, record) =>
        `${record.street || ''} ${record.houseNumber || ''}`,
    },
    {
      ...columnSettings,
      title: t('relation.zipcode'),
      dataIndex: 'zipcode',
    },
    {
      ...columnSettings,
      title: t('relation.city'),
      dataIndex: 'city',
    },
    {
      ...columnSettings,
      title: t('relation.country'),
      dataIndex: 'country',
    },
    {
      ...columnSettings,
      title: t('relation.mailbox'),
      dataIndex: 'mailbox',
      width: 250,
    },
    {
      onFilter: (value, relation: Relation) =>
        relation.identifiers
          ?.find((identifier) => identifier.category === IdentifierCategory.COC)
          ?.identifier.includes(value as string) || false,
      ...columnSettings,
      title: t('relation.coc'),
      key: 'coc',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (indentifier) => indentifier.category === IdentifierCategory.COC,
        )?.identifier,
    },
    {
      onFilter: (value, relation: Relation) =>
        relation.identifiers
          ?.find((identifier) => identifier.category === IdentifierCategory.VAT)
          ?.identifier.includes(value as string) || false,
      ...columnSettings,
      title: t('relation.vat'),
      key: 'vat',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (indentifier) => indentifier.category === IdentifierCategory.VAT,
        )?.identifier,
    },
  ];

  if (renderActions) {
    tableColumns.push({
      title: '',
      align: 'right',
      render: (_, record) => renderActions(record),
      enableDefaultSearch: false,
    });
  }

  const onTableChange = async (pagination, filters, sorter) => {
    const filter: RelationFilter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'name',
    );

    filter.cocNumber = filters.coc?.[0] || '';
    filter.vatNumber = filters.vat?.[0] || '';
    filter.customerNumber = filters.customerNumber?.[0] || '';

    setTableSettings?.({ ...tableSettings, ...filter });
  };

  return (
    <CustomTable
      rowKey="id"
      loading={isLoading}
      columns={tableColumns}
      dataSource={relations}
      onChange={onTableChange}
      pagination={{
        current: tableSettings.page,
        pageSize: tableSettings.pageSize,
        hideOnSinglePage: true,
        total: total,
        onChange: () => {},
      }}
      onRow={(record) => {
        return {
          onClick: () => onRowClick?.(record),
        };
      }}
      rowClassName={onRowClick ? styles.selectableRow : ''}
      size="small"
    />
  );
};
