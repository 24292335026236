import { CustomColumnType, CustomTable } from '@/lib/components';
import { FilterBase, PagedResponse, SortOrder } from '@/lib/types';
import { getFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { useEffect, useState } from 'react';

interface IAgrinotaImportDetailsTable<T> {
  tableColumns: CustomColumnType<T>[];
  fetchFunction: (tableSetting: FilterBase) => Promise<PagedResponse<T[]>>;
}

export const AgrinotaImportDetailsTable = <T extends unknown>({
  tableColumns,
  fetchFunction,
}: IAgrinotaImportDetailsTable<T>) => {
  const [tableSettings, setTableSettings] = useState<FilterBase>({
    page: 1,
    pageSize: 10,
    orderField: '',
    order: SortOrder.Descending,
    exactMatch: false,
  });

  const [isLoading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<T[]>();

  const onTableChange = async (pagination, filters, sorter) => {
    const filter = getFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'deliveryDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await fetchFunction(tableSettings);
        setTotal(response.total);
        setData(response.data);
      } catch (e) {
        showNotification('error', 'Could not get details');
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [fetchFunction, tableSettings]);

  return (
    <CustomTable
      rowKey="id"
      style={{ marginTop: '2rem' }}
      loading={isLoading}
      columns={tableColumns}
      dataSource={data}
      onChange={onTableChange}
      pagination={{
        current: tableSettings.page,
        pageSize: tableSettings.pageSize,
        hideOnSinglePage: true,
        total: total,
        onChange: () => {},
      }}
      size="small"
    />
  );
};
