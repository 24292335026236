import {
  InvoiceStatusReason,
  InvoiceStatusReasonModifyRequest,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['invoicestatusreasons'];
const BASE_URL = `${apiBasePaths.notifications}/v2/invoicestatusreasons`;

export const useGetInvoiceStatusReasons = (): UseQueryResult<
  InvoiceStatusReason[],
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => api.get(`${BASE_URL}`).then((r) => r.data),
  });

export const useCreateInvoiceStatusReason = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: InvoiceStatusReasonModifyRequest) =>
      api.post(`${BASE_URL}`, request).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};

export const useUpdateInvoiceStatusReason = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      request,
    }: {
      id: number;
      request: InvoiceStatusReasonModifyRequest;
    }) => api.put(`${BASE_URL}/${id}`, request).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};

export const useDeleteInvoiceStatusReason = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`${BASE_URL}/${id}`).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};
