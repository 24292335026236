import { api } from '@/lib/utils/api.axios';
import {
  DefinedUseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { SupportTicket, SupportTicketModifyRequest } from '@/lib/types/supportTicket';
import { apiBasePaths } from '../_apiPaths';
import { PagedResponse, SupportTicketFilter } from '@/lib/types';
import { createFilterParams } from '@/lib/utils/helpers';

const QUERY_KEY = ['user', 'support'];
const BASE_URL = `${apiBasePaths.user}/v2/support`;

export const useGetSupportTickets = (params: SupportTicketFilter): DefinedUseQueryResult<PagedResponse<SupportTicket[]>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    enabled: !!params,
    queryFn: () =>
      api
        .get<PagedResponse<SupportTicket[]>>(`${BASE_URL}`, {
          params: createFilterParams({
            ...params,
          }),
          timeout: 60000,
        })
        .then((r) => r.data),
    initialData: {
      page: 1,
      pageSize: 10,
      total: 0,
      data: [],
    },
  });

export const useCreateSupportTicket = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: SupportTicketModifyRequest) =>
      api.post(`${BASE_URL}`, request).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};
