import { getVerifyDetails, verifyUser } from '@/lib/adapters/users-adapter';
import { keycloakInstanceAtom } from '@/lib/atoms/atoms';
import { Modal, Row, Spin } from 'antd';
import { useAtomValue } from 'jotai/utils';

import { CustomButton } from '@/lib/components';
import { UserVerifyDetails, UserVerifyRequest } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VerifyUser } from './verifyUser';

export const UserVerification = () => {
  const { t } = useTranslation();

  const keycloak = useAtomValue(keycloakInstanceAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<
    UserVerifyDetails | undefined
  >();

  const refreshUserDetails = async () => {
    setIsLoading(true);
    try {
      const verifyDetails = await getVerifyDetails();
      setUserDetails(verifyDetails);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshUserDetails();
  }, []);

  const verify = async () => {
    if (!userDetails?.relation) return;

    setIsLoading(true);
    try {
      const data: UserVerifyRequest = {
        username: userDetails.username,
        relationId: userDetails.relation.id,
      };

      const response = await verifyUser(data);
      if (!response.isVerified) {
        throw new Error('Verification error');
      }
      if (response.reloginRequired) {
        // Simple reload should request a new token
        window.location.reload();
      }
    } catch (e) {
      showNotification('error', t('userverification.errors.unableToVerify'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={true} closable={false} footer={null}>
      <Spin spinning={isLoading}>
        <VerifyUser
          username={userDetails?.username}
          relationName={userDetails?.relation?.name}
          locatioName={userDetails?.location?.name}
        />
        <Row justify="space-between" gutter={10}>
          <CustomButton onClick={() => keycloak.logout()}>
            {t('action.logout')}
          </CustomButton>
          <CustomButton
            disabled={!userDetails?.verificationPossible}
            color="primary"
            onClick={verify}
            toolTipKey="verification.action.verify"
          >
            {t('profile.verify')}
          </CustomButton>
        </Row>
      </Spin>
    </Modal>
  );
};
