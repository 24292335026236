import { FC, useEffect, useState } from 'react';

type ImageViewProps = {
  url: string;
  onErrorComponent: React.ReactNode;
};

export const ImageView: FC<ImageViewProps> = ({ url, onErrorComponent }) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then(() => {
          setIsError(false);
        })
        .catch((e) => {
          setIsError(true);
        });
    } else {
      setIsError(true);
    }
  }, [url]);

  return (
    <>{isError ? onErrorComponent : <img src={url} alt="Document preview" onError={() => setIsError(true)} />}</>
  );
};
