import { Tooltip, TooltipProps } from 'antd';
import { FC } from 'react';

type ConditionalTooltipProps = {
  isShown: boolean;
} & TooltipProps;

export const ConditionalTooltip: FC<ConditionalTooltipProps> = ({
  isShown,
  children,
  ...props
}) => {
  return isShown ? (
    <Tooltip placement="bottom" {...props}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
