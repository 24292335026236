import React from 'react';
import { useFeature } from './useFeature';

interface FeatureProps {
  flag: string;
  inverse?: boolean;
  enabled: React.ReactNode;
  disabled?: React.ReactNode;
}

export const Feature: React.FunctionComponent<FeatureProps> = ({
  flag,
  inverse,
  enabled,
  disabled,
}) => {
  let isEnabled = useFeature(flag);
  if (inverse) {
    isEnabled = !isEnabled;
  }

  if (isEnabled) {
    return <>{enabled}</>;
  }

  return <>{disabled}</>;
};
