import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CmsTooltip } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Layout, Menu, MenuProps } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import i18next from 'i18next';
import { useAtomValue } from 'jotai/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { ContentPanel } from '../../lib/components/content-panel/contentPanel';

type MenuItem = Required<MenuProps>['items'][number];

const pathToLabel = (path: string) =>
  `pages${path.toLowerCase().replaceAll('/', '.')}`;

const getItem = (
  path: string,
  cmsTooltip?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: string,
): MenuItem => {
  const labelText = i18next.t(`${pathToLabel(path)}.title`);
  const label = cmsTooltip ? (
    <CmsTooltip toolTipKey={cmsTooltip}>{labelText}</CmsTooltip>
  ) : (
    labelText
  );

  return {
    label,
    key: path,
    icon,
    children,
    type,
  } as MenuItem;
};

export const SettingsLayout = () => {
  const navigate = useNavigate();
  const match = useMatch('/settings');
  const location = useLocation();

  const { t } = useTranslation();

  const { relation, isFrozen } = useRelationContext();
  const hasAccess = useAtomValue(hasAccessAtom);

  useEffect(() => {
    if (match?.pathname === '/settings') {
      if (hasAccess.isAdmin) {
        navigate('/settings/application-settings');
      } else {
        navigate('/settings/notifications');
      }
    }
  }, [match]);

  const menuItems: MenuItem[] = [];

  if (hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) {
    const children: MenuItem[] = [
      getItem('/settings/documents', 'settingsMyNota.label.otherDocuments'),
    ];

    if (!isFrozen) {
      children.push(
        getItem(
          '/settings/notifications',
          'settingsMyNota.label.notifications',
        ),
        getItem('/settings/bankaccount', 'settingsMyNota.label.payment'),
        getItem('/settings/suppliers', 'settingsMyNota.label.suppliers'),
        getItem('/settings/company', 'settingsMyNota.label.company'),
        getItem('/settings/support', 'settingsMyNota.label.support'),
      );
    }

    menuItems.push({
      type: 'group',
      label: t('settings.userSettings'),
      key: 'userSettings',
      children: [...children, { type: 'divider' }],
    });
  }

  if (hasAccess.isAdmin) {
    const notificationTriggerChildren: MenuItem[] = [
      getItem('/settings/triggers/nofilesuploaded'),
      getItem('/settings/singletasks/notifyusers'),
    ];

    const datamanagementChildren: MenuItem[] = [
      getItem('/settings/application-settings'),
      getItem('/settings/relations'),
      getItem('/settings/users'),
      getItem('/settings/rejectedinvoices'),
      getItem('/settings/invoiceswithouthrelation'),
      getItem('/settings/invoice-overview'),
      getItem('/settings/processdata'),
      getItem('/settings/lyanthedata'),
      getItem('/settings/bulkarchive'),
      getItem('/settings/merge'),
      getItem('/settings/labels'),
    ];

    const agrinotaChildren: MenuItem[] = [
      getItem('/settings/agrinotaimport'),
      getItem('/settings/agrinotaimport/overview'),
    ];

    menuItems.push(
      {
        type: 'group',
        label: t('settings.notificationTriggerSettings'),
        key: 'notificationTriggerSettings',
        children: [...notificationTriggerChildren, { type: 'divider' }],
      },
      {
        type: 'group',
        label: t('settings.datamanagement'),
        key: 'datamanagement',
        children: [...datamanagementChildren, { type: 'divider' }],
      },
      {
        type: 'group',
        label: t('settings.agrinotaImport'),
        key: 'agrinotaImport',
        children: [...agrinotaChildren],
      },
    );
  }
  return (
    <Layout
      hasSider
      style={{ padding: '0 16px', backgroundColor: 'transparent' }}
    >
      <Sider
        theme="light"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Menu
          mode="inline"
          theme="light"
          style={{ background: 'transparent' }}
          activeKey={location.pathname}
          selectedKeys={[location.pathname]}
          items={menuItems}
          onSelect={(e) => navigate(e.key)}
        />
      </Sider>
      <Content>
        <ContentPanel
          title={t(`${pathToLabel(location.pathname)}.title`, '')}
          description={t(`${pathToLabel(location.pathname)}.description`, '')}
        >
          <Outlet />
        </ContentPanel>
      </Content>
    </Layout>
  );
};
