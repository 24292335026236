import { Accent, CustomButton } from '@/lib/components';
import { Form, Input } from 'antd';
import { FC } from 'react';

interface IIdentifier {
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  setIdentifier?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const Identifier: FC<IIdentifier> = ({
  setCurrentPage,
  setIdentifier,
}) => {
  const goNext = (values) => {
    setIdentifier?.(values.identifier);
    setCurrentPage?.((prev) => prev + 1);
  };

  return (
    <>
      <Accent type="h1">KvK nummer</Accent>
      <Form name="identifier" onFinish={goNext}>
        <Form.Item
          name="identifier"
          rules={[{ required: true, min: 8, max: 8 }]}
        >
          <Input size="large" placeholder={'Vul een KVK in'} />
        </Form.Item>
        <CustomButton htmlType="submit" type="primary">
          Zoek Agrinota Relatie
        </CustomButton>
      </Form>
    </>
  );
};
