export interface ScopeItem {
  id: number | string;
  scopeId: string;
  status?: string;
  statusDescription?: string;
  created: Date;
  updated: Date;
  deleted?: Date;
  fileType: string | number;
}

export interface ActionScopeItem extends ScopeItem {
  action?: ScopeItemAction;
}

export enum ScopeItemAction {
  Updated,
  Deleted,
}

export enum FileType {
  IncomingFile,
  LyantheFile,
  ProcessFile,
  Invoice,
}
