import { CustomButton } from '@/lib/components';
import { useGetProcessFileUbl } from '@/lib/queries';
import { downloadUrl } from '@/lib/utils/download-file';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UblDownloadButtonProps {
  processFileId: string;
}

export const UblDownloadButton: React.FunctionComponent<
  UblDownloadButtonProps
> = ({ processFileId }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetProcessFileUbl(processFileId);

  const downloadFile = async () => {
    try {
      if (!data?.blobStorageUri) {
        throw new Error('no url');
      }
      await axios.get(data.blobStorageUri);
      downloadUrl(data.blobStorageUri, '');
    } catch (error) {
      console.log(error);
      showNotification('error', t('Error.FilesNotFound'));
    }
  };
  return (
    <CustomButton
      type="link"
      shape="circle"
      loading={isLoading}
      icon={<FontAwesomeIcon icon={['fas', 'file-code']} color="black" />}
      toolTipKey="document.action.download-ubl"
      onClick={downloadFile}
    />
  );
};
