import { ContentPanel } from '@/lib/components/content-panel/contentPanel';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const DocumentsLayout = () => {
  const { t } = useTranslation();
  return (
    <ContentPanel title={t(`nav.documents`, '')} style={{ margin: '0px 16px' }}>
      <Outlet />
    </ContentPanel>
  );
};
