import { InfoButtonRequest } from '@/lib/types/cms';
import { cmsApi } from '@/lib/utils/api.axios';

export const getInfoButtons = () =>
  cmsApi
    .post<InfoButtonRequest>('', {
      query: `
      query infoButtons {
        infoButtons{
          key
          value
        }
      }
    `,
    })
    .then((r) => r.data.data.infoButtons);
