import { RelationInvoiceStatistics } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.invoices}/v1/management`;

export const bulkArchive = (request: { year: number; locationId: string }) =>
  api.post(`${BASE_URL}/invoices/archive`, request).then((r) => r.data);

export const requestInvoiceMigration = (request: {
  oldOwnerId: string;
  newOwnerId: string;
  includeDocuments: boolean;
}) => api.post(`${BASE_URL}/invoicemigrations`, request).then((r) => r.data);

export const getRelationInvoiceStatistics = (params: { relationId: string }) =>
  api
    .get<RelationInvoiceStatistics>(`${BASE_URL}/statistics/relationinvoices`, {
      params,
      headers: {
        // Workaround - as it is required to execute as a user.
        onBehalfOfRelation: params.relationId,
      },
    })
    .then((r) => r.data);
