import i18next from 'i18next';

export const createRelationIdentifierValidator = ({
  required,
}: {
  required: boolean;
}) => {
  return () => ({
    validator(_, value) {
      if (!value && required) {
        return Promise.reject(
          new Error(i18next.t('validation.general.required', { ns: 'errors' })),
        );
      }

      // Minimum length is 8 for default identifiers except "RelatieOnbekend" so we should allow length of 4
      if (value.length < 8 && value.length !== 4) {
        return Promise.reject(
          new Error(
            i18next.t('validation.identifiers.invalidLength', { ns: 'errors' }),
          ),
        );
      }

      return Promise.resolve();
    },
  });
};
