import { Bank } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['payment', 'banks'];
const BASE_URL = `${apiBasePaths.payments}/v1/banks`;

export const useGetBankByIban = (
  iban: string,
): UseQueryResult<Bank | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'iban', iban],
    queryFn: () =>
      api.get<Bank>(`${BASE_URL}/iban/${iban}`).then((r) => r.data),
    enabled: !!iban && iban.length >= 8,
  });

export const useGetAllBanks = (): UseQueryResult<Bank[] | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => api.get<Bank>(`${BASE_URL}`).then((r) => r.data),
  });
