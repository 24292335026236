import { getLocations } from '@/lib/adapters/users-adapter';
import { CustomButton } from '@/lib/components';
import { LocationProps } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { DatePicker, Form, Popconfirm, Select, Spin } from 'antd';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bulkArchive } from '../../../lib/adapters/invoice-management-adapter';

export const BulkArchive = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [locations, setLocations] = useState<LocationProps[]>([]);

  const fetchData = async () => {
    setLoading(true);
    await getLocations().then((r) => setLocations(r));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    const yearDate: Dayjs = values.year;
    setSubmitting(true);
    try {
      await bulkArchive({
        year: yearDate.year(),
        locationId: values.locationId,
      });
      showNotification(
        'success',
        t('settings.tabs.bulkarchive.archiveStarted'),
      );
      form.resetFields();
    } catch {
      showNotification('error', t('settings.tabs.bulkarchive.archiveError'));
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label={t('settings.tabs.bulkarchive.year')}
            name="year"
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '300px' }} picker="year" />
          </Form.Item>
          <Form.Item
            label={t('settings.tabs.bulkarchive.location')}
            name="locationId"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '300px' }}
              showSearch
              optionFilterProp="label"
              options={locations.map((location) => ({
                label: location.name,
                value: location.id,
              }))}
            />
          </Form.Item>
          <Popconfirm
            title={t('payment.sepaConfirm')}
            onConfirm={() => form.submit()}
            placement="topRight"
          >
            <CustomButton
              loading={submitting}
              htmlType="submit"
              type="primary"
              toolTipKey="bulkarchive.action.submit"
            >
              {t('settings.tabs.bulkarchive.submit')}
            </CustomButton>
          </Popconfirm>
        </Form>
      </Spin>
    </>
  );
};
