import {
  getNotifications,
  markAsSeenBasedOnFilter,
  patchNotification,
} from '@/lib/adapters/notification-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Accent, CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import {
  Notification,
  NotificationLevel,
  PagedNotificationsResult,
  PaginationParams,
} from '@/lib/types';
import { convertEnumToArray } from '@/lib/utils/helpers';
import { List, Row, Select, Tabs } from 'antd';
import { useAtomValue } from 'jotai/utils';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollList } from '../../lib/components/scroll-list/scrollList';
import { NotificationItem } from './notificationItem';
interface NotificationListProps {
  category?: string;
  style?: React.CSSProperties;
  onChangeNotify?: () => void;
}

export const NotificationList: FC<NotificationListProps> = ({
  category,
  style,
  onChangeNotify,
}) => {
  const { relation, isFrozen } = useRelationContext();
  const hasAccess = useAtomValue(hasAccessAtom);
  const { t } = useTranslation();
  const [activeMode, setActiveMode] = useState<'all' | 'unread'>('unread');
  const [activeLevel, setActiveLevel] = useState<number>();
  const [loading, setLoading] = useState<boolean>(); // used for select element

  const [pagination, setPagination] = useState<PaginationParams>();
  const [items, setItems] = useState<Notification[]>([]);

  useEffect(() => {
    fetchNotifications();
  }, [activeMode, activeLevel]);

  //initial fetch
  const fetchNotifications = async () => {
    await getNotifications({
      includeSeen: activeMode === 'all',
      page: 1,
      category,
      level: activeLevel,
    }).then((r) => {
      setPagination(r);
      setItems(r.data);
    });
  };

  const onTabChange = (activeKey: string) => {
    setItems([]);
    setActiveMode(activeKey as any);
  };

  const handleSeen = async (id: number) => {
    try {
      await patchNotification(id, true);

      if (activeMode === 'all') {
        //only change marking, since read messages are shown
        setItems(
          items!.map((n) => (n.id === id ? { ...n, isMarkedAsSeen: true } : n)),
        );
      } else {
        //remove read messages from items
        setItems(items!.filter((n) => n.id !== id));
      }

      onChangeNotify?.();
    } catch (e) {
      console.error('Could not patch notification');
    }
  };

  const handleSeenAll = async () => {
    try {
      await markAsSeenBasedOnFilter({
        category,
        level: activeLevel,
      });

      await fetchNotifications();
      onChangeNotify?.();
    } catch (e) {
      console.error('Could not patch notifications');
    }
  };

  const loadMore: (number: number) => Promise<void> = async (page: number) => {
    try {
      let res = await load(page, activeMode, activeLevel);
      if (!res) return;
      setLoading(false);
      setPagination(res);
      setItems([...items, ...res.data]);
    } catch (e) {
      console.error(e);
    }
  };

  const load: (
    page: number,
    mode: 'all' | 'unread',
    activeLevel?: number,
  ) => Promise<PagedNotificationsResult | null> = async (
    page: number,
    mode: 'all' | 'unread',
    activeLevel?: number,
  ) => {
    setLoading(true);
    const newNotifications = await getNotifications({
      includeSeen: mode === 'all',
      page: page,
      category,
      level: activeLevel,
    });
    return newNotifications;
  };

  const TabAction = () => {
    const enumList = convertEnumToArray(NotificationLevel);
    return (
      <Select
        onChange={setActiveLevel}
        onClear={() => setActiveLevel(undefined)}
        placeholder="Kies een niveau"
        allowClear
        style={{ width: 200 }}
        value={activeLevel}
        loading={loading}
      >
        {enumList.map((level) => (
          <Select.Option key={level.key} value={level.key}>
            {level.value}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const scrollList = () => (
    <ScrollList pagination={pagination} loadMore={loadMore}>
      <List>
        {items?.map((item) => (
          <NotificationItem
            key={item.id}
            notification={item}
            handleSeen={handleSeen}
          />
        ))}
      </List>
    </ScrollList>
  );

  return (
    <div className="container" style={style}>
      <Row justify="space-between">
        <Accent type="h2">{t(`notifications.title${category || ''}`)}</Accent>
        <CustomButton
          onClick={handleSeenAll}
          type="link"
          disabled={
            !(hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) ||
            isFrozen
          }
          color="primary"
          shape="round"
          toolTipKey="notifications.action.markAllAsRead"
        >
          {t('notifications.clearBtn')}
        </CustomButton>
      </Row>
      <Tabs
        onChange={onTabChange}
        tabBarExtraContent={{ right: <TabAction /> }}
        items={[
          {
            label: 'Ongelezen',
            key: 'unread',
            children: scrollList(),
          },
          {
            label: 'Alles',
            key: 'all',
            children: scrollList(),
          },
        ]}
      />
    </div>
  );
};
