import { Accent } from '@/lib/components';
import { InvoiceRelation } from '@/lib/types';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

export const InvoiceRelationDescription = ({
  invoiceRelation,
}: {
  invoiceRelation: InvoiceRelation;
}) => {
  const { t } = useTranslation();

  const labels = {
    kvk: t('renders.invoice.relation.coc'),
    vat: t('renders.invoice.relation.vat'),
  };
  const type = invoiceRelation.identifierType?.split(':');
  const label = type?.length > 0 ? labels[type[1].toLowerCase()] : null;
  return (
    <Descriptions
      style={{ textAlign: 'left' }}
      layout="horizontal"
      column={1}
      size="small"
    >
      <Descriptions.Item label={t('renders.invoice.relation.name')}>
        <Accent type="strong">{invoiceRelation.name}</Accent>
      </Descriptions.Item>
      <Descriptions.Item label={t('renders.invoice.relation.address')}>
        <Accent type="strong">
          {`${invoiceRelation.address?.street || ''}${invoiceRelation.address?.houseNumber || ''
            }`}
        </Accent>
      </Descriptions.Item>
      <Descriptions.Item label={t('renders.invoice.relation.zipcode')}>
        <Accent type="strong">{invoiceRelation.address?.zipcode}</Accent>
      </Descriptions.Item>
      <Descriptions.Item label={t('renders.invoice.relation.city')}>
        <Accent type="strong">{invoiceRelation.address?.city}</Accent>
      </Descriptions.Item>
      <Descriptions.Item label={t('renders.invoice.relation.country')}>
        <Accent type="strong">{invoiceRelation.address?.zipcode}</Accent>
      </Descriptions.Item>
      {label && (
        <Descriptions.Item label={label}>
          <Accent type="strong">{invoiceRelation.identifier}</Accent>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};
