import { api } from '@/lib/utils/api.axios';
import { apiBasePaths } from '../queries';
import { BankAccount, InvoicePayment } from '../types';

const BASE_URL = `${apiBasePaths.payments}/v1`;

export const createSepaPayment = (data) =>
  api.post(`${BASE_URL}/payment`, data).then((r) => r.data);

export const postBankAccount = (values: BankAccount) =>
  api.post<BankAccount>(`${BASE_URL}/bankaccounts`, values).then((r) => r.data);

export const putBankAccount = (id: number, values: BankAccount) =>
  api
    .put<BankAccount>(`${BASE_URL}/bankaccounts/${id}`, values)
    .then((r) => r.data);

export const deleteBankAccount = (id: number) =>
  api.delete<BankAccount>(`${BASE_URL}/bankaccounts/${id}`).then((r) => r.data);

export const getSepaFile = (id: number) =>
  api.get(`${BASE_URL}/payment/${id}/file`).then((r) => r.data);

export const getInvoicePayments = () =>
  api.get<InvoicePayment[]>(`${BASE_URL}/payment/invoices`).then((r) => r.data);

export const getInvoicePaymentsByInvoiceId = (id: number) =>
  api
    .get<InvoicePayment>(`${BASE_URL}/payment/invoices/${id}`)
    .then((r) => r.data);
