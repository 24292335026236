import { GenericModal } from '@/lib/components';
import { RejectFile, RejectModalState } from '@/lib/types/reject-invoice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransList } from '../core/translations/transList';
import { InvoiceRejectForm } from './invoiceRejectForm';

interface InvoiceRejectModalProps {
  modalState: RejectModalState;
  hideModal: () => void;
  rejectSingle: (
    item: RejectFile,
    reason: string,
    description?: string,
  ) => Promise<unknown> | void;

  rejectBatch?: (
    items: RejectFile[],
    reason: string,
    description?: string,
  ) => Promise<unknown> | void;
  singleFile?: RejectFile;
  multiFiles: RejectFile[];
}

export const InvoiceRejectModal: React.FunctionComponent<
  InvoiceRejectModalProps
> = ({
  modalState,
  hideModal,
  singleFile,
  multiFiles,
  rejectSingle,
  rejectBatch,
}) => {
  const { t } = useTranslation();

  const getRejectText = () => {
    if (modalState === 'single') {
      return t('processFile.actions.reject.single', {
        name: singleFile?.name,
      });
    } else if (modalState === 'multi') {
      if (multiFiles.length > 10) {
        return (
          <TransList
            i18nKey="processFile.actions.reject.contentOverflow"
            items={multiFiles.map((x) => x.name).slice(0, 10)}
            count={multiFiles.length - 10}
          />
        );
      }
      return (
        <TransList
          i18nKey="processFile.actions.reject.content"
          items={multiFiles.map((x) => x.name)}
          count={multiFiles.length}
        />
      );
    }
  };

  return (
    <GenericModal
      title={t('processFile.actions.reject.title')}
      isVisible={modalState !== 'hidden'}
      hideModal={hideModal}
      footer={false}
      destroyOnClose
    >
      <p>{getRejectText()}</p>
      <InvoiceRejectForm
        onFinish={(reason, description) =>
          modalState === 'single'
            ? rejectSingle(singleFile!, reason, description)
            : rejectBatch?.(multiFiles, reason, description)
        }
        onCancel={hideModal}
      />
    </GenericModal>
  );
};
