import { CustomButton, CustomColumnType, CustomTable } from '@/lib/components';
import { Label } from '@/lib/types/labels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLabels } from '../../../lib/adapters/label-adapter';

export const LabelManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, data } = useQuery({
    queryKey: ['labels'],
    queryFn: () => getLabels(),
    retry: false,
  });

  const tableColumns: CustomColumnType<Label>[] = [
    { title: t('settings.tabs.labelmanagement.columns.id'), dataIndex: 'id' },
    {
      title: t('settings.tabs.labelmanagement.columns.name'),
      dataIndex: 'name',
    },
    {
      title: t('settings.tabs.labelmanagement.columns.description'),
      dataIndex: 'description',
    },
    {
      title: t('settings.tabs.labelmanagement.columns.values'),
      dataIndex: 'valueCount',
    },
    {
      title: t('settings.tabs.labelmanagement.columns.created'),
      dataIndex: 'created',
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      enableDefaultSearch: false,
      enableDefaultSorter: false,
      render: (id, label) => (
        <>
          <CustomButton
            type="link"
            onClick={() => navigate(`${id}`)}
            icon={<FontAwesomeIcon icon="pencil-alt" />}
          />
        </>
      ),
    },
  ];

  return (
    <CustomTable loading={isLoading} columns={tableColumns} dataSource={data} />
  );
};
