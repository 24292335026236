import { hasAccessAtom } from '@/lib/atoms/atoms';
import { RelationBlockedResult } from '@/routes/_components/relationBlockedResult';
import { useAtomValue } from 'jotai/utils';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface BlockedWrapperProps extends PropsWithChildren {
  isBlocked?: boolean;
  isFrozen?: boolean;
}

export const BlockedWrapper: React.FunctionComponent<BlockedWrapperProps> = ({
  isBlocked,
  isFrozen,
  children,
}) => {
  const { t } = useTranslation();
  const hasAccess = useAtomValue(hasAccessAtom);

  if (isBlocked && !hasAccess.isAdmin) {
    return <RelationBlockedResult />;
  }

  return (
    <>
      {isFrozen && (
        <div className={styles.frozenIndicator}>
          {t('general.messages.frozen')}
        </div>
      )}
      {isBlocked && (
        <div className={styles.blockedIndicator}>
          {t('general.messages.blockedAdmin')}
        </div>
      )}
      {children}
    </>
  );
};
