import { currencyFormatter } from '@/lib/utils/formatters';
import { useTranslation } from 'react-i18next';

type CurrencyDisplayProps = {
  amount: number;
  currency?: string;
};

export const CurrencyDisplay = ({
  amount,
  currency = 'EUR',
}: CurrencyDisplayProps) => {
  const { i18n } = useTranslation();

  const language = i18n.language;
  return <span>{currencyFormatter(language, currency).format(amount)}</span>;
};
