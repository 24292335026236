import { api } from '@/lib/utils/api.axios';
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';
import { Payment, PaymentFilter } from '../../types/payment';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['payment', 'payment'];
const BASE_URL = `${apiBasePaths.payments}/v1/payment`;

export const useGetRenameableRelations = (): DefinedUseQueryResult<
  string[],
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'renameablerelations'],
    queryFn: () =>
      api.get<string[]>(`${BASE_URL}/renameablerelations`).then((r) => r.data),
    initialData: [],
  });

export const useGetPayments = (params?: PaymentFilter) =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () =>
      api
        .get<Payment[]>(BASE_URL, {
          params: params,
        })
        .then((r) => r.data),
  });
