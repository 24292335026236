import {
  getInvoiceDetails,
  getUnhandledInvoices,
  setBulkHandled,
} from '@/lib/adapters/invoice-adapter';
import { getInvoicePaymentsByInvoiceId } from '@/lib/adapters/payment-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton } from '@/lib/components';
import { Invoice, OnSuccessHandlers } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { useQuery } from '@tanstack/react-query';
import { Affix, Card, Col, Drawer, Grid, List, Popconfirm, Row } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { InvoiceDetail } from '../../lib/components/invoice-detail/invoiceDetail';
import { convertToExtendedInvoice } from '../../lib/utils/extendedInvoice';
import { InvoiceRow } from './_components/invoiceRow';
import styles from './styles.module.scss';
import { useGetIdentifierTypes } from '@/lib/queries';

const { useBreakpoint } = Grid;

export const Invoices: FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const { id: idString } = useParams<{ id: string }>();
  const id = parseInt(idString!);
  const { isAdmin } = useAtomValue(hasAccessAtom);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { xl } = useBreakpoint();

  const {
    refetch,
    isFetching,
    data: invoices,
  } = useQuery({
    queryKey: ['invoices', 'unhandled'],
    queryFn: () => getUnhandledInvoices().then((r) => r.data),
    refetchInterval: 300 * 1000,
    initialData: [],
  });

  const {
    data: identifierTypes,
  } = useGetIdentifierTypes();

  useEffect(() => {
    const openInvoice = (id: number) => {
      toggleDrawer();
      navigate(`/invoices/${id}`);
    };
    if (invoices.length) {
      openInvoice(invoices[0]?.invoiceId);
    }
  }, [invoices]);

  const {
    refetch: refetchActiveInvoice,
    isFetching: detailsIsFetching,
    data: activeInvoice,
  } = useQuery({
    queryKey: ['invoice', id],
    enabled: !!id,
    queryFn: () => getInvoiceDetails(id).then(convertToExtendedInvoice),
  });

  const { isFetching: paymentsIsFetching, data: activeInvoicePayments } =
    useQuery({
      queryKey: ['invoice', id, 'payments'],
      enabled: !!id,
      queryFn: () => getInvoicePaymentsByInvoiceId(id),
    });

  const refresh = () => {
    refetch();
    refetchActiveInvoice();
  };

  const toggleInvoiceSelect = (id: number, isSelected: boolean) => {
    setSelectedInvoices((current) => {
      const newSelection = [...current];
      if (isSelected) {
        newSelection.push(id);
        return newSelection;
      }

      return newSelection.filter((x) => x !== id);
    });
  };

  const toggleInvoiceSelectAll = () => {
    setSelectedInvoices((current) =>
      !current.length ? invoices.map((x) => x.invoiceId) : [],
    );
  };

  const handleSelected = async () => {
    try {
      setIsActionLoading(true);
      await setBulkHandled(selectedInvoices, true);
      setSelectedInvoices([]);
      refresh();
      showNotification('info', 'Facturen succesvol opgeschoond');
    } catch {
      showNotification(
        'error',
        'Fout tijdens opschonen facturen. Probeer opnieuw.',
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const moduleHandlers: OnSuccessHandlers = {
    onArchiveSuccess() {
      refresh();
    },
    onHandleSuccess() {
      refresh();
    },
    onAutoHandleSuccess() {
      refresh();
    },
    onAutoPaidSuccess() {
      refresh();
    },
    onPaymentSuccess() {
      refresh();
    },
    onDeleteSuccess() {
      refresh();
    },
    onCommentSuccess() {
      refresh();
    },
    onEditSuccess() {
      refresh();
    },
  };

  const toggleDrawer = () => {
    setDrawerVisible((prev) => !prev);
  };

  return (
    <div className={styles.invoiceContainer}>
      <Row>
        <Col span={24} xl={8}>
          <Row>
            {isAdmin && (
              <Row style={{ marginBottom: '8px' }} gutter={8}>
                <Col>
                  <CustomButton
                    onClick={() => toggleInvoiceSelectAll()}
                    toolTipKey="invoices.selectAll"
                  >
                    {!selectedInvoices.length
                      ? t('invoices.actions.selectAll')
                      : t('invoices.actions.deselectAll')}
                  </CustomButton>
                </Col>
                <Col>
                  <Popconfirm
                    title={t('invoices.actions.confirmHandle')}
                    onConfirm={() => handleSelected()}
                  >
                    <CustomButton
                      loading={isActionLoading}
                      color="primary"
                      disabled={!selectedInvoices.length}
                      toolTipKey="invoices.moveToDossier"
                    >
                      {t('invoices.actions.handle')}
                    </CustomButton>
                  </Popconfirm>
                </Col>
              </Row>
            )}
          </Row>
          <Row className={styles.invoiceRowContainer}>
            <List<Invoice>
              style={{ width: '100%' }}
              grid={{ column: 1 }}
              loading={isFetching}
              dataSource={invoices}
              renderItem={(invoice) => (
                <List.Item key={invoice.invoiceId}>
                  <InvoiceRow
                    moduleHandlers={moduleHandlers}
                    key={invoice.invoiceId}
                    invoice={invoice}
                    toggleDrawer={toggleDrawer}
                    isSelectable={isAdmin}
                    isSelected={selectedInvoices.includes(invoice.invoiceId)}
                    onSelect={toggleInvoiceSelect}
                  />
                </List.Item>
              )}
            />
          </Row>
        </Col>
        {xl ? (
          <Col xl={16}>
            <Affix offsetTop={100}>
              <Card
                bodyStyle={{
                  height: 'calc(100vh - 200px)',
                  overflowY: 'auto',
                  padding: '1.5rem',
                }}
                bordered={false}
              >
                <InvoiceDetail
                  identifierTypes={identifierTypes || []}
                  moduleHandlers={moduleHandlers}
                  showPdf
                  invoice={activeInvoice}
                  invoicePayment={activeInvoicePayments}
                  isLoading={
                    isFetching || detailsIsFetching || paymentsIsFetching
                  }
                />
              </Card>
            </Affix>
          </Col>
        ) : (
          <Drawer
            title="Details"
            placement={'right'}
            width={'90%'}
            onClose={() => {
              toggleDrawer();
              navigate('/invoices');
            }}
            open={drawerVisible}
            key={'right'}
          >
            <InvoiceDetail
              identifierTypes={identifierTypes || []}
              moduleHandlers={moduleHandlers}
              showPdf
              invoice={activeInvoice}
              invoicePayment={activeInvoicePayments}
              isLoading={isFetching || detailsIsFetching || paymentsIsFetching}
            />
          </Drawer>
        )}
      </Row>
    </div>
  );
};
