import { getSepaFile } from '@/lib/adapters/payment-adapter';
import {
  Accent,
  CurrencyDisplay,
  CustomButton,
  HeaderItem,
} from '@/lib/components';
import { PaymentEntry } from '@/lib/types';
import { downloadUrl } from '@/lib/utils/download-file';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaidRow } from './PaidRow';

interface PaidInvoiceEntriesProps {
  paymentEntries: PaymentEntry[];
}

export const PaidInvoiceEntries: FC<PaidInvoiceEntriesProps> = ({
  paymentEntries,
}) => {
  const { t } = useTranslation();

  const downloadSepa = async (paymentId: number) => {
    try {
      if (!paymentId) {
        throw new Error('Missing sepa payment id.');
      }

      const data = await getSepaFile(paymentId);
      downloadUrl(data.blobStorageUri, `sepa_${paymentId}.xml`);
    } catch (error) {
      console.log(error);
      showNotification('error', t('Error.FilesNotFound'));
    }
  };

  return (
    <>
      <Row>
        <Accent color="tertiary" inline>
          {t('label.payment', { count: paymentEntries.length })}
        </Accent>
      </Row>
      <PaidRow
        ibanSupplier={<HeaderItem textKey="payment.header.ibanSupplier" />}
        bicSupplier={<HeaderItem textKey="payment.header.bicSupplier" />}
        executionDate={<HeaderItem textKey="payment.header.executionDate" />}
        paidAmount={<HeaderItem textKey="payment.header.paidAmount" />}
        paymentId={<HeaderItem textKey="payment.header.sepa" />}
      />
      {paymentEntries.map((paymentEntry) => (
        <PaidRow
          key={paymentEntry.id}
          ibanSupplier={paymentEntry.receiverIban}
          bicSupplier={paymentEntry.receiverBic}
          executionDate={dateTimeFormatter.toDateOnly(
            paymentEntry.executionDate,
          )}
          paidAmount={<CurrencyDisplay amount={paymentEntry.amount} />}
          paymentId={
            <CustomButton
              size="small"
              type="text"
              toolTipKey="payment.action.downloadSepa"
              onClick={() => downloadSepa(paymentEntry.paymentId)}
            >
              <FontAwesomeIcon color="var(--secondary)" icon="file-download" />
            </CustomButton>
          }
        />
      ))}
    </>
  );
};
