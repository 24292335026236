import { getSepaFile } from '@/lib/adapters/payment-adapter';
import {
  Accent,
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import { NettingPaymentEntry } from '@/lib/types';
import { downloadUrl } from '@/lib/utils/download-file';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NettingInvoiceEntriesProps {
  nettingPaymentEntries: NettingPaymentEntry[];
}

export const NettingInvoiceEntries: FC<NettingInvoiceEntriesProps> = ({
  nettingPaymentEntries,
}) => {
  const { t } = useTranslation();

  const downloadSepa = async (paymentId: number) => {
    try {
      if (!paymentId) {
        throw new Error('Missing sepa payment id.');
      }

      const data = await getSepaFile(paymentId);
      downloadUrl(data.blobStorageUri, `sepa_${paymentId}.xml`);
    } catch (error) {
      console.log(error);
      showNotification('error', t('Error.FilesNotFound'));
    }
  };

  return (
    <>
      <Row>
        <Accent color="tertiary" inline>
          {t('label.creditPayment', { count: nettingPaymentEntries.length })}
        </Accent>
      </Row>

      {nettingPaymentEntries.map((paymentEntry) => (
        <Row key={paymentEntry.id} gutter={10}>
          <Col span={4}>{t('invoiceTypes.CreditNote')}</Col>
          <Col span={4}>
            <CurrencyDisplay amount={paymentEntry.invoiceAmount} />
          </Col>
          <Col span={12}>
            <DocumentViewModal
              id={paymentEntry.nettingInvoiceId.toString()}
              idType="invoice"
            />
            <CustomButton
              size="small"
              type="text"
              toolTipKey="payment.action.downloadSepa"
              onClick={() => downloadSepa(paymentEntry.paymentId)}
            >
              <FontAwesomeIcon color="var(--secondary)" icon="file-download" />
            </CustomButton>
          </Col>
        </Row>
      ))}
    </>
  );
};
