import { CmsTooltip } from '@/lib/components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FC } from 'react';
import styles from './styles.module.scss';

export type CustomButtonProps = ButtonProps & {
  toolTipKey?: string;
  iconOnly?: boolean;
  disableToolTip?: boolean;
}

export const CustomButton: FC<CustomButtonProps> = ({
  color = '',
  iconOnly = false,
  children,
  shape,
  toolTipKey,
  disableToolTip,
  ...rest
}) => {
  const childComp = (
    <Button
      {...rest}
      shape={shape ?? (iconOnly ? 'circle' : 'round')}
      className={[styles.button, styles[color]].join(' ')}
    >
      {!iconOnly && children}
    </Button >
  );

  return disableToolTip ? childComp : <CmsTooltip toolTipKey={toolTipKey}>{childComp}</CmsTooltip>;
};
