import { Accent, DetailItem } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { useGetAdministrationByOwner } from '@/lib/queries/lyanthe/administrations';
import { Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';

export const ServiceRelation = () => {
  const { relation } = useRelationContext();
  const { t } = useTranslation();
  const { data } = useGetAdministrationByOwner(relation?.id);
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size="small">
        <Accent type="h3" color="primary">
          {t('profile.relation')}
        </Accent>
        <DetailItem name={t('relation.id')} value={relation?.id} />
        <DetailItem name={t('relation.name')} value={relation?.name} />
        <DetailItem
          name={t('relation.address')}
          value={`${relation?.street || ''} ${relation?.houseNumber || ''}`}
        />
        <DetailItem name={t('relation.zipcode')} value={relation?.zipcode} />
        <DetailItem name={t('relation.city')} value={relation?.city} />
        <DetailItem name={t('relation.country')} value={relation?.country} />
        <Divider />
        <Accent type="h3" color="primary">
          {t('relation.identifiers')}
        </Accent>
        {relation?.identifiers.map((x) => (
          <DetailItem
            key={`${x.identifier}/${x.identifierType}`}
            name={x?.identifierType}
            value={x?.identifier}
          />
        ))}
        <Divider />
        <Accent type="h3" color="primary">
          Lyanthe
        </Accent>
        <DetailItem
          name={t('profile.administrationId')}
          value={data?.administrationId}
        />
      </Space>
    </>
  );
};
