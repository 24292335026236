import { CurrencyDisplay, CustomButton, HeaderItem } from '@/lib/components';
import { NettingPaymentEntry } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { InvoiceCreditRow } from './invoiceCreditRow';

type InvoiceCreditListProps = {
  nettingPaymentEntries: NettingPaymentEntry[];
  downloadSepa: (paymentId: number) => void;
};

export const InvoiceCreditList = ({
  nettingPaymentEntries,
  downloadSepa,
}: InvoiceCreditListProps) => {
  const { t } = useTranslation();
  if (!nettingPaymentEntries || !nettingPaymentEntries.length) {
    return null;
  }

  return (
    <>
      <InvoiceCreditRow
        className="font-bold"
        desc={<HeaderItem textKey="Type" />}
        amount={<HeaderItem textKey="payment.amount" />}
        actions={<HeaderItem textKey="payment.header.sepa" />}
      />
      <List
        split={false}
        bordered={false}
        dataSource={nettingPaymentEntries}
        renderItem={(item) => (
          <InvoiceCreditRow
            key={item.id}
            desc={t('invoiceTypes.CreditNote')}
            amount={<CurrencyDisplay amount={item.invoiceAmount} />}
            actions={
              <CustomButton
                size="small"
                type="text"
                toolTipKey="payment.action.downloadSepa"
                onClick={() => downloadSepa(item.paymentId)}
              >
                <FontAwesomeIcon
                  color="var(--secondary)"
                  icon="file-download"
                />
              </CustomButton>
            }
          />
        )}
      />
    </>
  );
};
