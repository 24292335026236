import { Accent, CustomButton } from '@/lib/components';
import { AgrinotaRelation } from '@/lib/types';
import { Col, Row, Spin } from 'antd';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAgrinotaRelation } from '../../../../../lib/adapters/agrinota-service-adapter';

interface IAgrinota {
  identifier?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  agrinotaRelation: AgrinotaRelation | undefined;
  setAgrinotaRelation?: React.Dispatch<
    React.SetStateAction<AgrinotaRelation | undefined>
  >;
}

export const Agrinota: FC<IAgrinota> = ({
  identifier,
  setCurrentPage,
  setAgrinotaRelation,
  agrinotaRelation,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const { t } = useTranslation();

  useEffect(() => {
    if (identifier) {
      setLoading(true);
      getAgrinotaRelation({ coc: identifier })
        .then((r) => {
          if (!r) {
            throw new Error('No relation');
          }
          return r;
        })
        .then((r) => setAgrinotaRelation?.(r))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [identifier]);

  return (
    <>
      <Spin spinning={isLoading}>
        {agrinotaRelation ? (
          <>
            <Accent type="h1" color="primary">
              Er is een relatie gevonden
            </Accent>
            <Row gutter={16}>
              <Col span={12} className="text-right">
                <Accent color="primary" type="strong">
                  {t('relation.name')}
                </Accent>
              </Col>
              <Col span={12} className="text-left">
                {agrinotaRelation?.name}
              </Col>
              {/* ADDRESS */}
              <Col span={12} className="text-right">
                <Accent color="primary" type="strong">
                  {t('relation.address')}
                </Accent>
              </Col>
              <Col span={12} className="text-left">
                {agrinotaRelation?.street} {agrinotaRelation?.houseNumber},{' '}
                {agrinotaRelation?.city}
              </Col>
              {/* ZIPCODE */}
              <Col span={12} className="text-right">
                <Accent color="primary" type="strong">
                  {t('relation.zipcode')}
                </Accent>
              </Col>
              <Col span={12} className="text-left">
                {agrinotaRelation?.zipcode}
              </Col>
              {/*KVK */}
              <Col span={12} className="text-right">
                <Accent color="primary" type="strong">
                  {t('relation.coc')}
                </Accent>
              </Col>
              <Col span={12} className="text-left">
                {agrinotaRelation?.coC}
              </Col>
              <Col span={12} className="text-right">
                <Accent color="primary" type="strong">
                  {t('relation.customerNumber')}
                </Accent>
              </Col>
              <Col span={12} className="text-left">
                {agrinotaRelation?.customerNumbers}
              </Col>
            </Row>
          </>
        ) : (
          <div>Er is geen Agrinota relatie gevonden</div>
        )}
        <div style={{ marginTop: '2rem' }}>
          <CustomButton
            type="link"
            onClick={() => setCurrentPage?.((prev) => prev - 1)}
          >
            Ga terug
          </CustomButton>
          <CustomButton
            type="primary"
            disabled={!!error}
            onClick={() => setCurrentPage?.((prev) => prev + 1)}
          >
            Volgende
          </CustomButton>
        </div>
      </Spin>
    </>
  );
};
