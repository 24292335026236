import { hasAccessAtom, userTokenParsed } from '@/lib/atoms/atoms';
import {
  Accent,
  CustomButton,
  DocumentViewModal,
  InfoButton,
} from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Notification, NotificationInvoice } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Collapse, Divider, Popconfirm, Row, Space, Steps } from 'antd';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScopeTrackingButton } from '../scope';
import { LoggingShortcut } from '../support/loggingShortcut';
import { DuplicateInvoiceView } from './duplicateInvoiceView';

interface TrackingItemProps {
  notification: Notification;
  onSeen: (id: number) => void;
  onDelete: (id: number) => void;
  mode: 'all' | 'unread';
}

const accents = {
  Error: 'danger',
  Success: 'success',
  default: 'secondary',
};

const { Panel } = Collapse;

const TrackingItem: React.FunctionComponent<TrackingItemProps> = ({
  notification,
  onSeen,
  mode,
  onDelete,
}) => {
  const { t } = useTranslation();
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();
  const parsedToken = useAtomValue(userTokenParsed);

  const hasInvoiceData = (notification?: Notification) => {
    return (
      !!notification?.invoice &&
      (notification.invoice?.processFileId ||
        notification.invoice?.incomingFileId)
    );
  };

  const getPdfViewButton = (invoice?: NotificationInvoice) => {
    if (!invoice) {
      return null;
    }
    if (invoice.processFileId) {
      return (
        <DocumentViewModal id={invoice.processFileId} idType="processfile" />
      );
    } else if (invoice.incomingFileId) {
      return (
        <DocumentViewModal id={invoice.incomingFileId} idType="incomingfile" />
      );
    }
  };

  function notificationChildrenToSteps(notification: Notification) {
    const a: any[] = [];
    a.push({
      title: t(
        `notifications.descriptions.${notification.description || ''}`,
        notification.description || '',
      ),
      description: dateTimeFormatter.toDateTime(
        new Date(notification.sendDate + 'Z'),
      ),
    });
    notification?.children
      ?.sort(
        (a, b) =>
          new Date(b.sendDate).getTime() - new Date(a.sendDate).getTime(),
      )
      .forEach((el) => {
        a.push({
          title: t(
            `notifications.descriptions.${el.description || ''}`,
            el.description || '',
          ),
          description: dateTimeFormatter.toDateTime(
            new Date(el.sendDate + 'Z'),
          ),
        });
      });
    return a;
  }

  return (
    <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
      <Panel
        key={'panel_' + notification.id}
        style={{ borderBottom: '1px solid #f0f0f0' }}
        header={
          <Row justify="space-between">
            <Col md={5} sm={24}>
              <Accent
                type={
                  notification.isMarkedAsSeen || mode === 'unread'
                    ? undefined
                    : 'strong'
                }
                color="default"
                style={{
                  width: '8rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {notification.name}
              </Accent>
            </Col>
            <Col md={9} sm={24}>
              <Space style={{ alignItems: 'baseline' }}>
                <Accent
                  color={
                    accents[notification.invoice?.status ?? 'default'] ??
                    'secondary'
                  }
                  type="strong"
                >
                  {notification.invoice?.status}
                </Accent>
                <div>
                  {notification.invoice?.status !==
                    notification.description && (
                    <Accent>
                      <Space>
                        {t(
                          `notifications.descriptions.${
                            notification.description || ''
                          }`,
                          notification.description || '',
                        )}
                        {notification.description === 'Invoice.Duplicate' &&
                          !!notification.invoice && (
                            <DuplicateInvoiceView
                              duplicateIncomingFileId={
                                notification.invoice.incomingFileId
                              }
                              processFileId={notification.invoice.processFileId}
                            />
                          )}
                      </Space>
                    </Accent>
                  )}
                  <Space>
                    <em>{notification.invoice?.statusReason}</em>
                    <InfoButton
                      content={notification.invoice?.statusDescription}
                    />
                  </Space>
                </div>
              </Space>
            </Col>
            <Col md={5} sm={24}>
              <Accent color="secondary">
                {dateTimeFormatter.toDateTime(
                  new Date(notification.sendDate + 'Z'),
                )}
              </Accent>
            </Col>
            <Col md={5} sm={24} style={{ textAlign: 'right' }}>
              {hasAccess.isAdmin && (
                <>
                  <Popconfirm
                    title={t('notifications.actions.confirmDelete')}
                    onConfirm={() => onDelete(notification.id)}
                  >
                    <CustomButton size="small" danger type="link">
                      <FontAwesomeIcon icon="trash-alt" />
                    </CustomButton>
                  </Popconfirm>
                </>
              )}
              {hasAccess.isAccountManager ||
                (hasAccess.isAdmin && (
                  <ScopeTrackingButton
                    scopeId={notification.invoice?.scopeId}
                  />
                ))}

              <CustomButton
                disabled={
                  (hasAccess.isAccountManager &&
                    notification.userId !== parsedToken.user_id &&
                    notification.relationId !== parsedToken.relation_id) ||
                  isFrozen
                }
                hidden={notification.isMarkedAsSeen}
                toolTipKey="notifications.action.markAsRead"
                onClick={(e) => {
                  onSeen(notification.id);
                }}
                size="small"
                type="link"
              >
                <FontAwesomeIcon color="var(--secondary)" icon="eye-slash" />
              </CustomButton>
              {hasInvoiceData(notification) &&
                getPdfViewButton(notification.invoice)}
              <LoggingShortcut datetime={notification.sendDate} />
            </Col>
          </Row>
        }
      >
        <Row gutter={12} align="top">
          <Col>
            <Steps
              style={{ marginLeft: '1rem', marginRight: '2.25rem' }}
              progressDot
              size="small"
              direction="vertical"
              items={notificationChildrenToSteps(notification)}
            />
          </Col>
          <Col>
            <Divider type="vertical" />
          </Col>
          <Col>
            <Space style={{ margin: '0 1rem' }} direction="vertical">
              <Accent color="primary">{t('notifications.invoice')}</Accent>
              <Accent>{notification.name}</Accent>
            </Space>
          </Col>
          {notification.description && (
            <>
              <Col>
                <Divider type="vertical" />
              </Col>
              <Col>
                <span style={{ margin: '0 1rem' }}>
                  {t(
                    `notifications.descriptions.${notification.description}`,
                    notification.description,
                  )}
                </span>
              </Col>
            </>
          )}
        </Row>
      </Panel>
    </Collapse>
  );
};

export default TrackingItem;
