import { PagedResponse } from '@/lib/types';
import { Relation, RelationFilter } from '@/lib/types/user';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['user', 'locations'];
const BASE_URL = `${apiBasePaths.user}/v1/locations`;

export const useGetLocationRelations = (
  params?: RelationFilter,
): DefinedUseQueryResult<PagedResponse<Relation[]>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params, 'relations'],
    enabled: !!params,
    queryFn: () =>
      api
        .get<PagedResponse<Relation[]>>(`${BASE_URL}/relations`, {
          params: createFilterParams({
            ...params,
          }),
          timeout: 60000,
        })
        .then((r) => r.data),
    initialData: {
      page: 1,
      pageSize: 10,
      total: 0,
      data: [],
    },
  });
