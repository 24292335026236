/**
 * This file contains all API paths, relative to API_URL configured in config.js
 * To override, add them to .env file
 * To override the base API_URL, start with https://
 */

export const apiBasePaths = {
  invoices: import.meta.env.VITE_API_INVOICES || '/invoices/api',
  notifications: import.meta.env.VITE_API_NOTIFICATIONS || '/notifications/api',
  user: import.meta.env.VITE_API_USERS || '/user/api',
  payments: import.meta.env.VITE_API_PAYMENTS || '/payments/api',
  scheduler: import.meta.env.VITE_API_SCHEDULER || '/scheduler/api',
  lyanthe: import.meta.env.VITE_API_LYANTHE || '/lyanthe/api',
};
