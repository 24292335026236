import { getSepaFile } from '@/lib/adapters/payment-adapter';
import { hasAccessAtom, userTokenParsed } from '@/lib/atoms/atoms';
import {
  Accent,
  ConditionalTooltip,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import { LoggingShortcut } from '@/lib/components/support/loggingShortcut';
import { useRelationContext } from '@/lib/context';
import {
  Notification,
  NotificationInvoice,
  NotificationSepaInformation,
} from '@/lib/types';
import { downloadUrl } from '@/lib/utils/download-file';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, List, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import parse from 'html-react-parser';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import {
  getColorByLevel,
  getIconByLevel,
} from '../../lib/utils/notificationHelper';
import styles from './styles.module.scss';

type NotificationItemProps = {
  notification: Notification;
  handleSeen: (id: number) => any;
};

export const NotificationItem = ({
  notification,
  handleSeen,
}: NotificationItemProps) => {
  const { sm } = useBreakpoint();
  const [hasAccess] = useAtom(hasAccessAtom);
  const { t, i18n } = useTranslation();
  const parsedToken = useAtomValue(userTokenParsed);
  const { isFrozen } = useRelationContext();

  const hasInvoiceData =
    !!notification.invoice &&
    (notification.invoice.processFileId || notification.invoice.incomingFileId);
  const hasSepaData = !!notification.sepaInformation;

  const getPdfViewButton = (invoice?: NotificationInvoice) => {
    if (!invoice) {
      return null;
    }

    if (invoice.processFileId) {
      return (
        <DocumentViewModal id={invoice.processFileId} idType="processfile" />
      );
    } else if (invoice.incomingFileId) {
      return (
        <DocumentViewModal id={invoice.incomingFileId} idType="incomingfile" />
      );
    }
  };

  const downloadSepa = async (sepa?: NotificationSepaInformation) => {
    try {
      if (!sepa) {
        throw new Error('Missing sepa data');
      }
      if (!sepa.paymentId) {
        throw new Error('Missing sepa payment id.');
      }

      const data = await getSepaFile(sepa.paymentId);
      downloadUrl(data.blobStorageUri, `sepa_${sepa.paymentId}.xml`);
    } catch (error) {
      showNotification('error', t('Error.FilesNotFound'));
    }
  };

  const className =
    styles.row + (notification.isMarkedAsSeen ? '' : ' ' + styles.unread);

  const label = i18n.exists(`notifications.names.${notification.name}`)
    ? t(`notifications.names.${notification.name}`)
    : notification.name;
  const description = i18n.exists(
    `notifications.descriptions.${notification.description}`,
  )
    ? t(`notifications.descriptions.${notification.description}`)
    : notification.description;

  return (
    <List.Item>
      <Row className={className} align="middle">
        <Col xs={13} sm={10}>
          <div>
            <Accent style={{ fontSize: '0.7rem' }} color="tertiary">
              {dateTimeFormatter.toDateTime(
                new Date(notification.sendDate + 'Z'),
              )}
            </Accent>
          </div>
          <div>{label}</div>
        </Col>
        <Col flex="1">
          <ConditionalTooltip isShown={!sm} title={description}>
            <FontAwesomeIcon
              size="lg"
              color={getColorByLevel(notification.level)}
              icon={getIconByLevel(notification.level)}
              style={{ marginRight: '5px' }}
            />
            {sm && parse(description || '')}
          </ConditionalTooltip>
        </Col>
        <Col xs={6} sm={4} style={{ textAlign: 'right' }}>
          <LoggingShortcut datetime={notification.sendDate} />
          <CustomButton
            disabled={
              (hasAccess.isAccountManager &&
                notification.userId !== parsedToken.user_id &&
                notification.relationId !== parsedToken.relation_id) ||
              isFrozen
            }
            toolTipKey="notifications.action.markAsRead"
            onClick={() => handleSeen(notification.id)}
            size="small"
            type="text"
          >
            <FontAwesomeIcon color="var(--secondary)" icon="eye-slash" />
          </CustomButton>
          {hasInvoiceData && getPdfViewButton(notification.invoice)}
          {hasSepaData && (
            <CustomButton
              size="small"
              type="text"
              toolTipKey="payment.action.downloadSepa"
              onClick={() => downloadSepa(notification.sepaInformation)}
            >
              <FontAwesomeIcon color="var(--secondary)" icon="file-download" />
            </CustomButton>
          )}
        </Col>
      </Row>
    </List.Item>
  );
};
