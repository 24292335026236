import { CustomTable } from '@/lib/components';
import { Invoice } from '@/lib/types';
import { useTranslation } from 'react-i18next';

export const InvoiceDetailsTable = ({ invoice }: { invoice: Invoice[] }) => {
  const { t } = useTranslation();

  return (
    <CustomTable
      rowKey="id"
      dataSource={invoice.map((inv) => ({
        ...inv,
        key: `row_${inv.invoiceId}`,
      }))}
      columns={[
        {
          title: t('renders.invoice.details.currency'),
          dataIndex: 'currency',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          key: 'currency_col',
        },
        {
          title: t('renders.invoice.details.invoiceNumber'),
          dataIndex: 'invoiceNumber',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          key: 'invoice_nr_col',
        },
        {
          title: t('renders.invoice.details.invoiceDate'),
          dataIndex: 'invoiceDate',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'dateonly',
          key: 'invoice_date_col',
        },
        {
          title: t('renders.invoice.details.paymentDueDate'),
          dataIndex: 'paymentDueDate',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'dateonly',
          key: 'payment_due_date_col',
        },
        {
          title: t('renders.invoice.details.paymentTerm'),
          dataIndex: 'paymentTerm',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          key: 'payment_term_col',
        },
        {
          title: t('renders.invoice.details.paymentCode'),
          dataIndex: 'paymentCode',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          key: 'payment_code_col',
        },
        {
          title: t('renders.invoice.details.identification'),
          dataIndex: 'identification',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          key: 'identification_col',
        },
      ]}
    />
  );
};
