import {
  getIsInPaymentInvoices,
  setIsInPayment,
} from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom, payableInvoiceCountAtom } from '@/lib/atoms/atoms';
import { useRelationContext } from '@/lib/context';
import { Invoice } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../core/buttons/customButton';

type PaymentButtonProps = {
  iconOnly?: boolean;
  IsInPayment: boolean;
  invoiceId: number;
  isDisabled?: boolean;
  onSuccess?: (invoice: Invoice) => void;
};

export const PaymentButton: FC<PaymentButtonProps> = ({
  invoiceId,
  iconOnly = true,
  IsInPayment = false,
  isDisabled = false,
  onSuccess,
}) => {
  const [isLoading, setLoading] = useState(false);
  const setPayableInvoiceCount = useUpdateAtom(payableInvoiceCountAtom);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();

  const { t } = useTranslation();
  const onClick = async () => {
    setLoading(true);
    try {
      const invoice = await setIsInPayment(invoiceId, !IsInPayment);
      onSuccess?.(invoice);

      getIsInPaymentInvoices().then((r) => {
        setPayableInvoiceCount(r.data.length);
      });
    } catch {
      showNotification('error', 'Klaar zetten mislukt');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomButton
      danger={IsInPayment}
      color="primary"
      disabled={isDisabled || hasAccess.isAccountManager || isFrozen}
      type="primary"
      shape={iconOnly ? 'circle' : 'round'}
      icon={<FontAwesomeIcon icon={['far', 'credit-card']} size="sm" />}
      onClick={onClick}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.payment"
    >
      {!iconOnly &&
        (IsInPayment
          ? t('label.isReadyForPayment')
          : t('label.readyForPayment'))}
    </CustomButton>
  );
};
