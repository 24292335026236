import { resendInvoice } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton, GenericModal } from '@/lib/components';
import { Invoice } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SwitchChannelButtonProps = {
  invoiceId: number;
  onSuccess?: (invoice: Invoice) => void;
};

export const SwitchChannelButton = ({
  invoiceId,
  onSuccess,
}: SwitchChannelButtonProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [isVisible, setIsVisible] = useState(false);

  const handleResend = async () => {
    try {
      const invoice = await resendInvoice(invoiceId, true);
      showNotification('success', 'Opnieuw ter verwerking gesteld.');
      onSuccess?.(invoice);
    } catch {
      showNotification('error', 'Fout tijdens herverwerken factuur.');
    }
  };

  return !isAdmin ? null : (
    <>
      <GenericModal
        title={t('action.switchChannel.invoice.title')}
        okText={t('action.switchChannel.invoice.ok')}
        isVisible={isVisible}
        hideModal={() => setIsVisible(false)}
        onOkHandler={handleResend}
        destroyOnClose
      >
        <p>{t('action.switchChannel.invoice.content')}</p>
      </GenericModal>
      <CustomButton
        shape="circle"
        type="link"
        disabled={!isAdmin}
        onClick={() => setIsVisible(true)}
        icon={<FontAwesomeIcon icon="random" size="sm" />}
        style={{ marginRight: 5 }}
        toolTipKey="invoiceDetail.action.switchChannel"
      />
    </>
  );
};
