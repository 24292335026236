import { getRelations } from '@/lib/adapters/users-adapter';
import { FilterBase, OwnerFilterBase, RelationType, SortOrder } from '@/lib/types';
import { RelationFilter } from '@/lib/types/user';

const getLinkedRelationIds = async (name?: string, identifier?: string) => {
  try {
    const filter: RelationFilter = { exactMatch: false };
    if (name) {
      filter.name = name;
    }
    if (identifier) {
      filter.identifier = identifier;
    }
    const relations = (await getRelations({ ...filter, type: RelationType.Customer })).data;
    return relations.length ? relations?.map((x) => x.id) : [''];
  } catch (e) {
    return [''];
  }
};

export const getOwnerFilterBase = async (
  pagination: { current: number; pageSize?: number },
  filters: { [key: string]: any },
  sorter: { field: string; order: string },
  currentPageSize?: number,
  defaultSortField?: string,
  exactMatch: boolean = false,
): Promise<OwnerFilterBase> => {
  const filter: OwnerFilterBase = getFilterBase(
    pagination,
    filters,
    sorter,
    currentPageSize,
    defaultSortField,
    exactMatch,
  );

  const ownerCustomerNumber = filters?.ownerCustomerNumber?.[0];
  const ownerName = filters?.ownerName?.[0];
  if (ownerName || ownerCustomerNumber) {
    filter.ownerRelationIds = await getLinkedRelationIds(
      ownerName,
      ownerCustomerNumber
    );
  } else {
    filter.ownerRelationIds = [];
  }

  return filter;
};

export const getFilterBase = (
  pagination: { current: number; pageSize?: number },
  filters: { [key: string]: any },
  sorter: { field: string; order: string },
  currentPageSize?: number,
  defaultSortField?: string,
  exactMatch: boolean = false,
): FilterBase => {
  const filter: FilterBase = {
    orderField: sorter.order === undefined ? defaultSortField : sorter.field || defaultSortField,
    order:
      sorter?.order === 'ascend' ? SortOrder.Ascending : SortOrder.Descending,
    filterValues: [],
    page: pagination.current,
    pageSize: pagination.pageSize ?? currentPageSize,
    exactMatch,
  };

  if (!filters) {
    return filter;
  }

  for (let [key, value] of Object.entries(filters)) {
    if (value) {
      let valueString = `${value}`;
      filter.filterValues!.push({ key, value: valueString });
    }
  }

  return filter;
};