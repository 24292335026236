import { BankAccount } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['payment', 'bankaccounts'];
const BASE_URL = `${apiBasePaths.payments}/v1/bankaccounts`;

export const useGetBankAccounts = (): DefinedUseQueryResult<
  BankAccount[],
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => api.get<BankAccount[]>(`${BASE_URL}`).then((r) => r.data),
    initialData: [],
  });
