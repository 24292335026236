import { FC, ReactNode, useState } from "react";
import { CustomButton, CustomButtonProps } from "./customButton";
import { CombinedIcon } from "../icons";

export type ToggleButtonProps = CustomButtonProps & {
  active: boolean;
  icon: ReactNode;
  inactiveIcon?: ReactNode;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  active,
  icon,
  inactiveIcon,
  children,
  ...rest
}) => {
  return <CustomButton
    {...rest}
    icon={active ? icon : (inactiveIcon ?? icon)}
    color={active ? "primary" : "default"}
  >
    {children}
  </CustomButton>
}
