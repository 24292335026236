import { CustomTable } from '@/lib/components';
import { InvoiceTotal } from '@/lib/types';
import { useTranslation } from 'react-i18next';

export const InvoiceTotalTable = ({ totals }: { totals: InvoiceTotal[] }) => {
  const { t } = useTranslation();
  return (
    <CustomTable
      dataSource={totals}
      rowKey="amount"
      columns={[
        {
          title: t('renders.invoice.total.amount'),
          dataIndex: 'amount',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'currency',
        },
      ]}
    />
  );
};
