import { ImpersonatedRoute, RoleProtectedRoute } from '@/lib/route-guards';
import { Documents } from '@/routes/documents/page';
import { UserRoles } from '../../lib/types/enums';
import { AgrinotaImportDetails } from './agrinotaimport/[id]/page';
import { AgrinotaImportOverview } from './agrinotaimport/overview/page';
import { AgrinotaImport } from './agrinotaimport/page';
import { ApplicationSettings } from './application-settings/page';
import { BankAccountSettings } from './bankaccount/page';
import { BulkArchive } from './bulkarchive/page';
import { InvoiceOverview } from './invoice-overview/page';
import { AttachUnknownRelation } from './invoiceswithouthrelation/[id]/page';
import { InvoicesWithouthRelation } from './invoiceswithouthrelation/page';
import { LabelManagementDetails } from './labels/[id]/page';
import { LabelManagement } from './labels/page';
import { SettingsLayout } from './layout';
import { LyantheData } from './lyanthedata/page';
import { MergeRelations } from './merge/page';
import { NotificationSettings } from './notifications/page';
import { ProcessData } from './processData/page';
import { RejectedInvoices } from './rejectedinvoices/page';
import { Relations } from './relations/page';
import { NotifyUsers } from './singletasks/notifyusers/page';
import { SupplierSettings } from './suppliers/page';
import { Support } from './support/page';
import { NoFilesUploaded } from './triggers/nofilesuploaded/page';
import { Users } from './users/page';
import { Company } from './company/page';

const settingsRoute = {
  path: 'settings',
  element: <SettingsLayout />,
  children: [
    {
      path: '',
      element: <ImpersonatedRoute />,
      children: [
        { path: '/settings/documents', element: <Documents /> },
        { path: '/settings/notifications', element: <NotificationSettings /> },
        { path: '/settings/support', element: <Support /> },
        { path: '/settings/bankaccount', element: <BankAccountSettings /> },
        { path: '/settings/company', element: <Company /> },
        { path: '/settings/suppliers', element: <SupplierSettings /> },
      ],
    },
    {
      path: '',
      element: <RoleProtectedRoute roles={[UserRoles.APP_ADMIN]} />,
      children: [
        {
          path: '/settings/triggers/nofilesuploaded',
          element: <NoFilesUploaded />,
        },
        { path: '/settings/singletasks/notifyusers', element: <NotifyUsers /> },
        {
          path: '/settings/application-settings',
          element: <ApplicationSettings />,
        },
        { path: '/settings/relations', element: <Relations /> },
        { path: '/settings/users', element: <Users /> },
        { path: '/settings/rejectedinvoices', element: <RejectedInvoices /> },
        {
          path: '/settings/invoiceswithouthrelation',
          children: [
            { path: '', element: <InvoicesWithouthRelation /> },
            { path: ':id', element: <AttachUnknownRelation /> },
          ],
        },
        { path: '/settings/invoice-overview', element: <InvoiceOverview /> },
        { path: '/settings/processData', element: <ProcessData /> },
        { path: '/settings/lyanthedata', element: <LyantheData /> },
        { path: '/settings/bulkarchive', element: <BulkArchive /> },
        { path: '/settings/merge', element: <MergeRelations /> },
        {
          path: '/settings/labels',
          children: [
            { path: '', element: <LabelManagement /> },
            { path: ':id', element: <LabelManagementDetails /> },
          ],
        },
        {
          path: '/settings/agrinotaimport',
          children: [
            {
              path: '',
              element: <AgrinotaImport />,
            },
            {
              path: 'overview',
              element: <AgrinotaImportOverview />,
            },
            {
              path: ':id',
              element: <AgrinotaImportDetails />,
            },
          ],
        },
      ],
    },
  ],
};

export default settingsRoute;
