export const getColorByLevel = (level: string) => {
  switch (level) {
    case 'Success':
      return 'green';
    case 'Info':
      return 'var(--info-color)';
    case 'Warning':
      return 'orange';
    case 'Error':
      return 'red';
    default:
      break;
  }
};
export const getIconByLevel = (level: string) => {
  switch (level) {
    case 'Error':
      return 'times-circle';
    case 'Info':
      return 'info-circle';
    case 'Warning':
      return 'exclamation-circle';
    default:
      return 'check-circle';
  }
};
