import { editInvoice } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import {
  Accent,
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { ExtendedInvoice, IdentifierType, Invoice } from '@/lib/types';
import { createRelationIdentifierValidator } from '@/lib/utils/formValidators';
import { currencyFormatter, dateTimeFormatter } from '@/lib/utils/formatters';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Descriptions, Form, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { UtcDatePicker } from '../form/utcDatePicker';
import styles from './styles.module.scss';

interface EditInvoiceProps {
  identifierTypes: IdentifierType[];
  invoice: ExtendedInvoice;
  disableEditMode: () => void;
  onSuccess?: (invoice: Invoice) => void;
}

export const EditInvoice: React.FunctionComponent<EditInvoiceProps> = ({
  identifierTypes,
  invoice,
  disableEditMode,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [form] = Form.useForm();

  const [relationKey, setRelationKey] = useState<
    'supplier' | 'customer' | 'none'
  >('none');

  useEffect(() => {
    const relationType =
      invoice.ownerRelationId === invoice.supplier.relationId
        ? 'supplier'
        : invoice.ownerRelationId === invoice.customer.relationId
        ? 'customer'
        : 'none';

    setRelationKey(relationType);
    form.setFieldsValue({
      invoiceDate: dayjs.utc(invoice?.invoiceDate).local(),
      amount: invoice.amount,
      invoiceNumber: invoice.invoiceNumber,
      supplierIdentifier: invoice.supplier.identifier,
      supplierIdentifierType: invoice.supplier.identifierType,
      customerIdentifier: invoice.customer.identifier,
      customerIdentifierType: invoice.customer.identifierType,
    });
  }, [invoice]);

  if (!invoice) {
    return null;
  }

  const onFormFinish = async (values) => {
    if (typeof values.customerIdentifierType !== 'string') {
      const currentIdentifierType = identifierTypes?.find(
        (x) => x.id === parseInt(values.customerIdentifierType),
      );
      values.customerIdentifierType = currentIdentifierType?.type;
    }
    if (typeof values.supplierIdentifierType !== 'string') {
      const currentIdentifierType = identifierTypes?.find(
        (x) => x.id === parseInt(values.supplierIdentifierType),
      );
      values.supplierIdentifierType = currentIdentifierType?.type;
    }
    try {
      setIsLoading(true);
      const result = await editInvoice(invoice.invoiceId, values);
      onSuccess?.(result);
      showNotification('success', 'Factuur aangepast.');
    } catch (e: any) {
      const msg =
        e.message || 'Fout tijdens aanpassen factuur. Probeer opnieuw.';
      showNotification('error', msg);
    } finally {
      setIsLoading(false);
    }
  };

  return !isAdmin ? null : (
    <Spinal initializing={isLoading} loading={false}>
      <Card bordered={false}>
        <Row>
          <Accent type="h1" color="primary">
            {t('renders.invoice.edit')}
          </Accent>
        </Row>
        <Row style={{ marginBottom: '1rem' }}>
          <CustomButton
            icon={<FontAwesomeIcon icon="long-arrow-alt-left" />}
            color="secondary"
            onClick={() => disableEditMode()}
          >
            {t('general.actions.back')}
          </CustomButton>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Accent type="h3" color="primary">
              {t('renders.invoice.title')}
            </Accent>

            <Descriptions labelStyle={{ fontWeight: 'bold' }} colon={false}>
              <Descriptions.Item
                span={3}
                label={t('renders.invoice.details.invoiceDate')}
              >
                {invoice.invoiceDate &&
                  dateTimeFormatter.toDateOnly(invoice.invoiceDate)}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('renders.invoice.invoiceAmount')}
              >
                <CurrencyDisplay amount={invoice.amount || 0} />
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={t('renders.invoice.details.invoiceNumber')}
              >
                {invoice.invoiceNumber}
              </Descriptions.Item>

              <Descriptions.Item span={12}>
                <Accent type="h4" color="primary">
                  {t('renders.invoice.supplier')}
                </Accent>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('renders.invoice.relation.identifier')}
              >
                {invoice.supplier.identifier}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('renders.invoice.relation.identifierType')}
              >
                {invoice.supplier.identifierType}
              </Descriptions.Item>

              <Descriptions.Item span={12}>
                <Accent type="h4" color="primary">
                  {t('renders.invoice.customer')}
                </Accent>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('renders.invoice.relation.identifier')}
              >
                {invoice.customer.identifier}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={t('renders.invoice.relation.identifierType')}
              >
                {invoice.customer.identifierType}
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={t('invoiceWithouthRelation.showPdf')}
              >
                {invoice && (
                  <DocumentViewModal
                    id={invoice.sourceIdentifier}
                    idType="processfile"
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Accent type="h3" color="primary">
              {t('renders.invoice.edit')}
            </Accent>
            <Form
              form={form}
              labelAlign="left"
              onFinish={onFormFinish}
              labelCol={{ span: 8 }}
            >
              <Form.Item
                name="invoiceDate"
                label={t('renders.invoice.details.invoiceDate')}
              >
                <UtcDatePicker className={styles.editField} />
              </Form.Item>
              <Form.Item
                name="amount"
                label={t('renders.invoice.invoiceAmount')}
                normalize={currencyFormatter().parse}
              >
                <NumericFormat
                  className={styles.editField}
                  step={1}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator="."
                  allowNegative
                  prefix="€ "
                />
              </Form.Item>
              <Form.Item
                name="invoiceNumber"
                label={t('renders.invoice.details.invoiceNumber')}
              >
                <Input className={styles.editField} />
              </Form.Item>
              <Accent type="h4" color="primary">
                {t('renders.invoice.supplier')}
              </Accent>
              <Form.Item
                name="supplierIdentifier"
                label={t('renders.invoice.relation.identifier')}
                rules={[createRelationIdentifierValidator({ required: true })]}
              >
                <Input
                  className={styles.editField}
                  disabled={relationKey !== 'supplier'}
                />
              </Form.Item>
              <Form.Item
                name="supplierIdentifierType"
                label={t('renders.invoice.relation.identifierType')}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={identifierTypes.map((type) => ({
                    label: type.type,
                    value: type.id,
                  }))}
                  disabled={relationKey !== 'supplier'}
                />
              </Form.Item>

              <Accent type="h4" color="primary">
                {t('renders.invoice.customer')}
              </Accent>
              <Form.Item
                name="customerIdentifier"
                rules={[createRelationIdentifierValidator({ required: true })]}
                label={t('renders.invoice.relation.identifier')}
              >
                <Input
                  className={styles.editField}
                  disabled={relationKey !== 'customer'}
                />
              </Form.Item>
              <Form.Item
                name="customerIdentifierType"
                label={t('renders.invoice.relation.identifierType')}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={identifierTypes.map((type) => ({
                    label: type.type,
                    value: type.id,
                  }))}
                  disabled={relationKey !== 'customer'}
                />
              </Form.Item>

              <Form.Item label="" colon={false}>
                <CustomButton
                  htmlType="submit"
                  type="primary"
                  toolTipKey="invoice.edit.save"
                >
                  {t('general.actions.save')}
                </CustomButton>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Spinal>
  );
};
