import { useGetInvoiceScope } from '@/lib/queries/invoices/scope';
import { useGetLyantheScope } from '@/lib/queries/lyanthe/scope';
import { Navigatable } from '@/lib/types';
import {
  ActionScopeItem,
  FileType,
  ScopeItem,
  ScopeItemAction,
} from '@/lib/types/scope';
import { sorter } from '@/lib/utils/sorter';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Skeleton, Timeline } from 'antd';
import React from 'react';
import { ScopeViewItem } from './scopeViewItem';

interface ScopeViewProps extends Navigatable {
  scopeId?: string;
}

const createDataSet = (
  ...data: (ScopeItem[] | undefined)[]
): ActionScopeItem[] => {
  const result: ActionScopeItem[] = [];
  for (const scopeItems of data) {
    if (!scopeItems) {
      continue;
    }
    for (const scopeItem of scopeItems) {
      result.push(scopeItem);
      // If an item is deleted, we want to show it as a seperate entry
      if (scopeItem.deleted) {
        result.push({
          ...scopeItem,
          created: scopeItem.deleted,
          action: ScopeItemAction.Deleted,
        });
      }
    }
  }

  return result.sort(sorter.sortProperty({ property: 'created', asc: true }));
};

const getDot = (item: ActionScopeItem): React.ReactNode | undefined => {
  if (item.action === ScopeItemAction.Deleted) {
    return <CloseCircleOutlined style={{ color: 'var(--danger-color)' }} />;
  }

  const fileType = FileType[item.fileType];
  if (fileType === FileType.IncomingFile) {
    return undefined;
  }

  // Invoice should always be success
  if (item.status === 'Success' || fileType === FileType.Invoice) {
    return <CheckCircleOutlined style={{ color: 'var(--success-color)' }} />;
  }

  if (fileType === FileType.ProcessFile && item.status === 'Duplicate') {
    return <CopyOutlined style={{ color: 'var(--danger-color)' }} />;
  }

  return <SyncOutlined style={{ color: 'var(--info-color)' }} spin />;
};

export const ScopeView: React.FunctionComponent<ScopeViewProps> = ({
  scopeId,
  onNavigate,
}) => {
  const invoiceScope = useGetInvoiceScope(scopeId);
  const lyantheScope = useGetLyantheScope(scopeId);

  if (invoiceScope.isLoading || lyantheScope.isLoading) {
    return <Skeleton active />;
  }

  const items = createDataSet(invoiceScope.data, lyantheScope.data);

  return (
    <Timeline reverse>
      {items.map((item, i) => (
        <Timeline.Item key={i} dot={getDot(item)}>
          <ScopeViewItem item={item} onNavigate={onNavigate} />
        </Timeline.Item>
      ))}
    </Timeline>
  );
};
