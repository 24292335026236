import { Accent, CurrencyDisplay } from '@/lib/components';
import { Invoice, OnSuccessHandlers } from '@/lib/types';
import { Checkbox, Col, Row } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HandleButton } from '../../../lib/components/invoice-buttons/handleButton';
import { PaymentButton } from '../../../lib/components/invoice-buttons/paymentButton';
import { convertToExtendedInvoice } from '../../../lib/utils/extendedInvoice';
import styles from './styles.module.scss';

interface InvoiceRowProps {
  invoice: Invoice;
  isSelectable?: boolean;
  isSelected?: boolean;
  moduleHandlers: OnSuccessHandlers;
  toggleDrawer?: () => void;
  onSelect?: (id: number, isSelected: boolean) => void;
}

export const InvoiceRow: FC<InvoiceRowProps> = ({
  invoice,
  moduleHandlers,
  toggleDrawer,
  isSelectable = false,
  isSelected,
  onSelect,
}) => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const rowClasses = [styles.invoicerow];

  const onClick = () => {
    toggleDrawer?.();
    navigate(`/invoices/${invoice.invoiceId}`);
  };

  if (params.id === invoice.invoiceId.toString())
    rowClasses.push(styles.active);

  const extendedInvoice = convertToExtendedInvoice(invoice);

  return (
    <Row className={rowClasses.join(' ')} onClick={onClick}>
      {isSelectable && (
        <Col span={2}>
          <Checkbox
            checked={isSelected}
            onChange={(e) =>
              onSelect?.(extendedInvoice.invoiceId, e.target.checked)
            }
          />
        </Col>
      )}
      <Col flex={1}>
        <Row justify="space-between">
          <Col>
            <Accent prefix={{ value: '#', inline: true, color: 'tertiary' }}>
              {extendedInvoice.invoiceNumber}
            </Accent>
          </Col>
          <Col>
            <Accent type="h1" color="secondary">
              <CurrencyDisplay amount={extendedInvoice.amount} />
            </Accent>
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            <Accent type="p">
              {extendedInvoice.counterInvoiceRelation.name}
              <br />
            </Accent>
          </Col>
          <Col className="button__group">
            <HandleButton
              setHandle={true}
              type="default"
              color="white"
              invoiceId={extendedInvoice.invoiceId}
              onSuccess={moduleHandlers.onHandleSuccess}
            />
            <PaymentButton
              iconOnly={true}
              invoiceId={extendedInvoice.invoiceId}
              IsInPayment={extendedInvoice.isInPayment}
              onSuccess={moduleHandlers.onPaymentSuccess}
              isDisabled={!extendedInvoice.isPayable}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
