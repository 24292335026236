import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import dayjs from 'dayjs';
import moment from 'moment';

interface UtcDatePickerProps extends PickerDateProps<moment.Moment> {}

export const UtcDatePicker: React.FunctionComponent<UtcDatePickerProps> = ({
  onChange,
  ...restProps
}) => {
  const handleUniformOnChange = (input: any) => {
    const value = input ? new Date(dayjs(input).format('YYYY-MM-DD')) : null;
    onChange?.(moment(value), value?.toDateString() || '');
  };

  return (
    <DatePicker
      value={moment()}
      {...restProps}
      onChange={handleUniformOnChange}
      picker="date"
      format={dayjs.Ls.nl.formats.L}
    />
  );
};
