import {
  removeAutoHandle,
  setAutoHandle,
} from '@/lib/adapters/invoice-adapter';
import { useSetAutoHandler as setAutoHandlerByRelationId, useSetAutoHandler } from '@/lib/queries/invoices/autoHandlers'
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButtonProps } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from '../core/buttons/toggleButton';
import { showNotification } from '@/lib/utils/showNotification';

interface AutoHandleButtonProps extends CustomButtonProps {
  invoiceId?: number;
  relationId?: string;
  isAutoHandled: boolean;
  onSuccess?: (unknown?) => void;
};

export const AutoHandleButton: FC<AutoHandleButtonProps> = ({
  invoiceId,
  relationId,
  isAutoHandled,
  onSuccess,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();
  const setHandledByRelationId = relationId ? useSetAutoHandler(relationId) : undefined;

  const onClickUsingInvoice = async () => {
    if (!invoiceId) return;

    try {
      setLoading(true);
      isAutoHandled ? await removeAutoHandle(invoiceId) : await setAutoHandle(invoiceId);
      onSuccess?.();
    } catch {
      showNotification(
        'error',
        'Fout tijdens activeren automatische afhandeling.',
      );
    } finally {
      setLoading(false);
    }
  }

  const onClickUsingRelation = async () => {
    if (!relationId) return;

    try {
      setLoading(true);
      await setHandledByRelationId?.mutateAsync({ autoHandle: !isAutoHandled });
      onSuccess?.();
    } catch {
      showNotification(
        'error',
        'Fout tijdens activeren automatische afhandeling.',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <ToggleButton
      {...rest}
      active={isAutoHandled}
      icon={
        <FontAwesomeIcon icon={['fas', 'cogs']} size="1x" />
      }
      onClick={relationId ? onClickUsingRelation : onClickUsingInvoice}
      disabled={hasAccess.isAccountManager || isFrozen}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.autoHandle"
      iconOnly
    >
      {t('label.autoHandler')}
    </ToggleButton>
  );
};