import { CustomTable } from '@/lib/components';
import { InvoiceVat } from '@/lib/types';
import { useTranslation } from 'react-i18next';

export const InvoiceVatTable = ({
  invoiceVats,
}: {
  invoiceVats: InvoiceVat[];
}) => {
  const { t } = useTranslation();
  return (
    <CustomTable
      rowKey="percentage"
      dataSource={invoiceVats}
      columns={[
        {
          title: t('renders.invoice.vat.base'),
          dataIndex: 'base',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
        },
        {
          title: t('renders.invoice.vat.percentage'),
          dataIndex: 'percentage',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          render: (value) => `${value} %`,
        },
        {
          title: t('renders.invoice.vat.amount'),
          dataIndex: 'amount',
          enableDefaultSearch: false,
          enableDefaultSorter: false,
          defaultRender: 'currency',
        },
      ]}
    />
  );
};
