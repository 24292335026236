import { payableInvoiceCountAtom } from '@/lib/atoms/atoms';
import { CmsTooltip } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useAtomValue } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

interface IHeaderMenu {
  mode?: 'horizontal' | 'vertical' | 'inline';
}

export const HeaderMenu = ({ mode }: IHeaderMenu) => {
  const props = useHeaderMenu();

  return <Menu mode={mode} {...props} />;
};

// used for getting menu items in dropdown
const useHeaderMenu: () => MenuProps = () => {
  const location = useLocation();
  const items = useHeaderMenuItems();

  return {
    selectable: false,
    selectedKeys: location.pathname.split('/'),
    overflowedIndicator: <FontAwesomeIcon icon="bars" />,
    defaultSelectedKeys: [location.pathname],
    activeKey: '',
    items,
  };
};

// seperated in case only items are needed, without other props
const useHeaderMenuItems: () => ItemType[] = () => {
  const { relation } = useRelationContext();
  const { t } = useTranslation();
  const payableInvoiceCount = useAtomValue(payableInvoiceCountAtom);

  return [
    {
      key: 'invoices',
      disabled: !relation,
      label: (
        <CmsTooltip toolTipKey="menu.label.invoices">
          <Link to="/invoices">{t('nav.invoices')}</Link>
        </CmsTooltip>
      ),
    },
    {
      key: 'dossier',
      disabled: !relation,
      label: (
        <CmsTooltip toolTipKey="menu.label.dossier">
          <Link to="/dossier">{t('nav.dossier')}</Link>
        </CmsTooltip>
      ),
    },
    {
      key: 'payment',
      disabled: !relation,
      label: (
        <CmsTooltip toolTipKey="menu.label.payments">
          <Link to="/payment/inpayment">
            {t('label.payment_plural')}
            <Badge offset={[0, -20]} count={payableInvoiceCount} />
          </Link>
        </CmsTooltip>
      ),
    },
    {
      key: 'documents',
      disabled: !relation,
      label: (
        <CmsTooltip toolTipKey="menu.label.documents">
          <Link to="/documents">{t('nav.documents')}</Link>
        </CmsTooltip>
      ),
    },
  ];
};
