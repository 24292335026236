import { Input, InputProps } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

interface ControlledInputProps extends InputProps {
  normalize?: (value: string) => string;
}
export const ControlledInput: FC<ControlledInputProps> = ({
  value,
  onChange,
  normalize,
  ...props
}) => {
  const [stateValue, setStateValue] = useState(value);
  const [cursor, setCursor] = useState(0);
  const ref = useRef<any>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor]);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const onStateChange = (e) => {
    const value = e.target.value || '';
    const normalized = normalize?.(value) || value;
    setStateValue(normalized);
    setCursor(e.target.selectionStart);
    onChange?.(normalized);
  };

  return (
    <Input ref={ref} value={stateValue} onChange={onStateChange} {...props} />
  );
};
