import { getRelationByIdentifier } from '@/lib/adapters/users-adapter';
import { Accent, CustomButton } from '@/lib/components';
import { Relation } from '@/lib/types/user';
import { Form, Input, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RelationDetails } from '../../../../lib/components/relations/relationDetails';

interface RelationSearchProps {
  onSelect: (relation?: Relation) => void;
  relation?: Relation;
  title?: string;
  disabled: boolean;
}

export const RelationSearch = ({
  title,
  relation,
  onSelect,
  disabled,
}: RelationSearchProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const searchRelation = async (identifier: string, type: string) => {
    try {
      setIsLoading(true);
      const response = await getRelationByIdentifier({
        identifier,
        type,
      });
      onSelect(response);
    } catch (e) {
      onSelect(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const onFormFinish = async (values) => {
    return searchRelation(values.identifier, values.type);
  };

  return (
    <>
      <Accent type="h2" color="primary">
        {title}
      </Accent>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFormFinish}
        initialValues={{ type: 'NL:KVK' }}
      >
        <Form.Item
          name="identifier"
          label={t('relation.identifier.identifier')}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item label={t('relation.identifier.type')} name="type">
          <Input placeholder="NL:KVK" disabled={disabled} />
        </Form.Item>

        <Form.Item colon={false}>
          <CustomButton
            disabled={disabled}
            htmlType="submit"
            type="primary"
            style={{ marginTop: '1rem' }}
          >
            {t('merge.actions.search')}
          </CustomButton>
        </Form.Item>
      </Form>
      {isLoading ? (
        <Spin />
      ) : relation ? (
        <>
          <RelationDetails relation={relation} />
        </>
      ) : (
        <p>Geen relatie gevonden.</p>
      )}
    </>
  );
};
