import { hasAccessAtom } from '@/lib/atoms/atoms';
import { createProjectLoggingUrl } from '@/lib/utils/logging-utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { CustomButton } from '../core/buttons';

interface LoggingShortcutProps {
  datetime: Date;
  hoursSpan?: number;
}

export const LoggingShortcut: FC<LoggingShortcutProps> = ({
  datetime,
  hoursSpan = 1,
}) => {
  const [hasAccess] = useAtom(hasAccessAtom);

  const onClick = () => {
    const converted = dayjs(datetime);
    const url = createProjectLoggingUrl(
      converted.subtract(hoursSpan, 'hour'),
      converted.add(hoursSpan, 'hour'),
    );
    window.open(url, '_blank');
  };

  return hasAccess.isSupport ? (
    <Tooltip title="Logging">
      <CustomButton onClick={onClick} type="text" size="small">
        <FontAwesomeIcon color="var(--secondary)" icon="search" />
      </CustomButton>
    </Tooltip>
  ) : (
    <></>
  );
};
