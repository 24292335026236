import { setAutoHandle, setAutoPayment } from '@/lib/adapters/invoice-adapter';
import { useSetAutoHandler } from '@/lib/queries/invoices/autoHandlers'
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButtonProps } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CombinedIcon } from '../core/icons';
import { ToggleButton } from '../core/buttons/toggleButton';

interface AutoPayButtonProps extends CustomButtonProps {
  invoiceId?: number;
  relationId?: string;
  isAutoPaid: boolean;
  onSuccess?: (unknown?) => void;
}

export const AutoPayButton: FC<AutoPayButtonProps> = ({
  invoiceId,
  relationId,
  isAutoPaid,
  onSuccess,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();
  const setHandledByRelationId = relationId ? useSetAutoHandler(relationId) : undefined;

  const onClickUsingInvoice = async () => {
    if (!invoiceId) return;

    try {
      setLoading(true);
      await setAutoPayment(invoiceId, !isAutoPaid);
      onSuccess?.();
    } catch (err) {
      console.error(err)
      showNotification(
        'error',
        'Fout tijdens activeren automatische afhandeling.',
      );
    } finally {
      setLoading(false);
    }
  }

  const onClickUsingRelation = async () => {
    if (!relationId) return;

    try {
      setLoading(true);
      await setHandledByRelationId?.mutateAsync({ autoHandle: true, autoPayment: !isAutoPaid });
      onSuccess?.();
    } catch (err) {
      console.error(err)
      showNotification(
        'error',
        'Fout tijdens activeren automatische afhandeling.',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <ToggleButton
      {...rest}
      active={isAutoPaid}
      disabled={hasAccess.isAccountManager || isFrozen}
      icon={
        <CombinedIcon>
          <FontAwesomeIcon icon={['far', 'credit-card']} />
          <FontAwesomeIcon
            color={isAutoPaid ? 'red' : 'grey'}
            transform="shrink-3 up-6 right-6"
            icon={['fas', 'circle']}
          />
          <FontAwesomeIcon
            color="white"
            transform="shrink-7 up-6 right-6"
            icon={['fas', 'font']}
          />
        </CombinedIcon>
      }
      onClick={relationId ? onClickUsingRelation : onClickUsingInvoice}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.autoPay"
      iconOnly
    >
      {t('label.autoPay')}
    </ToggleButton>
  );
};