import { hasAccessAtom } from '@/lib/atoms/atoms';
import { ContentPanel } from '@/lib/components';
import { Layout, Menu, MenuProps } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import { useAtomValue } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
type MenuItem = Required<MenuProps>['items'][number];

const pathToLabel = (path: string) =>
  `pages${path.toLowerCase().replaceAll('/', '.')}`;

export const ServiceLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const hasAccess = useAtomValue(hasAccessAtom);
  if (!hasAccess.isSupport) {
    return null;
  }

  const menuItems: MenuItem[] = [
    {
      label: t('profile.relation'),
      key: '/service/relation',
    },
  ];

  return (
    <Layout
      hasSider
      style={{ padding: '0 16px', backgroundColor: 'transparent' }}
    >
      <Sider
        theme="light"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Menu
          mode="inline"
          theme="light"
          style={{ background: 'transparent' }}
          activeKey={location.pathname}
          selectedKeys={[location.pathname]}
          items={menuItems}
          onSelect={(e) => navigate(e.key)}
        />{' '}
      </Sider>
      <Content>
        <ContentPanel
          title={t(`${pathToLabel(location.pathname)}.title`, '')}
          description={t(`${pathToLabel(location.pathname)}.description`, '')}
        >
          <Outlet />
        </ContentPanel>
      </Content>
    </Layout>
  );
};
