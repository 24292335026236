import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';

interface TransListProps {
  i18nKey: string;
  items: ReactNode[];
  count: number;
}

export const TransList: React.FunctionComponent<TransListProps> = ({
  i18nKey,
  items,
  count,
}) => {
  return (
    <Trans i18nKey={i18nKey} count={count}>
      <CustomUl i18nIsDynamicList>
        {items.map((x) => (
          <li>{x}</li>
        ))}
      </CustomUl>
    </Trans>
  );
};

// This is required as ul has no valid i18nIsDynamicList property: https://github.com/i18next/react-i18next/issues/1703
interface CustomUlProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  i18nIsDynamicList?: boolean;
}

const CustomUl: React.FunctionComponent<CustomUlProps> = ({
  children,
  ...props
}) => {
  return <ul {...props}>{children}</ul>;
};
