import { NoFilesUploadedTrigger } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.scheduler}`;

export const getNoUploadedFilesTrigger = () =>
  api
    .get<NoFilesUploadedTrigger[]>(`${BASE_URL}/triggers/NoFilesUploaded`)
    .then((r) => r.data);

export const postNoUploadedFilesTrigger = (values) =>
  api.post<NoFilesUploadedTrigger>(
    `${BASE_URL}/triggers/NoFilesUploaded`,
    values,
  );

export const putNoUploadedFilesTrigger = (id: number, values) =>
  api.put<NoFilesUploadedTrigger>(
    `${BASE_URL}/triggers/NoFilesUploaded/${id}`,
    values,
  );

export const deleteNoUploadedFilesTrigger = (id: number) =>
  api.delete<NoFilesUploadedTrigger>(
    `${BASE_URL}/triggers/NoFilesUploaded/${id}`,
  );
