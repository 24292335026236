import { isAuthenticatedAtom, keycloakInstanceAtom } from '@/lib/atoms/atoms';
import { useRelationContext } from '@/lib/context';
import { api } from '@/lib/utils/api.axios';
import { Skeleton } from 'antd';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';

// UPDATE BEARER TOKEN FUNCTION
const updateToken = (token: string | undefined) =>
  (api.defaults.headers.common['Authorization'] = `Bearer ${token}`);

export const AuthComponent = ({ children }) => {
  const [isInitializing, setInitializing] = useState(true);
  const [keycloak, setKeycloakInstance] = useAtom(keycloakInstanceAtom);
  const setAuthenticated = useUpdateAtom(isAuthenticatedAtom);
  const { setRelationId } = useRelationContext();

  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(60)
      .catch(() => console.error('Failed to refresh token ' + new Date()));
  };

  keycloak.onAuthLogout = () => setRelationId(undefined);
  keycloak.onAuthSuccess = () => success();
  keycloak.onAuthRefreshSuccess = () => success();
  keycloak.onAuthError = (error) => {
    console.error('Keycloak onAuthError:', error);
    keycloak.logout();
  };
  keycloak.onAuthRefreshError = () =>
    console.error('Keycloak onAuthRefreshError');

  const success = () => {
    updateToken(keycloak.token);
  };

  useEffect(() => {
    keycloak
      .init({
        onLoad: 'login-required',
      })
      .then((auth: boolean) => {
        setAuthenticated(auth);
        setKeycloakInstance(keycloak);
        setInitializing(false);
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Skeleton loading={isInitializing}>{children}</Skeleton>;
};
