import { CustomButton } from "@/lib/components";
import { useTranslation } from "react-i18next";

export const ReleaseNotes = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.settings.support.releaseNotes',
  });
  return <CustomButton
    color="primary">
    <a target="_blank" href="https://www.alfa.nl/release-notes-mijn-alfa">
      {t('button')}
    </a>
  </CustomButton>
}