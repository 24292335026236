import { postNote } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { InvoiceNote, ThemeColors } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
type NoteButtonProps = {
  iconOnly?: boolean;
  invoiceId: number;
  isDashboard: boolean;
  color: ThemeColors;
  type: ButtonType;
  onSuccess?: (comment: InvoiceNote) => void;
};

export const NoteButton: FC<NoteButtonProps> = ({
  invoiceId,
  isDashboard,
  iconOnly = true,
  color,
  type,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { isFrozen } = useRelationContext();

  const [hasAccess] = useAtom(hasAccessAtom);

  const onclick = () => setVisible(true);
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const onOk = () => form.validateFields().then((values) => onFinish(values));

  const onFinish = async (values: { message: string }) => {
    setLoading(true);
    try {
      const comment = await postNote(invoiceId, values.message);
      onSuccess?.(comment);
      setVisible(false);
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomButton
        disabled={hasAccess.isAccountManager || isFrozen}
        color={color}
        type={type}
        shape={iconOnly ? 'circle' : 'round'}
        icon={<FontAwesomeIcon icon={['far', 'comment']} size="sm" />}
        onClick={onclick}
        toolTipKey="invoiceDetail.action.comment"
      >
        {!iconOnly && t('label.invoiceNote')}
      </CustomButton>
      <Modal
        title={t('label.addInvoiceNote')}
        open={visible}
        onOk={onOk}
        okText="Opmerking toevoegen"
        cancelText="Annuleren"
        confirmLoading={isLoading}
        onCancel={onCancel}
        destroyOnClose
      >
        <Form form={form}>
          <Form.Item name="message" rules={[{ required: true }]}>
            <TextArea rows={4}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
