import { ImpersonatedRoute } from '@/lib/route-guards';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { DocumentsLayout } from './routes/documents/layout';
import { Documents } from './routes/documents/page';
import { Dossier } from './routes/dossier/page';
import { ErrorPage } from './routes/errorPage';
import { Invoices } from './routes/invoices/page';
import { RootLayout } from './routes/layout';
import { RootPage } from './routes/page';
import paymentRoutes from './routes/payment/routes';
import { RelationSelection } from './routes/relation-selection/page';
import { ServiceLayout } from './routes/service/layout';
import { ServiceRelation } from './routes/service/relation/page';
import settingsRoute from './routes/settings/routes';

const router = createBrowserRouter([
  {
    path: '',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      // Backwards compatible
      {
        path: '/impersonate',
        element: <Navigate to="/relation-selection" />,
      },
      {
        path: '/relation-selection',
        element: <RelationSelection />,
      },
      settingsRoute,
      {
        path: '',
        element: <ImpersonatedRoute />,
        children: [
          { path: '/', element: <RootPage /> },
          {
            path: '/dossier',
            children: [
              {
                path: '',
                element: <Dossier />,
              },
              {
                path: ':id',
                element: <Dossier />,
              },
            ],
          },
          {
            path: '/invoices',
            children: [
              { path: '', element: <Invoices /> },
              { path: ':id', element: <Invoices /> },
            ],
          },
          {
            path: '',
            element: <DocumentsLayout />,
            children: [{ path: '/documents', element: <Documents /> }],
          },
          {
            path: '/service',
            element: <ServiceLayout />,
            children: [{ path: 'relation', element: <ServiceRelation /> }],
          },
          paymentRoutes,
        ],
      },
    ],
  },
]);

export const AppRouter: React.FunctionComponent = () => {
  return <RouterProvider router={router} />;
};
