import { Accent, CustomButton } from '@/lib/components';
import { DatePicker, Form, Switch } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { IImportSettings } from '../ImportModal';

interface ISettings {
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  setImportSettings?: React.Dispatch<React.SetStateAction<IImportSettings>>;
}

export const Settings: FC<ISettings> = ({
  setCurrentPage,
  setImportSettings,
}) => {
  const goNext = (values) => {
    const date = dayjs(values.fromDate).format('YYYY-MM-DD');
    setImportSettings?.({
      fromDate: new Date(date),
      includeInvoices: values.includeInvoices,
      includeDocuments: values.includeDocuments,
      inlcudePaymentSettings: values.inlcudePaymentSettings,
    });
    setCurrentPage?.((prev) => prev + 1);
  };

  return (
    <>
      <Accent type="h1">Instellingen</Accent>
      <Form
        name="fromDate"
        onFinish={goNext}
        labelAlign="left"
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Vanaf datum"
          name="fromDate"
          rules={[{ required: true }]}
        >
          <DatePicker style={{ width: '100%' }} picker="date" />
        </Form.Item>
        <Form.Item
          label="Import facturen"
          name="includeInvoices"
          valuePropName="checked"
          initialValue={true}
          rules={[{ required: true }]}
          style={{ textAlign: 'left' }}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Import documenten"
          name="includeDocuments"
          valuePropName="checked"
          initialValue={true}
          rules={[{ required: true }]}
          style={{ textAlign: 'left' }}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Import betaalinstellingen"
          name="inlcudePaymentSettings"
          valuePropName="checked"
          initialValue={true}
          rules={[{ required: true }]}
          style={{ textAlign: 'left' }}
        >
          <Switch />
        </Form.Item>
        <CustomButton htmlType="submit" type="primary">
          Overzicht
        </CustomButton>
      </Form>
    </>
  );
};
