import React from 'react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  isValid: boolean | (() => boolean);
  redirectPath: string;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  redirectPath,
  isValid,
}: ProtectedRouteProps) => {
  const valid = typeof isValid === 'function' ? isValid() : isValid;

  if (valid) {
    return <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};
