import { PagedResponse } from '@/lib/types';
import { Relation, RelationFilter, User } from '@/lib/types/user';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import {
  DefinedUseQueryResult,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['user', 'relations'];
const BASE_URL = `${apiBasePaths.user}/v1/relations`;
const BASE_URL_V2 = `${apiBasePaths.user}/v2/relations`;

export const useGetCurrentRelation = (): UseQueryResult<
  Relation | undefined,
  Error
> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'current'],
    queryFn: () => api.get<Relation>(`${BASE_URL}/current`).then((r) => r.data),
  });

export const useGetRelations = (
  params?: RelationFilter,
): DefinedUseQueryResult<PagedResponse<Relation[]>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    enabled: !!params,
    queryFn: () =>
      api
        .get<PagedResponse<Relation[]>>(`${BASE_URL_V2}`, {
          params: createFilterParams({
            ...params,
          }),
          timeout: 60000,
        })
        .then((r) => r.data),
    initialData: {
      page: 1,
      pageSize: 10,
      total: 0,
      data: [],
    },
  });

export const useGetRelationUsers = (
  id?: string,
): UseQueryResult<User[] | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id, 'users'],
    queryFn: () =>
      api.get<User[]>(`${BASE_URL}/${id}/users`).then((r) => r.data),
    enabled: !!id,
  });
