import { setArchived } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { useRelationContext } from '@/lib/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai/utils';

import { CustomButtonProps } from '@/lib/components';
import { Invoice } from '@/lib/types';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from '../core/buttons/toggleButton';

type ArchiveButtonProps = CustomButtonProps & {
  iconOnly?: boolean;
  isArchived: boolean;
  invoiceId: number;
  onSuccess?: (invoice: Invoice) => void;
};

export const ArchiveButton: FC<ArchiveButtonProps> = ({
  invoiceId,
  iconOnly = true,
  isArchived,
  onSuccess,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isFrozen } = useRelationContext();

  const hasAccess = useAtomValue(hasAccessAtom);

  const onclick = async () => {
    try {
      setLoading(true);
      const invoice = await setArchived(invoiceId, !isArchived);
      onSuccess?.(invoice);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ToggleButton
      disabled={hasAccess.isAccountManager || isFrozen}
      icon={<FontAwesomeIcon icon={['fas', 'archive']} size="sm" />}
      onClick={onclick}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.archive" active={isArchived}
      iconOnly={iconOnly}
      {...rest}
    >
      {!iconOnly && (isArchived ? t('label.resetArchive') : t('label.archive'))}
    </ToggleButton>
  );
};