import { getRelations } from '@/lib/adapters/users-adapter';
import {
  Accent,
  CmsTooltip,
  ControlledInput,
  CustomButton,
} from '@/lib/components';
import { RelationType } from '@/lib/types/enums';
import { Relation } from '@/lib/types/user';
import { debounce } from '@/lib/utils/debounce';
import { useQuery } from '@tanstack/react-query';
import { Row, Space, Spin } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RelationSummary } from './relationSummary';

interface RelationSearchProps {
  onSelect: (relation: Relation) => void;
  customerNumber?: string;
  locationId?: string | undefined;
  setCustomerNumber: (customerNumber: string) => void;
}

export const RelationSearch: React.FunctionComponent<RelationSearchProps> = ({
  onSelect,
  customerNumber,
  locationId,
  setCustomerNumber,
}) => {
  const { data: relations, isFetching: isFetchingSearch } = useQuery({
    queryKey: ['relations', RelationType.Customer, customerNumber],
    queryFn: () =>
      getRelations({
        type: RelationType.Customer,
        customerNumber,
      }).then((x) => x.data),
    enabled: !!customerNumber?.length,
  });

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <CmsTooltip toolTipKey="user.relations.search">
        <Accent type="h1">{t('user.relations.search.label')}</Accent>
        <ControlledInput
          placeholder={t('user.relations.search.placeholder')}
          value={customerNumber}
          onChange={debounce((e) => setCustomerNumber(e), 300)}
        />
      </CmsTooltip>
      <Spin spinning={isFetchingSearch}>
        <SearchResult
          locationId={locationId}
          relations={relations}
          onSelect={onSelect}
        />
      </Spin>
    </Space>
  );
};

const SearchResult = ({
  relations,
  onSelect,
  locationId,
}: {
  relations?: Relation[];
  locationId?: string | undefined;
  onSelect: (relation: Relation) => void;
}) => {
  const { t } = useTranslation();

  if (!relations) {
    return null;
  }

  const filtered = !!locationId
    ? relations.filter((e) => e.locationId === locationId)
    : relations;
  const relation = filtered[0];
  return (
    <>
      <div>
        <Accent type="h4">
          {!filtered.length
            ? t('user.relations.search.empty')
            : filtered.length === 1
              ? t('user.relations.search.found')
              : t('user.relations.search.multiple', {
                count: filtered.length,
              })}
        </Accent>
      </div>
      {filtered.length === 1 && <RelationSummary relation={relation} />}
      <Row justify="center">
        <CustomButton
          disabled={filtered.length !== 1}
          type="primary"
          onClick={() => onSelect(relation)}
        >
          {t('user.actions.selectRelation')}
        </CustomButton>
      </Row>
    </>
  );
};
