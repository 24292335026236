import { IncomingFile } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['invoices', 'incomingfiles'];
const BASE_URL_V1 = `${apiBasePaths.invoices}/v1/incomingfiles`;

export const useGetIncomingFile = (
  id: string,
): UseQueryResult<IncomingFile | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () =>
      id
        ? api.get<IncomingFile>(`${BASE_URL_V1}/${id}`).then((r) => r.data)
        : Promise.reject(),
    enabled: !!id,
  });
