import { Accent } from '@/lib/components';
import { Relation } from '@/lib/types/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import React from 'react';

interface UserAvatarProps {
  relation?: Relation | null;
  username?: string;
  extra?: React.ReactNode;
}

const checkTextOverflow = (text?: string) => {
  if (!text) return '';

  if (text.length > 27) {
    return text.substring(0, 27) + '...';
  }

  return text;
};

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = ({
  relation,
  username,
  extra,
}) => {
  return (
    <Space>
      <FontAwesomeIcon
        style={{ color: 'var(--tertiary)', marginRight: 4 }}
        icon="user"
      />
      <Space.Compact
        block
        style={{ lineHeight: 'normal' }}
        direction="vertical"
      >
        <Accent color="default" type="small" style={{ fontWeight: 'bold' }}>
          {username}
        </Accent>
        <Accent>{checkTextOverflow(relation?.name)}</Accent>
      </Space.Compact>
      {extra}
    </Space>
  );
};
