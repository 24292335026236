import { Col, Row } from 'antd';
import { FC, ReactNode } from 'react';

type PaidRowProps = {
  ibanSupplier: ReactNode | string;
  bicSupplier: ReactNode | string;
  executionDate: ReactNode | string;
  paidAmount: ReactNode | string;
  paymentId: ReactNode | string;
};

export const PaidRow: FC<PaidRowProps> = ({
  ibanSupplier,
  bicSupplier,
  executionDate,
  paidAmount,
  paymentId,
}) => {
  return (
    <Row>
      <Col span={4}>{ibanSupplier}</Col>
      <Col span={4}>{bicSupplier}</Col>
      <Col span={3}>{executionDate}</Col>
      <Col span={3}>{paidAmount}</Col>
      <Col span={3}>{paymentId}</Col>
    </Row>
  );
};
