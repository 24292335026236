import { putRelation } from '@/lib/adapters/users-adapter';
import { CustomButton } from '@/lib/components';
import { AdministrationIdentification } from '@/lib/types';
import { IdentifierCategory, RelationType } from '@/lib/types/enums';
import { Relation } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Modal, Steps } from 'antd';
import { useState } from 'react';
import { Afas } from './Steps/afas';
import { ConvertSummary } from './Steps/convertSummary';
import { Extra } from './Steps/extra';
import { Identifier } from './Steps/identifier';
import { Lyanthe } from './Steps/lyanthe';
import { ExtraData } from './Steps/types';

const { Step } = Steps;

export const ConvertRelation = ({
  relation,
  onSubmit,
}: {
  relation: Relation;
  onSubmit?: () => void;
}) => {
  const [isVisible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [form] = Form.useForm();

  const [afasRelation, setAfasRelation] = useState<Relation>();
  const [lyantheRelation, setLyantheRelation] =
    useState<AdministrationIdentification>();
  const [identifier, setIdentifier] = useState<string>();
  const [extraData, setextraData] = useState<ExtraData>();
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setCurrentPage(0);
    setAfasRelation(undefined);
    setLyantheRelation(undefined);
    setextraData(undefined);
    setIdentifier(undefined);
    setVisible(false);
  };

  const update = async (relation: Relation) => {
    try {
      setLoading(true);
      await putRelation(relation.id, relation);
      showNotification('success', `Relatie ${relation.name} aangepast.`);
      resetState();
      onSubmit?.();
    } catch (e) {
      showNotification('error', 'Fout tijdens aanpassen van relatie.');
    } finally {
      setLoading(false);
    }
  };

  const StepPropsObj = {
    form,
    setCurrentPage,
    identifier,
    isCreating: false,
  };

  const FormSteps = [
    {
      title: 'KvK',
      content: (
        <Identifier
          setIdentifier={setIdentifier}
          {...StepPropsObj}
          identifier={
            relation.identifiers?.find((identifier) => {
              return identifier.category === IdentifierCategory.COC;
            })?.identifier
          }
        />
      ),
    },
    {
      title: 'AFAS',
      content: (
        <Afas
          {...StepPropsObj}
          afasRelation={afasRelation}
          setAfasRelation={setAfasRelation}
        />
      ),
    },
    {
      title: 'Lyanthe',
      content: (
        <Lyanthe {...StepPropsObj} setLyantheRelation={setLyantheRelation} />
      ),
    },
    {
      title: 'Aanvullen',
      content: (
        <Extra
          {...StepPropsObj}
          extraData={extraData}
          setExtraData={setextraData}
        />
      ),
    },
    {
      title: 'Samenvatting',
      content: (
        <ConvertSummary
          {...StepPropsObj}
          afasRelation={afasRelation}
          lyantheRelation={lyantheRelation}
          relation={relation}
          extraData={extraData}
          submitConvert={update}
          type={RelationType.Customer}
          loading={loading}
        />
      ),
    },
  ];

  return (
    <>
      <CustomButton
        type="link"
        onClick={() => setVisible(true)}
        toolTipKey="relations.action.convert"
        icon={<FontAwesomeIcon icon="exchange-alt" />}
      />
      <Modal
        open={isVisible}
        footer={false}
        title="Relatie omzetten"
        onCancel={resetState}
        width={700}
      >
        {relation.type === RelationType.External ? (
          <>
            <Steps
              current={currentPage}
              labelPlacement="vertical"
              style={{ marginBottom: '2rem' }}
            >
              {FormSteps.map((step) => (
                <Step title={step.title} key={step.title} />
              ))}
            </Steps>
            <div className="text-center">{FormSteps[currentPage].content}</div>
          </>
        ) : (
          <ConvertSummary
            {...StepPropsObj}
            relation={relation}
            extraData={extraData}
            submitConvert={update}
            type={RelationType.External}
            loading={loading}
            afasRelation={null}
            lyantheRelation={null}
          />
        )}
      </Modal>
    </>
  );
};
