import { Col, Row } from 'antd';
import React, { FC, ReactNode } from 'react';

type InvoicePaymentRowProps = {
  executionDate?: ReactNode | string;
  iban?: ReactNode | string;
  amount?: ReactNode | string;
  actions?: ReactNode;
};

export const InvoicePaymentRow: FC<
  InvoicePaymentRowProps & React.HTMLAttributes<HTMLDivElement>
> = ({ executionDate, iban, amount, actions, className }) => {
  return (
    <Row gutter={10} className={className}>
      <Col span={6}>{executionDate} </Col>
      <Col span={10}>{iban}</Col>
      <Col span={6} className="text-right">
        {amount}
      </Col>
      <Col span={2}>{actions}</Col>
    </Row>
  );
};
