import { CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

export const RelationBlockedResult = () => {
  const { t } = useTranslation();
  const { setRelationId } = useRelationContext();

  const removeSelectedImpersonation = () => setRelationId(undefined);

  return (
    <Result
      title={t('general.messages.blocked')}
      extra={
        <CustomButton color="primary" onClick={removeSelectedImpersonation}>
          {t('general.actions.selectRelation')}
        </CustomButton>
      }
    />
  );
};
