import {
  deleteNotification,
  getNotifications,
  markAsSeenBasedOnFilter,
  patchNotification,
} from '@/lib/adapters/notification-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Accent, CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Row, Tabs } from 'antd';
import { useAtomValue } from 'jotai/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollList } from '../scroll-list/scrollList';
import TrackingItem from './trackingItem';
import { TrackingFilter } from './trackingFilter';
import { Notification, PaginationParams, TrackingFilterOptions } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';


interface TrackingProps {
  onChange?: () => void;
}

export const Tracking: React.FunctionComponent<TrackingProps> = ({
  onChange,
}) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationParams>();
  const [items, setItems] = useState<Notification[]>([]);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { relation, isFrozen } = useRelationContext();
  const [filter, setFilter] = useState<TrackingFilterOptions>({ mode: 'unread' });

  useEffect(() => {
    loadInitial();
  }, [filter]);

  const loadInitial = async () => {
    let res = await load(1, filter);
    if (!res) return;

    setPagination(res);
    setItems(res.data);
  };

  const handleSeenAll = async () => {
    try {
      await markAsSeenBasedOnFilter({
        category: 'InvoiceStatus',
        level: filter.level,
      });
      onChange?.();
    } catch (e) {
      console.error(e);
      return;
    }

    // edit current items
    if (filter.mode === 'all') {
      // mark as read
      const markedItems = items.map((item) =>
        item.isMarkedAsSeen ? item : { ...item, isMarkedAsSeen: true },
      );
      setItems(markedItems);
    } else {
      // clear all items, since they are marked as read now
      setItems([]);
    }
  };

  const handleSeen = async (id) => {
    try {
      await patchNotification(id, true);
      onChange?.();
    } catch (e) {
      console.error(e);
      return;
    }

    // update item list
    if (filter.mode === 'all') {
      // mark item as read in list
      const updated = [...items].map((item) =>
        item.id === id ? { ...item, isMarkedAsSeen: true } : item,
      );
      setItems(updated);
    } else {
      // remove item from list
      setItems(items.filter((item) => item.id !== id));
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteNotification(id);
      onChange?.();
    } catch (e) {
      showNotification('error', 'Fout tijdens verwijderen notificatie.');
      return;
    }

    // Update the list
    setItems(items.filter((item) => item.id !== id));
  };

  const loadMore = async (page) => {
    let res = await load(page, filter);
    if (!res) return;

    setPagination(res);
    setItems([...items, ...res.data]);
  };

  const load = async (
    page: number,
    filter: TrackingFilterOptions
  ) => {
    try {
      return await getNotifications({
        includeSeen: filter.mode === 'all',
        page: page,
        category: 'InvoiceStatus',
        pageSize: 20,
        level: filter.level,
        terms: filter.terms,
        from: filter.from?.toDate(),
        to: filter.to?.toDate(),
        onlyParent: true,
      });
    } catch (e) {
      console.error(e);
      showNotification('error', 'Could not get notifications'); //todo translate
      return null;
    }
  };

  const notificationList = () => {
    return (
      <ScrollList loadMore={loadMore} pagination={pagination} height="70vh">
        <>
          {items.map((notification) => (
            <TrackingItem
              key={notification.id}
              mode={filter.mode}
              notification={notification}
              onDelete={handleDelete}
              onSeen={handleSeen}
            />
          ))}
        </>
      </ScrollList>
    );
  };

  const onTabChange = (activeKey: string) => {
    setFilter(filter => { return { ...filter, mode: (activeKey as ('all' | 'unread')) } });
  };

  return (
    <div className="container">
      <Row style={{ marginBottom: "2.1342%" }} justify="space-between">
        <Accent type="h2">Tracking</Accent>
        <CustomButton
          onClick={handleSeenAll}
          type="link"
          disabled={
            !(hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) ||
            isFrozen
          }
          color="primary"
          shape="round"
          toolTipKey="notifications.action.markAllAsRead"
        >
          {t('notifications.clearBtn')}
        </CustomButton>
      </Row>
      <Tabs
        tabBarExtraContent={{ right: <TrackingFilter filter={filter} setFilter={setFilter} /> }}
        onChange={onTabChange}
        items={[
          {
            label: 'Ongelezen',
            key: 'unread',
            children: notificationList(),
          },
          {
            label: 'Alles',
            key: 'all',
            children: notificationList(),
          },
        ]}
      />
    </div>
  );
};
