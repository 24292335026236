import { InvoiceNote } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';

type InvoiceNoteListProps = {
  invoiceNotes: InvoiceNote[];
};

export const InvoiceNoteList = ({ invoiceNotes }: InvoiceNoteListProps) => {
  const { t } = useTranslation();
  if (!Array.isArray(invoiceNotes) || !invoiceNotes.length) {
    return null;
  }

  return (
    <List
      itemLayout="horizontal"
      bordered={false}
      split={false}
      dataSource={invoiceNotes}
      renderItem={(note) => (
        <List.Item key={note.id}>
          <List.Item.Meta
            title={note.message}
            description={`${
              note.username ?? t('user.undefined')
            } - ${dateTimeFormatter.toDateOnly(note.date)}`}
          />
        </List.Item>
      )}
    />
  );
};
