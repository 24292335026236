import {
  CurrentUserProvider,
  FeatureFlagProvider,
  RelationProvider,
} from '@/lib/context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { PropsWithChildren } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider>
        <CurrentUserProvider>
          <RelationProvider>{children}</RelationProvider>
        </CurrentUserProvider>
      </FeatureFlagProvider>
    </QueryClientProvider>
  );
};
