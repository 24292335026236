import { registerLogin } from '@/lib/adapters/users-adapter';
import { useEffect } from 'react';

export const useLoginRegistration = () => {
  useEffect(() => {
    const tryRegisterLogin = async () => {
      try {
        await registerLogin();
      } catch (error) {
        console.log('Could not register login');
      }
    };
    tryRegisterLogin();
  }, []);
};
