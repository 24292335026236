import { User } from '@/lib/types/user';
import { Skeleton } from 'antd';
import React, { PropsWithChildren, createContext } from 'react';
import { useGetCurrentUser } from '../queries';

const UserContext = createContext<User | undefined>(undefined);

export const useCurrentUserContext = () => React.useContext(UserContext);

export const CurrentUserProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const { data, isLoading } = useGetCurrentUser();

  if (isLoading) {
    return <Skeleton />;
  }

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
