import {
  deleteProcessFile,
  getProcessFiles,
  reprocessProcessFile,
} from '@/lib/adapters/invoice-adapter';
import { getRelations } from '@/lib/adapters/users-adapter';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import {
  ProcessFile,
  ProcessFileFilter,
  ProcessFileWithOwner,
  SortOrder,
} from '@/lib/types';
import { IdentifierCategory } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const baseColumnSettings: CustomColumnType<ProcessFileWithOwner> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const RejectedInvoices = () => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<ProcessFileWithOwner[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [tableSettings, setTableSettings] = useState<ProcessFileFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'deliveryDate',
    order: SortOrder.Descending,
    exactMatch: false,
  });

  const fetchData = async (tableSettings: ProcessFileFilter) => {
    setLoading(true);
    try {
      const files = await getProcessFiles({
        ...tableSettings,
        excludeStatusses: [
          'Success',
          'Duplicate',
          'MigrationError',
          'None',
          'Error',
          'Reprocessing',
          'UnknownRelation',
        ],
      });
      setTotal(files.total);

      const relationIds = Array.from(
        new Set(
          files.data
            .filter((x) => x.ownerRelationId)
            .map((x) => x.ownerRelationId),
        ),
      );

      const relations = relationIds.length
        ? (
            await getRelations({
              relationIds: relationIds,
            })
          )?.data
        : [];

      const filesWithOwner: ProcessFileWithOwner[] = files.data.map((x) => ({
        ...x,
        owner: relations.find((relation) => relation.id === x.ownerRelationId),
      }));
      setFiles(filesWithOwner);
    } catch (e) {
      console.log(e);
      showNotification('error', 'Fout tijdens ophalen facturen');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(tableSettings);
  }, [tableSettings]);

  const reprocessFile = async (id: string) => {
    try {
      await reprocessProcessFile(id);
      showNotification('success', 'Opnieuw ter verwerking gesteld.');
      fetchData(tableSettings);
    } catch {
      console.log('Fout tijdens herverwerken factuur.');
    }
  };

  const deleteFile = async (id: string) => {
    try {
      await deleteProcessFile(id);
      showNotification('success', 'Verwijderd.');
      fetchData(tableSettings);
    } catch {
      console.log('Fout tijdens verwijderen.');
    }
  };

  const formatIdentifer = (identifier?: string, identifierType?: string) => {
    let str = '';

    if (identifier) {
      str += identifier;
    }
    if (identifierType) {
      str += `(${identifierType})`;
    }

    return str;
  };

  const tableColumns: CustomColumnType<ProcessFile>[] = [
    {
      ...baseColumnSettings,
      title: t('processFile.owner.customerNumber'),
      dataIndex: 'ownerCustomerNumber',
      render: (_, row: ProcessFileWithOwner) => {
        return row.owner?.identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier;
      },
      sorter: false,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.deliveryDate'),
      dataIndex: 'created',
      width: 200,
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.documentName'),
      dataIndex: 'documentName',
      width: 300,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.uploadType'),
      dataIndex: 'uploadType',
      render: (value) => t(`uploadTypes.${value}`),
    },
    {
      ...baseColumnSettings,
      title: t('processFile.status'),
      dataIndex: 'status',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.statusDescription'),
      dataIndex: 'statusDescription',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.customerIdentifier'),
      dataIndex: 'customerIdentifier',
      render: (_, record) =>
        formatIdentifer(
          record.customerIdentifier,
          record.customerIdentifierType,
        ),
    },
    {
      ...baseColumnSettings,
      title: t('processFile.supplierIdentifier'),
      dataIndex: 'supplierIdentifier',
      render: (_, record) =>
        formatIdentifer(
          record.supplierIdentifier,
          record.supplierIdentifierType,
        ),
    },
    {
      width: 180,
      dataIndex: 'id',
      render: (id, row: ProcessFile) => (
        <>
          <DocumentViewModal id={id} idType="processfile" />

          <Popconfirm
            title={t('rejectedInvoices.actions.reprocess.content')}
            onConfirm={() => reprocessFile(id)}
          >
            <CustomButton
              toolTipKey="rejectedInvoices.action.reprocess"
              type="link"
              icon={<FontAwesomeIcon icon="redo" />}
            />
          </Popconfirm>

          {row.status.toLowerCase() === 'rejected' && (
            <Popconfirm
              title={t('rejectedInvoices.actions.delete.content')}
              onConfirm={() => deleteFile(id)}
            >
              <CustomButton
                type="link"
                toolTipKey="rejectedInvoices.action.delete"
                danger
                shape="circle"
                size="small"
                icon={<FontAwesomeIcon icon="trash-alt" />}
              />
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  const onTableChange = async (pagination, filters, sorter) => {
    const filter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'deliveryDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
  };

  return (
    <>
      <CustomTable
        rowKey="id"
        style={{ marginTop: '2rem' }}
        loading={isLoading}
        onChange={onTableChange}
        columns={tableColumns}
        dataSource={files}
        pagination={{
          current: tableSettings.page,
          pageSize: tableSettings.pageSize,
          hideOnSinglePage: true,
          total: total,
          onChange: () => {},
        }}
        size="small"
      />
    </>
  );
};
