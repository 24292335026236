import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

export const NoInvoiceFoundView = () => {
  const { t } = useTranslation();
  return (
    <Empty
      description={
        <span>
          {t('empty.description')}
          <br />
          {t('dashboard.emptyDetails')}
        </span>
      }
    />
  );
};
