import { Relation } from '@/lib/types/user';
import { api } from '@/lib/utils/api.axios';
import { Query, useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, createContext, useEffect } from 'react';
import { useStorage } from '../hooks';
import { useGetCurrentRelation } from '../queries';

interface Context {
  relation?: Relation;
  isFrozen: boolean;
  setRelationId: (relationId?: string) => void;
  isLoading: boolean;
}

const RelationContext = createContext<Context>({
  setRelationId: (relationId?: string) => {},
  isFrozen: false,
  isLoading: false,
});

export const useRelationContext = () => React.useContext(RelationContext);

export const RelationProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [selectedRelationId, setSelectedRelationId] = useStorage<
    string | undefined
  >('selectedRelation', undefined, window.sessionStorage);

  const { data, isLoading } = useGetCurrentRelation();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedRelationId) {
      api.defaults.headers.common['onBehalfOfRelation'] = selectedRelationId;
    } else {
      if (api.defaults.headers.common['onBehalfOfRelation']) {
        delete api.defaults.headers.common['onBehalfOfRelation'];
      }
    }

    // On relation change we want to clear all cached queries
    queryClient?.resetQueries({
      predicate: (query: Query) => !query.meta?.persistOnReset,
    });
  }, [selectedRelationId]);

  return (
    <RelationContext.Provider
      value={{
        isLoading,
        relation: selectedRelationId ? data : undefined,
        setRelationId: setSelectedRelationId,
        isFrozen: data?.isFrozen || false,
      }}
    >
      {children}
    </RelationContext.Provider>
  );
};
