import { Col, Row } from 'antd';
import React, { FC, ReactNode } from 'react';

type InvoiceCreditRowProps = {
  desc?: ReactNode | string;
  amount?: ReactNode | string;
  actions?: ReactNode;
};

export const InvoiceCreditRow: FC<
  InvoiceCreditRowProps & React.HTMLAttributes<HTMLDivElement>
> = ({ desc, amount, actions, className }) => {
  return (
    <Row gutter={10} className={className}>
      <Col span={4}>{desc}</Col>
      <Col span={4}>{amount}</Col>
      <Col span={16}>{actions}</Col>
    </Row>
  );
};
